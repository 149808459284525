.message-entry-field {
  @media (max-width: $screen-md-min) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    bottom: 0;
    position: absolute;
    .message-button {
      float: right
    }
  }
  @media (min-width: $screen-sm-max) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    .message-button {
      float: right
    }
  }
}

.message-media-container {
  border: 1px solid #DDDDDD;
  border-radius: $--br-default;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  transition: 400ms all;
  padding: 5px;
  align-items: center;
  z-index: 5;
  background: $--fc-background;
}

.message-text-container {
  width: 100%;
  max-width: 100%;
  transition: 400ms all;
}

.message-media{
  max-width: 100%;
  height: 125px;
}

.cancel-control {
  position: relative;
  margin: auto;
  .cancel-control-icon {
    position: absolute;
    font-size: 1.75rem;
    color: $--sc-red;
    top: -10px;
    right: 0;
    z-index: 2;
  }
}

.hide-message-container{
  width: 0;
  display: none;
  .cancel-control-icon{
    display: none;
  }
}