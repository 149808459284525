.upload_area {
  width: 100%;
  border: 2px dashed $denim;
  border-radius: 6px;
  padding: 25px 20px;
  font-size: 18px;
  text-align: center;
  transition: .25s ease-in-out;
  cursor: pointer;
  &.hover,
  &:hover {
    background: rgba(24, 123, 209, 0.1);
  }
  .upload_message {
    i {
      display: block;
      color: $denim;
      font-size: 30px;
    }
    .text {
      display: block;
      margin-top: 10px;
    }
    .errors {
      width: auto;
      display: inline-block;
      margin: 15px auto;
      span {
        display: block;
        text-align: left;
        width: auto;
      }
    }

    &--success i { color: $light-green }
    &--failure i { color: $error-red }
  }
}