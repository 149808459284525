$campaigns-table-planning: $--sc-sub-gray;
$campaigns-table-complete: $--fc-blue;
$campaigns-text-blue:$--sc-blue;
$campaigns-border-color:$--fc-blue;

table {
  th.ten_percent {
    width: 10%
  }
}

.table-form {
  thead:first-child > tr:first-child > th { border-bottom: none; }

  tbody {
    tr {
      td { border: none; }
      td:first-child { padding-left: 0; }
      td:last-child { padding-right: 0; }
    }
  }
}

.table-highlight-last-row {
  tr:last-child td {
    font-weight: $bold;
    border-top: 1px solid black;
  }
}

form.campaign-edit {
  margin-bottom: 20px;
}

.add-vehicle-field {
  margin-top: 10px;
}

#tag-helper {
  display: none;
  margin-top: 8px;
  color: $dark-green;
}

.campaign-summary-title{
  font-weight:600;
  display:block;
}

.campaign-summary-section{
  margin-bottom: 20px;
  font-size:16px;
  font-weight:300;

  .row{
    padding:3px 0;
  }

  .col-sm-1{
    padding:0;
  }
  .col-sm-11{
    padding:0;
  }
  .team-icon{
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    width: 20px;
    margin:2px 0 0;
  }
  .campaign-summary-team{
    .row{
    margin:0;
    }
  }
  #cl-team-icon{
    background: $curious-blue;
    padding: 1px 2px 1px 4px;

  }
  #cm-team-icon{
    background: $pelorous;
    padding: 1px 3px 1px 2px;
  }
  #cs-team-icon{
    background: $christi;
    padding: 1px 3px 1px 3px;
  }
  #ss-team-icon{
    background: $danger-red;
  }
  #analyst-team-icon{
    background: $holly;
  }
  #influencer-approvals-icon{
    background: $orange-you-glad;
    padding: 1px 3px 1px 2px;
  }

  @media screen and (max-width: $screen-md) {
    .text-right { text-align: left }
  }
}

.campaign-kickoff-title {
  color:$campaigns-text-blue;
  font-weight:600;
  margin-top:0;
  border-bottom:1px solid $campaigns-border-color;
}

.campaign-kickoff-inmar-logo {
  width: 191px;
  max-width: 100%;
  margin: 0px auto 35px auto;
}

.campaign-inmar-footer-icon {
  width: 100px;
}

.campaign-kickoff-footer-text {
  margin-left: 20px;
}

#admin-campaign-menu {
  width:100%;
  text-align:center;
  background-color:$light-green;
  .nav > li > a:hover,
  .nav > li > a:focus{
    background-color:$dark-grey;
  }
  ul.nav.nav-pills{

    display:inline-block;
    margin:0px;
    padding:0px;

    li{
      margin-bottom:-5px;
    }

    li a{
      border-radius:0px;
      color:#FFFFFF;
      padding: 4px 10px;
      position:relative;
    }

    li.active a{
      background-color:$dark-grey;
      color:#FFFFFF;
    }

  }
}

#paid-media-budget{
  table{
    width: 100%;
    box-shadow: none;
    border: none;
    margin-bottom: 0px;
    thead{
      tr:first-child{
        border-bottom: 1px solid $light-grey;
        td{ padding: 10px; }
      }
    }
    tbody.not-active{
      td:last-child{
        font-style:italic;
      }
    }
    td{
      padding: 10px 3px 0px;
      min-width: 90px;
      border: none;
    }
  }
  hr{
    margin-top: 30px;
    border-top: 1px solid $light-grey;
  }

}

.header-section--heading{
  margin: 0;
  line-height: 34px;
}

.header-section--separator{
  margin: 10px 0;
}

#s2id_campaign_executive_ids .select2-search-choice:first-child, .campaign-summary-team .cs-team .team a:first-child{
  &:after {
    content: " (Dashboard Contact)"
  }
  color: $campaigns-text-blue;
  border-color: $campaigns-text-blue;
}

.admin_shoppertunities_body,
.admin_campaigns_body,
.admin_timelines_body,
.admin_rounds_body,
.admin_shop_posting_body,
.admin_questions_body,
.admin_instructions_body,
.admin_shop_applications_body,
.admin_round_bloggers_body,
.admin_twitter_parties_body,
.admin_contests_body,
.admin_promoters_body {

  .header-section{
    font-weight:600;
    padding-bottom:8px;
    color:$campaigns-text-blue;
    border-bottom:1px solid $campaigns-border-color;
  }

  #shop-summary-section{
    .shop-desc{
      background-color: white;
    }
    .text-danger{
      color:$danger-red;
    }
  }

  #shop_main_navigation, .shop-link-jump{
    width:100%;
    list-style-type: none;
    .nav > li > a:hover,
    .nav > li > a:focus{
      background-color:$dark-grey;
    }

    ul{
      display:inline;
      margin:0px;
      padding:0px;

      li{
        list-style-type: none;
      }

      a{
        border-radius:0px;
        position:relative;
        font-weight:600;
      }

      .active a{
        color:#666;
      }

    }
    .sub-nav ul{
      padding-left:5px;
    }
  }

  // Influencer Tab
  .influencer-filter-form {
    .select-all-checkbox {
      label {
        font-weight: bold;
      }
    }
    .parent-checkbox {
      margin-top: 15px;
      label {
        font-weight: bold;
      }
    }
    .nested-checkbox {
      margin-left: 20px;
    }
  }

  #export-modal, #client-info-modal {
    .row{
      margin: 0;
    }
    .modal-header {
      padding-bottom: 0px;
    }
  }

  .timeline-table {
    td i:first-child {
      margin-right: 5px;
    }
    .best_in_place {
      input, textarea {
        width: 100%;
      }
    }
  }
}

.admin_campaigns_body {

  .header-section{
    border-color:#e4e4e4;
  }

  // Campaign index

  .active-state, .planning-state, .complete-state{
    text-align: center;
  }

  .active-state > p, .planning-state > p, .complete-state > p {
    padding: 2px 0;
    border-radius: $border-radius-base;
    color:#ffffff;
    font-size: 11px;
    max-width: 55px;
  }

  .active-state > p {
    background:$brand-primary;
  }

  .planning-state > p {
    background:$campaigns-table-planning;
  }

  .complete-state > p {
    background:$campaigns-table-complete;
  }
  // End Campaigns index
}

// Content datatable
table.shared-metrics {
  width: 200px;
  box-shadow: none;
  border: none;
  text-align: center;
  margin-bottom: 0px;
  .fa, .fas, .fab, .far {
    font-size: 1em;
  }
  td {
    padding: 10px 3px 0px;
    max-width: 10px;
    border: none;
  }
  &.content-action-totals {
    width: 350px;
    font-size: 1.2em;
    margin: 20px auto;
    tr:first-child {
      border-bottom: 1px solid $light-grey;
      td { padding: 10px; }
    }
  }
}

#aux_content_modal {
  .external-radios label {
    margin-right: 15px;
  }
  abbr[title="required"] {
    display: none;
  }
}

.campaign-content {
  .grade-above{
    width: 12%;
  }
  .filter-export {
    margin-top: 25px;
    width: 21%;
  }
}

#aux-content-table{
  margin-bottom: 50px;
  th,td{
    max-width: 600px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
#product-images-modal {
  .vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;
  }
  .vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
    width:800px;
  }
  .carousel-control i.fa{
    position: absolute;
    top: 50%;
  }
  .modal-content {
      /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
      width:inherit;
      height:inherit;
      /* To center horizontally */
      margin: 0 auto;
      pointer-events:all;
  }
  .item.active{
      height: 550px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

  }
  .carousel-indicators li {
    border-color: #eeeeee;
    box-shadow: 2px 2px 5px #888888;
  }
}

#edit-details-form{
  #product-image-previews{
    padding-top: 5px;
    .image-display{
      position: relative;
      width: 265px;
      height: 156px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 5px;
      border-radius: 3px;
      float: left;
      text-align: center;
      border: solid 1px $default-btn-hover-border;
      .product_image{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .icon--remove{
      position: absolute;
      right: 0;
      font-size: 18px;
      top: 0;
    }
  }
}

// Facebook Ad Campaigns
table.fb-ad-notes,
table.fb-ad-actions{
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  tr {
    background-color: gray;
    text-align: center;
  }
  td {
    text-align: center;
  }
}
td.fb-ad-notes,
td.fb-ad-actions {
  text-align: center;
}
td.fb-ad-actions{
  .fa-trash{
    color: $danger-red;
    cursor: pointer;
  }
  .fa-circle-notch{
    color: $denim;
    cursor: auto;
  }
}

//campaign_kickoff
.kickoff_body img{
  max-width: 100%
}

//campaign questionnaire
#questionnaire-loading,
.questionnaire-saving-msg {
  .fa{
    color: $true-blue;
  }
  h4, .fa, .fas, .fab, .far{
    display: inline-block;
    vertical-align: middle;
  }
}
.campaign-questionnaire-update {
  .category-description{
    color: $dark-grey;
  }
  .category-question {
    .checkbox {
      margin: 0;
    }
    .delete-question-checkbox-icon {
      cursor: pointer;
      &.fas.fa-trash {
        color: $true-blue;
      }
      &.fas.fa-trash.selected {
        color: $danger-red;
      }
    }
    .remove-new-question{
      color: $danger-red;
    }
  }
  .new-question-btn {
    color: $curious-blue;
    font-weight: bold;
  }
}

.attachments_body {
  .attachment-delete {
    text-align: center;
    i {
      cursor: pointer;
      &:hover {
        color: $danger-red;
      }
    }
  }
  .attachment-edit {
    min-height: 20px;
    display: flex;
    align-items: center;

    .attachment-edit--input,
    .attachment-edit--edit,
    .attachment-edit--confirm,
    .attachment-edit--cancel {
      display: none;
      height: 20px;
      font-size: 14px;
    }

    i {
      width: 20px;
      padding: 1px 0;
      color: $denim;
      text-align: center;
      border: 1px solid $denim;
      border-radius: 3px;
      background: #fff;
      cursor: pointer;
      &:before {
        vertical-align: middle;
      }
    }

    input {
      width: 70%;
    }

    &:hover {
      .attachment-edit--edit {
        display: inline;
        margin-left: auto;
      }
    }
    &.active {
      .attachment-edit--link,
      .attachment-edit--edit {
        display: none;
      }

      .attachment-edit--input,
      .attachment-edit--confirm,
      .attachment-edit--cancel {
        display: block;
      }

      i {
        margin: 0 5px;
      }

      input {
        margin-right: auto;
      }
    }
  }
}

#campaign_edit_form{
  .form-group{
    float: left;
    width: 100%;
    label{
      text-align: right
    }
  }
  .has-error .text-danger {
    display: block;
  }
}

#edit-details-form{
  .form-group{
    float: left;
    width: 100%;
    label{
      text-align: right
    }
  }
}
