.submission.content-grid {
  margin: auto;
  display: grid;
  grid-row-gap: 2px;
  grid-column-gap: 2px;
  grid-template-columns: repeat(auto-fill, minmax(calc(100%/3 - 2px), 1fr));

  &.content-count-1 {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  &.content-count-2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(100%/2 - 1px), 1fr));
  }


  .grid-item {
    overflow: hidden;
    position: relative;

    .item-image {
      position: relative;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
      transition: padding .3s ease-in-out;
    }

    .delete-item,
    .rejected-item,
    .approved-item{
      position: absolute;
      top: 5px;
      right: 5px;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      background-color: rgba(200, 200, 200, 0.5);
      box-shadow: 0 0 10px 0 rgba(200,200,200,.5);
      z-index: 10;
      cursor: pointer;
    }
    .rejected-item{
      background-color: $danger-red-background;
      border-color: $danger-red-border;
      color: $danger-red;
    }
    .approved-item{
      background-color: $success-green-background;
      border-color: $success-green-border;
      color: $success-green;
      cursor: default;
    }
    .rejected-item--description{
      position: absolute;
      top: 0;
      color: #fff;
      background:rgba(114, 28, 36,0.75);
      padding: 5px;
      z-index: 9;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .rejected-item--body{
        text-align: center;
        label{
          font-size: 18px;
          margin-bottom: 15px;
        }
        p{
          font-size: 14px;
          display: block;
        }
        @media (max-width: 470px) {
          label{
            font-size: 14px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }

    .state-indicator {
      z-index: 9;
      display: grid;
      position: absolute;
      bottom: calc(50% - 12.5% - 3px);
      right: calc(50% - 12.5% - 3px);
      padding: 12.5%;
      width: 0;
      height: 0;
      border: 3px solid #fff;
      background: rgba(200, 200, 200, 0.5);
      box-shadow: 0 0 10px 0 rgba(200,200,200,.5);
      border-radius: 50%;
      cursor: pointer;
      svg {
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 15%;
      }
      &.passed {
        border-color: #64f386;
        SVG {
          color: #64f386;
        }
      }
      &.failed {
        border-color: #f45062;
        SVG {
          color: #f45062;
        }
      }
      &.play-button {
        svg {
          padding: 20%;
        }
      }
    }

    .video {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      background: #fff;
      overflow: hidden;
      &.draft {
        visibility: visible;
      }
    }

    &.selected {
      &:after {
        content: "";
        opacity: .4;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000
      }
      .video  {
        visibility: hidden;
      }
    }

    &.playing {
      .state-indicator {
        display: none;
      }
      .video {
        visibility: visible;
      }
    }
  }

  &.instagram {
    .item-image {
      padding: 100% 0 0 0;
    }
  }

  &.tiktok {
    .item-image {
      padding: 100% 0 0 0;
    }
  }

  &.user_generated_content {
    .item-image {
      padding: 100% 0 0 0;
    }
  }

  &.instagram-story,
  &.user_generated_content,
  &.tiktok-video {
    .item-image {
      padding: 175% 0 0 0;
    }
  }

  &.youtube,
  &.facebook-video {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

    .item-image {
      padding: 56.25% 0 0 0;
    }
    .state-indicator {
      bottom: calc(50% - 25% - 1.5px);
    }
  }

  &.facebook-video {
    .video {
      max-height: 0;
    }

    .playing {
      .item-image {
        padding: 0;
      }
      .video {
        max-height: 100%;
        position: relative;
      }
    }
  }

  &.loading {
    display: block;
  }

  /* Tablets */
  @media (min-width: 839px) and (max-width: 1024px) {
    &.instagram,
    &.user_generated_content,
    &.instagram-story,
    &.tiktok-video {
      grid-template-columns: repeat(auto-fill, minmax(calc(100%/4 - 2px), 1fr));
    }
    &.youtube,
    &.facebook-video {
      grid-template-columns: repeat(auto-fill, minmax(calc(100%/2 - 1px), 1fr));
    }
  }

  /* Laptops, Desktops */
  @media (min-width: 1025px) {
    width: 90%;
    max-width: 1155px;

    &:not(.loading) {
      margin: 40px auto 0;
    }
    &.instagram,
    &.user_generated_content,
    &.instagram-story {
      grid-template-columns: repeat(auto-fill, minmax(calc(100%/5 - 2px), 1fr));
    }
    &.youtube,
    &.facebook-video {
      grid-template-columns: repeat(auto-fill, minmax(calc(100%/3 - 1.5px), 1fr));
    }
    &.tiktok-video {
      grid-template-columns: repeat(auto-fill, minmax(calc(100%/5 - 2px), 1fr));
    }
  }
}

#view-content-modal{
  .modal-body{
    .tiktok-content-embed{
      .tiktok-embed{
        margin: auto !important;
        padding: 10px 0px !important;
      }
    }
  }
}

.show-more-container {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
