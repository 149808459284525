.content-list{
  & > .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .section-title {
      font-size: $--fs-xx-large;
      font-weight: $--fw-light;
      color: $--sc-gray;
    }

    .filter-options {
      min-width: 150px;
      text-align: left;
      position: relative;

      select {
        // Remove the awful default arrows on the select box
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .fas.fa-caret-down {
        position: absolute;
        right: 14px;
        top: 14px;
      }
    }
  }
  .content-card{
    .content-card-row{
      display: flex;
      justify-content: space-between;
      align-items: top;
      .content-info{
        max-width: 75%;
        .content-round-task-title,
        .content-shop-name{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .content-views,
      .content-engagements{
        text-align: right;
        max-width: 70%;
        .content-pageviews-label{
          display: block;
        }
      }
      &:last-of-type{
        margin-top: 15px;
        align-items: center;
      }
    }
  }
}
.influencer-dashboard-no-content,
.influencer-dashboard-no-payments{
 text-align: center;
 i{
   margin-bottom: $--ls-regular;
 }
}