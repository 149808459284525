.submission.bottom-bar {
  .relative {
    height:95px;
    width: 100%;
  }
  .fixed {
    z-index: 12;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .submit {
      padding: 10px 15px;
      background: #fff;
      border-top: 1px solid #ccc;
      .submit-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1155px;
        margin: auto;
        .btn-container{
          .submission-remove-confirmation{
            .submission-remove-confirmation-btns{
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: flex-end;
            }
          }
          .btn {
            margin: 0 0 0 20px;
          }
        }
      }
    }

    /* Laptops, Desktops */
    @media (min-width: 1025px) {
      .list-container {
        .list-description {
          font-size: 14px;
        }
        .list-title {
          font-size: 20px;
        }
        .list-item {
          font-size: 14px;
        }
      }

      &.open {
        .title-bar {
          .fa-chevron-up {
            transform: rotate(0deg);
          }
        }
        .list-container {
          max-height: none;
        }
      }
    }
  }
}
