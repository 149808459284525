.promotion-wrapper{
  position: relative;
  margin: 0 auto 20px;
  max-width: 750px;
  padding: 10px;
  box-shadow: 0 0 0 1px rgba(225, 231, 234, .45);
  border-radius: $border-radius-base;
  background: #fff;
  .promo--posted{
    padding-top: 20px;
  }
  .promo--max > span{
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #808080;
    font-size: 13px;
  }
  .promo--value{
    font-size: 24px;
    color: $evergreen;
    line-height: 1;
    margin: 0 10px 5px 0;
  }
  counter-reset: stepcounter;
}

.promo-img{
  height:  130px;
  width: 130px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $gallery;
  @media(max-width: $screen-sm-min){
    width: 100%;
    margin: 10px auto;
  }
}

.promo_card--right{
  width: calc(100% - 140px);
  @media (max-width: $screen-sm-min){
    width: 100%;
  }
}

.promo--preview_card{
  padding: 30px;
  min-height: 150px;
  border-top: 3px solid #e6ecf0;
  position: relative;
  top: 10px;
}
.promo_card--header{
  display: block;
  .promo-social-icon{
    border-radius: 50%;
    height: 22px;
    width: 22px;
    padding: 2px;
    margin: 2px 10px 0px 2px;
    @media(max-width: $screen-sm-min){
      margin-bottom: 10px;
    }
    line-height: inherit;
    vertical-align: top;
    &.fa, .fas, .fab, .far{
      font-size: 14px;
    }
    &.fa-twitter{
      color: darken($twitter-color, 8%);
      border-color: $twitter-color;
      background-color: $lighter-blue;
    }
    &.fa-facebook{
      color: white;
      border-color: $facebook-color;
      background-color: $facebook-color;
    }
  }
  h4{
    font-size: 24px;
    display: inline-block;
    margin: 0;
    max-width: 280px;
  }
}

.promo_card--type{
  text-align: center;
}
.promo_card--content{
  margin: 0 auto;
  padding: 5px 25px;
  font-size: 15px;
  line-height: 20px;
  &.facebook{
    max-width: 500px;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    background: white;
    img {
      margin-top: 10px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      border: 1px solid rgba(0, 0, 0, .1);
      background-color: white;
    }
    h4{
      color: #1d2129;
      font-family: $font-family-sans-serif;
      font-size: 15px;
      letter-spacing: -0.01em;
      line-height: 1.34;
    }
  }
  &.twitter{
    background-color: rgb(230, 236, 240);
    max-width: 560px;
    border-radius: 5px;
    img {
      margin-top: 10px;
      border-radius: 5px;
    }
    h4{
      color: #1d2129;
      font-family: $font-family-sans-serif;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  }
}

.promo--btn{
  &.btn-lg{
    padding: 7px 20px;
  }
  &.disabled{
    opacity: .8;
    color: rgba(128, 128, 128, .6);
    border-color: rgba(128, 128, 128, .2);
    background-color: white;
  }
}

#show-more{
  text-align: center;
  padding-bottom: 40px;
}

#promo-post-media-preview {
  max-width: 245px;
}

#image-type-caution{
  background-color: $caution-yellow;
  border: 2px $warning-yellow;
  border-radius: 5px;
  padding: 15px;
  margin: 5px 0;
}

.promotions_body.modal-open .modal-backdrop.in{
  opacity: .7;
}

.promo_instructions_modal{
  width: 100%;
  .modal-body{
    padding: 0;
  }
  .modal-dialog{
    width: 90%;
    max-width: 750px;
    margin: 20px auto;
  }
  .modal-content{
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.promo_instructions_modal--promo_conf_msg{
  padding: 10px;
  background: #3a3838;
  color: #f3f3f3;
  border-top: solid 1px rgba(204, 204, 204, .2);
  @media(max-width: $screen-sm-min){
    > span:first-of-type {
      display: block;
    }
  }
}

.promo_instructions_modal--close_btn{
  color: $light-black;
  position: absolute;
  right: -60px;
  top: 0;
  font-size: 40px;
  line-height: 1;
  padding: 0;
  z-index: 1;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 0;
  background: rgba(white, .9);
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, .68);
  &:hover{
    background: #fff;
  }
  &:focus, &:active{
    outline: 0;
    box-shadow: none;
  }
  @media(max-width: $screen-sm-min){
    right: -5px;
    top: -20px;
  }
}

.promo_instructions_modal--step_heading{
  position: absolute;
  top: 0;
  left: 0;
  &::before{
    content: "Step " counter(stepcounter);
    counter-increment: stepcounter;
    font-size: 15px;
    padding: 15px 20px 0;
    display: block;
    font-weight: $semi-bold;
  }
  @media(max-width: $screen-sm-min){
    position: relative;
    &::before{
      padding: 0;
      margin-bottom: 10px;
    }
  }
}

.promo_instructions_modal--step{
  position: relative;
  border: solid 1px rgba(153, 153, 153, .2);
  border-left: 0;
  border-right: 0;
  &:not(:last-of-type){
    border-bottom: 0;
  }
}

.promo_instructions_modal--step_inner{
  padding: 15px 20px;
  max-width: 560px;
}

.fb_sponsored_warning{
  background-color: $caution-yellow;
  border: 2px $warning-yellow;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  & p{
    margin-bottom: 0;
  }
}

.copy_promo_textarea[readonly]{
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
  margin: 15px 0 0;
  min-height: 110px;
  resize: none;
  background: #f7f7f7;
  border-color: #e0e0e0;
  &:focus{
    box-shadow: none;
  }
  @media(max-width: $screen-sm-min){
    min-height: 120px;
    padding: 10px;
  }
}

.promo-auth{
  display: inline-block;
  width: 200px;
  .authorize{
    width: 170px;
    margin: 2px;
  }
}
