.submission.blog-preview {
  margin-bottom: 10vh;

  .hero {
    max-width: 100%;
    background-color: $dusty-grey;
    border-radius: 8px;
  }

  .blog-description {
    border-left: 4px solid $denim;
    padding-left: 8px;
    margin-top: 12px;
    font-size: 11pt;
  }
}
