/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap-sass/assets/stylesheets/bootstrap/_blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap-sass/assets/stylesheets/bootstrap/_variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_mixins";

// Reset and dependencies
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_normalize";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_print";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_glyphicons";

// Core CSS
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding";
@import "vendor/bootstrap-custom-scaffolding";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_type";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_code";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_grid";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_tables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_forms";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_buttons";

// Components
@import "bootstrap-sass/assets/stylesheets/bootstrap/_component-animations";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_dropdowns";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_button-groups";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_input-groups";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_navs";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_navbar";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_breadcrumbs";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_pagination";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_pager";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_labels";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_badges";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_jumbotron";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_thumbnails";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_alerts";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_progress-bars";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_media";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_list-group";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_panels";
// @import "bootstrap-sass/assets/stylesheets/bootstrap/_responsive-embed";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_wells";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_close";

// Components w/ JavaScript
@import "bootstrap-sass/assets/stylesheets/bootstrap/_modals";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_tooltip";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_popovers";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_carousel";

// Utility classes
@import "bootstrap-sass/assets/stylesheets/bootstrap/_utilities";
@import "bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities";
