$list-view-height: 100px;

.shop-card-search,
.shoppertunities_body[data-action="index"]{
  .shop-row{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  div.pagination{
    width: 100%;
    text-align: center;
    button{
      border:none;
      margin: 0 3px;
    }
    .pagination > li.previous_page button, .pagination > li.next_page button{
      font-weight: normal;
      font-size: 22px;
      line-height: 1;
      padding: 5px 15px;
      background: white;
      color: $blue-text;

      .arrow{
        position: relative;
        top: -2px;
      }
    }
    .pagination > li button:focus, .pagination > li button:hover, .pagination > li button:focus{
      background: $blue-text;
      color: white;
    }
    .pagination > li.disabled button{
      background: #E2DFDF;
    }
  }
}
