.submission.link-form {
  padding: 12px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .form-group {
    margin-bottom: 6px;

    input.form-control {
      border-radius: 6px;
    }
  }

  .btn-fetch {
    &[disabled] {
      background-color: $dusty-grey;
    }

    background-color: $denim;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 12px;
  }
}

.submission.draft {
  padding: 12px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .form-group {
    margin-bottom: 6px;

    input.form-control {
      border-radius: 6px;
    }
  }
}

.submission.upload-area {
  padding: 12px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
