$error: #E77471;

.notification_wrapper:first-of-type a{
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
}

.notification_wrapper:nth-last-of-type(2) a{
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
}

.notification_wrapper{
  position: relative;
  overflow: hidden;
}

.notification-page-actions-container{
  display: inline-block;
  width: 100%;
}

.notification{
  @include clearfix();
  display: block;
  width:100%;
  padding: 5px;
  padding-right: 35px;
  background: white;
  padding-left: 10px;
  border-bottom: solid 1px #e2e2e2;
  &:hover{background:  lighten(#f2f2f2, 2%)}
  a{
    color: #333
  }
  b{
    font-weight: $semi-bold;
    color: #222;
  }
  .notification_date i.fa{
    color: rgba(black, 0.5);
    font-size:12px;
    margin-right: 5px;
  }

  &.group,&.groupmembership,&.post{
    .fa:before{
      content:"\f0c0";
    }
  }
  &.comment .fa:before{
    content: "\f075";
  }
  &.campaign .fa:before{
    content: "\f0a1";
  }
  &.note .fa:before{
    content: "\f24a";
  }
  &.content,&.shoppertunity,&.invitation,&.round,&.roundblogger,&.instruction{
    .fa:before{
      content: "\f07a";
    }
  }
  &.user,&.usersocialplatform {
    .notification_date i.fa:before{
      content: "\f007";
    }
  }
  &.message,&.systemmessage{
    .fa:before{
      content: "\f0e0";
    }
  }
  &.viewed {
    opacity: 0.75;
    border-top: none;
    border-left: none;
    border-right: none;
    background: #efefef;
    .fa:before{
      color:darken(#ddd,15%);
    }
  }
  &.error, &.profile{
    box-shadow: 0 0 1px 1px rgba(231, 116, 113, 0.69) inset;
    .notif_pic:before{
      @include fontAwesome();
      content: "\f06a";
      font-size: 45px;
      text-align: center;
      color: $error;
      width: 55px;
      line-height: 55px;
    }
    .fa:before{
      content: "\f013";
    }
  }
}

.destroy_notif_btn{
  position: absolute;
  right:0;
  top:0;
  padding: 3px 12px;
  font-size: 23px;
  border: none;
  background: transparent;
  color: rgba($darkgray, 0.3);
  transition: $icon_interaction_transition;
  &:hover{
    color: $red;
    transform: scale(1.3);
  }
  &:focus, &:active{
    outline: 0;
    color: $red;
  }
}

.notification_body_wrapper{
  overflow: hidden;
}

.notification_body{
  font-size: 13px;
  min-height: 35px;
  color: $grey-text;
}

.notif_pic{
  height: 55px;
  width: 55px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  float: left;
  margin-right: 10px;
  border-radius: 2px;
 }

$load-more-height: 60px;

.load_more_section{
  text-align: center;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;
}

.show_more_notifs{
  margin-top: 10px;
}

.notification_spinner{
  line-height:$load-more-height;
  vertical-align: middle;
  font-size: 35px;
}

#header_notifications_dropdown .load_more_section{
  display: none;
}

.notification_time{
  font-weight: 400;
  color: #9e9e9e;
}

.notification_modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: absolute;
  justify-content: center;
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  top: 0;
}
.notification_badge{
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  line-height: 12px;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #4E76E3;
  border-radius: 10px;
}