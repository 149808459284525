.account-settings.category-settings {
  .category-description {
    font-size: 10pt;
    color: $--sc-sub-gray;
    margin-bottom: 8px;
  }

  .selected-categories {
    margin-top: 8px;
  }
}
