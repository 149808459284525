.submission.manual-instagram-submission {
  .manual-uploader {
    .upload_area {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .content-grid {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}
