.submission.story-preview {
  padding: 8px;

  .story-title {
    font-size: 12pt;
    margin-bottom: 8px;
  }

  .story-image,
  .story-video {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    margin-bottom: 16px;
    vertical-align: middle;
  }
}
