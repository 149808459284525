.submission-auth-failure {
  padding: 20px;
  color: gray;

  .submission-auth-warning {
    font-size: 50px;
  }

  .submission-auth-button {
    margin-top: 20px;

    &.manual-submit {
      background-color: grey;
      color: white;
    }
  }
}
