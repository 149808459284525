@keyframes background-fade-fade-in {
  0% {
    opacity: 0%;
  }
}

.background-fade {
  z-index: 0;
  background-color: black;
  opacity: 80%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: background-fade-fade-in 0.4s forwards;
}
