body[data-action=new_account_settings][data-controller=users] {
  #footer {
    margin-bottom: 32px;
  }
}

.account-settings {
  max-width: $container-md;
  margin-left: auto;
  margin-right: auto;
}
