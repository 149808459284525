article.blog-post {
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  background: none repeat scroll 0% 0% rgb(255, 255, 255);
  margin-top: 20px;
  padding: 0px;
  display: inline;
  float: left;
  &:hover {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
    .blog-post-social {
      bottom: 0;
    }
  }

  .blog-post-image {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .blog-post-preview{
    font-size: 14px;
  }
  .blog-card-body {
    border-top: 1px solid $light-purple;
    border-bottom: 1px solid $light-purple;
    padding: 15px 40px;
    .blog-post-title {
      margin-top: 0;
      font-size: 1.5em;
    }
    .fa-quote-right, .fa-quote-left {
      color: $holly;
    }
  }
  .blog-post-author {
    padding: 15px;
    h4 {
      padding-left: 60px;
      margin-top: 0;
      line-height: 25px;
    }
    img.author-pic {
      height: 50px;
    }
  }
  .blog-post-hero {
    position: relative;
    overflow-y: hidden;
  }
  .blog-post-social {
    position: absolute;
    bottom: -40px;
    width: 100%;
    transition: bottom 500ms cubic-bezier(0.2, 0.4, 0.6, 1.35);
    ul {
      text-align: justify;
      max-width: 220px;
      margin: 0 auto;
      height: 40px;
      li {
        padding: 0;
      }
      .stretch {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
