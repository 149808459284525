$header-input-height: 40px;
$navbar-link-color: rgba(108, 109, 109, 0.8);
$navbar-link-hover: rgb(63, 63, 63);

header.navbar {
  border: 0;
  font-family: $font-family-sans-serif;
  margin-bottom: 0;
  @media(max-width: $screen-sm-min) {
    position: static;
  }
  .header--base-community{
    padding-top: 20px;
  }
  .navbar-header .header--home-icons{
    float: left;
    display: flex;
    .header--community-image{
      img{
        max-height: 50px;
        max-width: 200px;
      }
    }
    .community-image{ //TODO: replaced wby above when header is updated to community logos - JW
      img{
        max-height: 50px;
        max-width: 100px;
      }
      @media(max-width: $screen-sm-min) {
        display: none;
      }
    }
  }
}

.header--home-logo {
  width: 215px;
  > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  @media(max-width: 380px) {
    max-width: 245px;
    img {
      max-width: 245px;
    }
  }
}

.navbar-right > a,
.count-bubble-group > a {
  float: left;
  margin-right: 18px;
  color: $navbar-link-color;
  line-height: 50px;
  font-size: 13px;
  &:hover,
  &.active {
    color: rgb(63, 63, 63);
  }
  &.active {
    font-weight: 600;
  }
  &.nav-action-icon >
    .fa, .fas, .far, .fab {
    font-size: 15px;
  }
}

// hack for community tab since the active class is added on the community page
[data-action="new_account_settings"] .header--sofab-top-nav .navbar-right > a.active,
[data-action="update_account_settings"] .header--sofab-top-nav .navbar-right > a.active {
  color: $navbar-link-color;
  font-weight: normal;
  &:hover {
    color: $navbar-link-hover;
  }
}

.count-bubble-group {
  position: relative;
  @media(min-width: $screen-sm-min) {
    float: left;
  }
  .nav-action-icon .visible-xs-block {
    float: left;
  }
}

.navbar-right {
  .dropdown.open::after {
    content: '\f0d8';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: 28px;
    right: 18px;
    font-size: 32px;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, .1);
    z-index: 1000;
    color: #fff;
  }
  .user_dropdown.open::after {
    right: 5px;
  }
  .dropdown-menu {
    border: 0;
    margin-top: 0;
    top: 49px;
    font-size: 13px;
    box-shadow: 0 3px 7px 0 rgba(#000, .13),
                0 0 30px 0 rgba(1, 1, 1, .17);
  }
}

.navatar {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.user_dropdown--menu {
  min-width: 315px;
  padding: 10px 20px;
  .navatar {
    height: 72px;
    width: 72px;
  }
  a {
    display: block;
    padding: 2px 0;
    color: #555;
    &:hover {
      color: #222;
      text-decoration: underline;
    }
    @media(max-width: $screen-sm-min) {
      padding: 4px 0;
    }
  }
}

.user_name {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 5px;
}

.user_drop--link_section {
  h4 {
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: 700;
    color: $true-blue;
  }
  &:not(:first-of-type) h4 {
    margin-top: 15px;
  }
}

.notifications_dropdown_menu {
  padding: 4px 0 0;
  min-width: 550px;
  min-height: 250px;
  max-height: 425px;
  overflow: hidden;
}

.dropdown_notifications {
  max-height: 420px;
  overflow-y: auto;
  padding-bottom: 55px;
  .alert-info {
    background: transparent;
    box-shadow: none;
  }
}

.notif_dropdown_header {
  padding: 3px 15px 5px;
  border-bottom: solid 1px #e2e2e2;
}

.notif_drop_footer_link {
  padding: 18px;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  background: #fff;
  border-top: solid 1px #ddd;
  color: $grey-text;
  letter-spacing: 1px;
  &:hover {
    background: rgb(232, 232, 232);
    color: #000;
    border-top-color: rgba(121, 120, 120, .5);
  }
}


.top-search {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: $light-black;
  z-index: 1000;
  .fas span {
    font-size: 12px;
    margin-left: 3px;
    text-transform: capitalize;
    font-family: $font-family-sans-serif;
  }
  .search-link {
    font-size: 14px;
  }
  @media(max-width: $screen-sm-min) {
    position: absolute;
    top: 0px;
    background-color: $--fc-background;
    height: 100%;
    padding: 12px 0px;
  }
}

.search-toggle {
  color: $--sc-blue;
  margin-bottom: 10px;
}

.search-result-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 50px;
  background: $--fc-background;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0px $--sc-sub-gray;
  .search-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $--sc-blue;
    font-size: 1.5rem;
  }
  .search-results {
    .search-result {
      font-weight: bold;
      padding: 10px;
      cursor: pointer;
      .search-result-link {
        color: $--sc-sub-gray;
      }
      border-bottom: 1px solid $--fc-gray;
      &:last-of-type{
        border-bottom: none !important;
      }
    }
  }
  @media(max-width: $screen-sm-min) {
    background: $--fc-background;
    box-shadow: none;
    top: 80px;
  }
}

.top-search-form {
  max-width: 500px;
  float: right;
  margin-right: 30px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 9px auto 5px auto;
  @media(max-width: $screen-sm-min) {
    flex-direction: column;
  }
  .dropdown-toggle {
    min-width: 100px;
    line-height: 40px;
    padding: 0;
  }
  input {
    height: $header-input-height;
    &:focus {
      box-shadow: none;
      border-top: solid 2px #56adf9;
      border-bottom: solid 2px #56adf9;
    }
  }
  .dropdown-menu {
    border-radius: 0 0 $border-radius-base $border-radius-base;
    font-size: 20px;
    i {
      font-size: 14px;
    }
  }
  .close_search_bar {
    font-size: $--fs-large;
    padding: 8px;
    margin: 0 !important;
  }
  .search_menu {
    border-radius: $border-radius-base 0 0 $border-radius-base;
    height: $header-input-height;
  }
  @media(max-width: $screen-sm-min) {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
}

.header-search-btn {
  height: $header-input-height;
  border: 1px solid $light-grey;
  border-left: none;
  margin-left: 0;
  border-right: 0;
  position: absolute;
  top: 0;
  right: 6px;
  z-index: 1001;
}

.mobile-nav-toggle-btn {
  position: relative;
  margin: 15px 5px 0 0;
  span {
    font: 0/0 none;
    background-color: transparent;
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  span,
  span::before,
  span::after {
    display: block;
    border-radius: 10px;
    height: 4px;
    width: 26px;
  }
  span::before,
  span::after {
    content: '';
    position: absolute;
    margin-top: 0;
    background-color: $red;
    transition: transform .3s ease;
  }
  &.collapsed {
    span,
    span::before,
    span::after {
      background-color: rgb(63, 63, 63);
      transform: none;
    }
    span {
      &::before {
        margin-top: 7px;
      }
      &::after {
        margin-top: -7px;
      }
    }
  }
}

@media(max-width: $--bp-medium) {
  .navbar-collapse {
    position: absolute;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100% !important;
    transform: translateX(-110%);
    padding-top: 10px;
    margin: 0;
    background: #fff;
    border: 0;
    transition: .2s ease transform;
    &.in {
      transform: translateX(0);
      margin: 0;
      border-top: solid 1px rgba(#000, .2);
      transition: .2s ease transform;
    }
    .navbar-right > a,
    .count-bubble-group a {
      display:none;
    }
  }
  .dropdown-toggle.navatar {
    display: none;
  }
  .navbar-right .user_dropdown {
    width: 100%;
    margin-top: 10px;
    .dropdown-menu {
      position: static;
      display: block;
      width: 100%;
      margin-top: 10px;
      box-shadow: none;
    }
  }
}

.header-menu-item {
  height: 40px;
  display: flex !important;
  align-items: center;
  border-radius: 0;
  transition: background 400ms;
  border-bottom: 2px solid #e5e5e5;
  &:first-child {
    border-top: 2px solid #e5e5e5;
  }

  .icon-button {
    margin-left: 5px;
    text-decoration: none;
    color: $navbar-link-color;
    margin-right: 12px;
    position: relative;
    padding: 15px;
  }

  .header-menu-item .icon-button:hover {
    filter: none;
  }

  .header-menu-item:hover {
    color: $navbar-link-hover;
  }

  .icon-right{
    margin-left: auto;
    margin-right: 10px;
  }
}

.header-base-menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .header-base-menu-items{
    text-align: center;
    padding: 10px 30px 10px 10px;
  }
  .header-base-menu-navatar{
    text-align: center;
  }
}

.admin-dropdown-links{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.full-width{
  display: flex;
  flex-direction: column;
  @media(max-width: $--bp-medium) {
    margin: 0;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  @media(min-width: $screen-sm-min) {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
}

.guest-navbar{
  .navbar-header{
    .navbar-brand{
      @media(max-width: $--bp-medium) {
        margin-left: -15px;
      }
      #logo{
        width: 215px;
        img {
           position: relative;
           width: 100%;
        }
      }
    }
  }
}
