.display_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0 100px 0;
  .btn + .btn {
    margin-left: 15px;
  }
  .btn {
    display: inline;
    padding: 4px 12px;
    position: relative;
    background: #fff;
    border: 1px solid #fff;
    color: #fff;
    transition: all ease-in-out .15s;
    i {
      padding: 8px 0 8px 0;
    }
    .left {
      flex: 1;
      padding-right: 12px;
    }
    &.general_button {
      background-color: $denim;
      border-color: $denim;
      display: flex;
      align-items: center;

      &:hover {
        background-color: darken($denim,5%);
        border-color: darken($denim,5%);
      }

      .fa-plus {
        padding-right: 8px;
      }
    }
    &.campaign_general_template_button, 
    &.instruction_note_template_button {
      background-color: white;
      border-color: $denim;
      color: $denim;

      &.templates {
        background-color: white;
        color: $denim;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    &.task_button {
      background-color: $vine-color;
      border-color: $vine-color;
      pointer-events: all;
      &.disabled {
        background-color: #eeeeee;
        border-color: #ccc;
        color: #5c5c5c;
        cursor: not-allowed;
        &:active {
          pointer-events: none;
        }
      }
      &:hover:not([disabled]) {
        background-color: darken($vine-color,5%);
        border-color: darken($vine-color,5%);
        cursor: pointer;
      }
    }
    &.campaign_template_button {
      background-color: white;
      border-color: $vine-color;
      color: $vine-color;
      pointer-events: all;
      &.disabled {
        background-color: #eeeeee;
        border-color: #ccc;
        color: #5c5c5c;
        cursor: not-allowed;
        &:active {
          pointer-events: none;
        }
      }
    }
    &.note_button{
      background-color: #f8f9fa;
      border-color: #dae0e5;
      color: #212529;
      &:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
      }
      .fa-plus {
        padding-right: 8px;
      }
    }
  }
  &.add_content_task_buttons{
    .search-list{
      border-color: $vine-color;
      .search-icon{
        color: $vine-color;
      }
    }
  }
}

.instruction_notes_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0 0 0;
  .reorder_switch {
    .reorder_switch_label {
      padding: 4px;
    }
  }
}