.application_layout.submissions_body {
  #footer {
    display: none;
  }

  .container#main {
    margin: 0;
    width: 100%;
    padding: 0;
    width: 100%;
    max-width: unset;
  }
}

.submission.show {
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  background-color: #ececec;
  
  .submission-columns {
    display: flex;

    .submission-left {
      margin: 0;
      padding: 0;
    }
    
    .submission-right {
      margin: 0;
      padding: 0;
      flex: 1;
      max-width: 100%;
    }
  }
}
