.submission.preview-html,
.instagram-content-embed {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.youtube-embed-container{
  max-width: 700px;
  margin: auto;
}
