// Place all the styles related to the comments controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.comments {
  padding-left: 25px;
}

.nested_comments {
  padding-left: 25px;
}

.nested_comments .nested_comments .nested_comments {
  padding-left: 0px;
}

.post-date-time{
  color:$light-blue;
  margin-top:15px;
}

.text-red {
  color: $danger-red;
  font-weight: bold;
}

.comment-text {
  img { max-width: 100%; }
}