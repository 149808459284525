.campaign-questionnaire-show{
  font-size: 14px;
  .category-label{
    color: $fun-blue
  }

  .client-question{
    font-size: 12px;
  }
}

#questionnaire-loading,
.questionnaire-saving-msg {
  .fa, .fas, .fab, .far{
    color: $true-blue;
  }
  h4, .fa, .fas, .fab, .far{
    display: inline-block;
    vertical-align: middle;
  }
}
.campaign-questionnaire-update {
  .category-description{
    color: $dark-grey;
  }
  .category-question {
    .delete-question-checkbox-icon {
      cursor: pointer;
      &.fas.fa-trash {
        color: $true-blue;
      }
      &.fas.fa-trash.selected {
        color: $danger-red;
      }
    }
    .remove-new-question{
      color: $danger-red;
    }
  }
  .new-question-btn {
    color: $curious-blue;
    font-weight: bold;
  }
}
