.shop_tasks--container{
  margin: auto;
  max-width: 600px;
  color: $grey-text;
  .shop_tasks--cards-container{
    .shop_tasks--card-link{
      color: $grey-text;
      .shop_tasks--card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 20px;
        border: 1px solid $lighter-blue;
        border-radius: $border-radius-base;
        margin: 15px auto;
        background-color: white;
        transition: all ease-in-out .25s;
        .shop_tasks--card_details{
          .shop_tasks--card_name{
            display: block;
            font-size: 14px;
          }
          .shop_tasks--card_due_date{
            display: block;
            font-style: italic;
            font-size: 12px;
          }
        }
        .shop_tasks--card_status{
          font-size: 12px;
        }
        &:hover {
          box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.125);
        }
        &.complete{
          background-color: $success-green-background;
          border: 1px solid $success-green-border;
          .shop_tasks--card_status{
            color: $success-green;
            a {
              color: inherit;
            }
          }
        }
        &.issues{
          background-color: $danger-red-background;
          border: 1px solid $danger-red-border;
          .shop_tasks--card_status{
            color: $danger-red;
            a {
              color: inherit;
            }
          }
        }
      }
    }
  }
}