.shop-card-component.shop-card-column {
  position: relative;
  padding: 10px;
  max-width: 390px;
  min-width: 250px;
  width: 100%;

  .shop-card{
    margin: 0 auto;
    width: 100%;

    .card-flag {
      position: absolute;
      top: 8px;
      left: -8px;
      color: $--sc-white;
      padding: 12px 6px;
      padding-left: 18px;
      font-size: 15px;
      line-height: 0;
      font-weight: bold;
      text-transform: uppercase;
      background: $--sc-green;
      border-radius: 0 3px 3px 0;
      box-shadow: 1px 1px 1px #026f4a;
      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: -8px;
        width: 0;
        height: 0;
        border-right: 8px solid #03704a;
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
      }
    }

    .card-body{
      display: block;
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-footer{
      color: $--sc-black;
      background: $--sc-white;
      display: flex;
      align-items: flex-start;
      overflow: hidden;

      .shop-name{
        flex: 0 0 65%;
        display: inline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .shop-platform {
        flex: 1;
        text-align: right;
        i{
          font-style: normal;
          padding-right: 6px;
        }
        .shop-payment{
          color: $--sc-green;
        }
      }
    }
  }
}
