.full-page-placeholder {
  min-height: calc(100vh - 50px - 64px);
  padding: 20px;
  background-color: white;
  
  .page_placeholder {
    .display_description {
      font-size: 15pt;
    }
  }

  .description-subtitle {
    text-align: center;
    font-size: 12pt;
    font-weight: 300;
  }
}
