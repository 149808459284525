.search-list {
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
  border: 1px solid $denim;
  border-radius: 4px;

  i.search-icon {
    color: $denim;
    margin-left: 4px;
  }
  
  input.search-text {
    flex: 1;

    // There are a ton of selector competing for input tags,
    // so we have to mark these as important
    border: none !important;
    border-radius: 4px !important;
    padding-left: 4px !important;
    outline: none;
  }

  .search-results {
    margin-top: 1px;
    background-color: white;
    z-index: 99;
    position: absolute;
    max-height: 100px;
    overflow-y: scroll;
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    width: 100%;
    top: 100%;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    border-radius: 0 0 4px 4px;

    .search-result {
      cursor: pointer;
      font-size: 13pt;
      font-weight: 300;
      border-radius: 4px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  &.loading {
    cursor: wait;
    * {
      pointer-events: none;
    }
  }
}
