.submission.content-type-selector {
  $item-weight: 600;
  $item-font-size: 12pt;
  margin: 40px auto;
  max-width: 600px;
  color: $grey-text;
  padding-left: 20px;
  padding-right: 20px;

  .type-options-container {
    width: 100%;

    .type-options {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 20vh;
      padding-top: 10px;

      .type {
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 10px;
        cursor: pointer;
        font-size: $item-font-size;
        font-weight: $item-weight;
        width: 100%;
        margin: 5px 0;
        border-radius: 3px;
        transition: all ease-in-out .25s;
        &:hover {
          box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.125);
        }
        .fa, .fas, .fab, .far {
          opacity: .5;
        }
      }
    }
  }
}
