.alert-component {
  z-index: 99;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 10px auto;
  padding: 10px 20px;
  border-radius: $--br-default;
  border: 1px solid;
  color: $--sc-gray;
  border-color: $--fc-sub-gray;
  background-color: $--fc-background;
  > p {
    margin-bottom: 0;
  }
  .close {
    margin-left: auto;
    color: inherit;
  }
  &.primary {
    color: $--lc-blue;
    background-color: $--fc-blue;
    border-color: $--fc-blue;
  }
  &.success {
    color: $--lc-green;
    background-color: $--fc-green;
    border-color: $--fc-green;
  }
  &.warning {
    color: $--lc-orange;
    border-color: $--fc-orange;
    background-color: $--fc-orange;
  }
  &.error {
    color: $--lc-red;
    border-color: $--fc-red;
    background-color: $--fc-red;
  }

  &.fixed-top,
  &.fixed-bottom {
    position: fixed;
    max-width: 700px;
    width: calc(100% - 50px);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
  &.fixed-top { 
    top: 50px;
    right: 25px;
  }
  &.fixed-bottom {
    bottom: 50px;
    right: 25px;
  }
}
