.admin_promoters_v2_body {
  table.table {
    border: 1px solid lightgrey;
   // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    th[data-sortable=true] {
      cursor: pointer;
      position: relative;
      &:after {
        content: "\f0d7";
        display: block;
        position: absolute;
        font-family: "Font Awesome 5 Free";
        top: 50%;
        right: 0;
        opacity: 0;
      }

      &[data-sorting=false] {
        &:hover {
          &:after {
            opacity: .5;
          }
        }
      }
      &[data-sorting=true] {
        &:after {
          opacity: .75;
        }
      }
      &[data-sortDir="asc"] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > li {
        padding: 5px 10px;
        margin: 0 2.5px;
        cursor: pointer;
        padding: 5px 10px;
        list-style: none;
        border-radius: 3px;
        color: #187bd1;
        background-color: #fff;
        border: 1px solid #ddd;
        &:hover {
          color: #1360a3;
          background-color: #eeeeee;
        }
        &[data-disabled=true] {
          color: #D1D1D1;
          background: #fff;
          cursor: not-allowed;
        }
        &[data-currentPage=true] {
          cursor: default;
          color: #fff;
          background-color: #187bd1;
        }
      }
    }
  }
  .row {
    display: flex;
    .custom-content-btn{
      margin: 15px;
      margin-left: auto;
    }
  }
  .input_wrap {
    width: 100%;
    max-width: 300px;
    margin: 15px;
  }
}
