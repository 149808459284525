// Shop Applicants index
$shop-app-td-inner-row-height: 90px;

#applicants-tab {

  span.app-name{
    float:left;
  }

  .admin-content-right{
    position: relative;
  }
  .applicant-profile-img{
    width:  150px;
    height: 150px;
    float: left;
    a {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  .selected_applicant_view_rate {
    color: $denim;
  }

  .editable{
      width: 100%;
      height: 100%;
    }
  p.internal-notes{
      white-space: normal;
  }

  .applicant-data{
    .applicant-profile{
      max-width: 600px;
      padding-right: 10px;
    }
    p{
      margin: 0;
      color: $dusty-grey;
    }
    .applicant-links{
      float:left;
      padding-right: 0;
      font-size: 17px;
      max-width: 350px;
      h2.applicant-name{
        font-size: 26px;
        margin: 0 0 4px 0;
        color: $grey-text;
        display: inline-block;
        vertical-align: middle;
      }
      .shopApp--send-msg-btn{
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
      a{
        display:inline-block;
        word-wrap:break-word;
      }
      p{
        margin-top: 2px;
      }
      .plugin-status, .applicant-blog-categories{
        font-size: 15px;
      }
    }
    .applicant-fit-info{
      padding: 5px 0 15px 0;
      font-size: 16px;
      min-height: 100px;
      h4{
        font-size: 18px;
        margin: 5px 0;
      }
      p{
       display: inline-block;
      }
      .applicant-fit-score div{
        display: inline-block;
        color: $dusty-grey;
      }
    }
    .applicant-label{
      display: inline-block;
      color: $grey-text;
      font-weight: $semi-bold;
    }
    p.applicant-engagement{
      font-size: 16px;
      color:#808080;
      margin-left: 15px;
    }
    .applicant-divider{
      border-left: 1px solid #ddd;
      height: 100%;
      max-width: 500px;
    }
    .red-text{
      color: red;
    }
    .reach-container{
      border-top: 1px solid #ddd;
      background-color:#FFFFFF;
      padding: 10px 15px 5px 15px;
      text-align:left;
      .reach-group{
        display:inline-block;
        padding: 0 8px;
        i{
          float: left;
          font-size: 20px;
          &.fa-twitter-square{
            color: $twitter-color;
          }
          &.fa-facebook{
            color: $facebook-color;
          }
          &.fa-instagram{
            color: $instagram-color;
          }
          &.fa-youtube-square{
            color: $youtube-color;
          }
          &.fa-pinterest-square{
            color: $pinterest-color;
          }
          &.fa-twitter-square{
            color: $twitter-color;
          }
        }
        .reach-stats{
          margin-left:8px;
          float:left;
          .list-group-item-heading{
            font-size: 19px;
            margin-bottom: 0;
          }
        }
      }
      .first-icon{
        padding-left: 0;
        border-left: none;
      }
    }
  }

  table.shop_app_table {
    width: 100%;
    font-size: 13px;
    .text-success{
      color: $brand-success;
    }
    .text-danger{
      color: $brand-danger;
    }
    .text-warning{
      color: orange;
    }
    thead th .checkbox {
      height: 22px;
    }

    tbody > tr.success td,
    tbody > tr.warning td,
    tbody > tr.danger td,
    tbody > tr.info td{
      background: inherit;
    }
    tbody > tr.success > td {
      &:first-child{
        border-left: solid 2px rgba($brand-success, 0.7);
      }
    }
    tbody > tr.warning > td {
      &:first-child{
        border-left: solid 2px rgba(orange, 0.7)
      }
    }
    tbody > tr.danger > td {
      &:first-child{
        border-left: solid 2px rgba($brand-danger, 0.7);
      }
    }
    tbody > tr.info > td {
      &:first-child{
        border-left: solid 2px rgba($brand-info, 0.7);
      }
    }
    ul {
      padding-left: 15px;
    }

    p {
      word-wrap: break-word;
    }

    tr {
      input:disabled{
        opacity: 0.5;
      }
      .applicant-name{
        font-size: 13px;
        font-weight: $semi-bold;
        max-width: 122px;
        text-overflow: ellipsis;
        white-space: nowrap;
        i{
          margin-right:5px;
        }
      }
      a.view-app-link {
        display: block;
        font-size: 10px;
        width:$shop-app-td-inner-row-height;
        box-shadow:none;
      }
      .app-note {
        max-height: 200px;
        overflow:hidden;
      }
    }
    .shop_app_pic_bg{
      height: 70px;
      width: 70px;
      margin: 0 0 2px;
      background-size: cover;
    }
    .shop_app--status_icon{
      opacity: 0.7;
      margin-right: 2px;
    }
    .reach {
      display: inline-block;
      height: $shop-app-td-inner-row-height;
        i {
          margin: 0 6px 6px 0;
        }
    }
  }

  .panel {
    table {
      width: 100%;
    }
  }

  .list-group .fa,
  .list-group .fas,
  .list-group .fab,
  .list-group .far {
    font-size: 50px;
    text-align: right;
    position: absolute;
    top: 7px;
    right: 27px;
    outline: none;
  }

  .list-group .fa.conflict-icon,
  .list-group .fas.conflict-icon,
  .list-group .fab.conflict-icon,
  .list-group .far.conflict-icon{
    font-size: 16px;
    text-align: left;
    position: relative;
    top: 0;
    right: 0;
  }

  .panel-title .fa.conflict-icon
  .panel-title .fas.conflict-icon
  .panel-title .fab.conflict-icon
  .panel-title .far.conflict-icon{
    font-size: 19px;
  }

  .display-note {
    word-wrap: break-word;
  }

  .applicant-totals {
    margin-left:-20px;
    margin-right:-20px;
    background-color: #FFFFFF;
    padding-top:20px;

    .panel{
      border:none;
    }

    .panel-body{
      padding:10px 0;
    }

    .panel-heading{
      padding:10px 0 5px;
      background-color:#FFFFFF;
      border:none;
      border-bottom:1px solid $light-grey;
    }

    .panel-title{
      color:$grey-text;
      font-size:24px;
      text-align:center;
    }

    .totals-value{
      width:100%;
      text-align:center;
      border-top:1px solid $light-grey;
    }

  }

  .app-collapsible {
    .list-group {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .list-group-item {
        &:first-child {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
  .shop-applicants--round-status-select{
    margin-top: 5px;
    margin-bottom: 15px;
    .btn-group{
      margin-right: 10px;
    }
  }
  .shop-applicants--shop-open-close{
    margin-top: 39px;
  }
}

#s2id_shop_application_round_ids.select2-container{
  width:100%;
}

#s2id_shop_application_selected_round_id{
  width: 250px
}

#s2id_shop_application_state{
  width: 130px
}

table.blogger-metrics {
  box-shadow: none;
  border: none;
  font-size: 11px;
  text-align: center;
  margin-bottom: 0;
  display: inline-block;
  .fa, .fas, .fab, .far {
    font-size: 1em;
  }
  tbody tr td {
    padding: 0;
  }
}

table.shop_app_table thead tr th.mouseDown:hover {
  cursor: grabbing;
}

table.shop_app_table thead tr th:hover {
  cursor: grab;
}

table.fixedHeader-floating.no-footer {
  z-index: 0;
}

a.resetOrder {
  border: none;
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
}

#applicants_info_nav{
  margin: 15px 30px 0;
  li {
    &.active a{
      color: $grey-text
    }
    a{
      font-size: 15px;
      color: $curious-blue;
    }
  }
}

.admin_shop_application-show--tab_content{
  border-top: 1px solid #ddd;
  > .tab-pane{
    padding: 20px;
    min-height: 400px;
    max-width: 800px;
    margin: 0 auto;
  }
}

#audience-dashboard{
  width: 100%;
  margin-top: 5px;
  .chart-container{
    width: 300px;
    height: 300px;
    margin: 25px 8px;
    display: inline-block;
    vertical-align: middle;
    overflow-wrap: break-word;
    i{
      color: $error-red;
      display: block;
      text-align: center;
    }
    h3{
      margin-top: 5px;
      text-align: center;
    }
  }
}
#shop-application-form .round_card--checkbox_wrapper,
#shop-application-form-edit .round_card--checkbox_wrapper{
  display: none;
}
.open_round_total_payment .with_bid{
  color: $curious-blue;
}
.open_round_total_payment .missing_bid{
  color: $red;
}

.shop_app_questions_table{
  max-width: 700px;
  font-family: $font-family-sans-serif;
  tr:first-child td{
    border-top: none;
  }
  tr td span{
    color: #555;
    font-size: 12px;
  }
  tr td p{
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
// Admin Shop Application Show
#applicants_info_nav, #activity.tab-pane{
  #completed-work, #applied-shops{
    .conflict-icon {
      width: 100%;
    }
  }
  .text-danger{
    color: $brand-danger;
  }
  .text-warning{
    color: orange;
  }
}

.negative {
  color: red;
}

.admin_round_negotiation_form{
  .form-inline label {
    padding-right: 10px;
  }
}

// The shop app table has two separate components that are duplicated
// use this section for styles that must be applied within the header
.dataTables_scrollHead {
  i.header-tooltip {
    cursor: pointer;
  }
}
