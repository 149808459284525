.account-settings.notification-settings {
  .email-description {
    font-size: 10pt;
    color: $--sc-sub-gray;
    margin-bottom: 14px;

    @media(min-width: $--bp-small) {
      max-width: 75%;
    }
  }

  .form-lr > select {
    @media(min-width: $--bp-small) {
      max-width: 25%;
    }
  }
}
