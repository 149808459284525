.single-select-component {
  .select {
    position: relative;
    .dropdown-caret {
      position: absolute;
      right: 16px;
      top: 15px;
    }
  }
}
