.action-alert-area {
  &:first-of-type {
    margin-top: 10px;
  }
}

.action-alert {
  position: relative;
  margin:0 0 $--ls-regular;
  padding: $--ls-regular $--ls-regular $--ls-small $--ls-regular;
  background: $--sc-white;
  border: 1px solid #DDDDDD;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .action-alert-content {
    display: flex;
    align-items: center;
    .action-alert-icon {
      font-size: 2rem;
    }
    .action-alert-text {
      margin-left: 0.825rem;
      white-space: pre-wrap;
    }
  }
  .action-alert-actions {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.action-alert.danger,
.action-alert.error {
  .action-alert-icon {
    color: $--sc-red;
  }
}

.action-alert.success {
  .action-alert-icon {
    color: $--sc-green;
  }
}

.action-alert.warning {
  .action-alert-icon {
    color: $--sc-orange;
  }
}

.action-alert.info {
  .action-alert-icon {
    color: $--sc-blue;
  }
}

.action-alert-actions {
  .alert-action, .btn, a {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
  a:after {
    margin-left: 3px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
  }
}