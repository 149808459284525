.page-title-bar {
  width: 100%;
  margin: 0;
  padding: 7px 0px;
  color: white;
  background: $--gradient-blue-purple;

  .container {
    .title {
      font-weight: 200;
      font-size: 16pt;
    }

    .sub-title {
      opacity: 0.7;
      font-size: 10pt;
    }
  }

}
