@keyframes floating-action-button-bobble {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.0);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.0);
  }
}

.floating-action-button {
  width: 64px;
  height: 64px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  position: fixed;
  cursor: pointer;
  z-index: 1;
  animation: floating-action-button-bobble 0.3s forwards;

  .fab-icon {
    font-size: 20pt;
  }

  &.red {
    background-color: $red;
  }

  &.yellow {
    background-color: $warning-yellow;
  }

  &.invert {
    color: white;
  }
}
