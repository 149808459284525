.new-user-btn{
  display: flex;
  justify-content: flex-end;
  background-color: white;
  .btn{
    margin: 10px 15px 0px 15px;
  }
}
.user-blog-tier{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_users_body{
  label.ms-list-label{
    display:block;
    float:none;
    text-align:left;
  }
  .user-transition {
    &.activate {
      @extend .btn-success;
    }
    &.deactivate, &.disable {
      @extend .btn-danger;
    }
  }

  #strike_form{
    label{
      font-weight: normal;
      padding-bottom: 5px;
    }
    .row{
      padding: 0 15px;
    }
    .row.text-center{
      padding-top: 10px;
    }
  }
  .strike-success{
    color: $light-green;
  }
  .strike-error{
    color: $error-red;
  }
}

span.help-inline {
  color: $danger-red;
  font-size: 12px;
}

.profile-index-link{
  height: 169px;
}

.profile-index-link a{
  display:block;
  width:100%;
  height:100%;
  border-radius: $border-radius-base;
}


.account-settings-panel{
  margin-top: 30px;
}

.account-setting-form .notification-settings-warning{
  padding-top: 7px;

  .alert-warning{
    padding: 5px;
  }
}

.user-card--wrapper{
  height: 189px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: $border-radius-base;
  box-shadow: 0 0 0 1px rgba(225, 231, 234, 0.45);
}

@media(max-width: 769px){
  .profile-index-link{
    margin-bottom: 10px;
    a{
      width: 169px;
    }
  }
  .user-card--wrapper{
    height: auto;
  }
}

.user-card--user-name{
  margin-top: 0;
  font-size: 20px;
}

.user-card--heading{
  font-size: 16px;
  margin: 5px 0;
  color: $navy;
}

.blog-list-section{
  height: 70px;
}

.blog-name{
  margin: 0 0 4px;
}

.user-card--user-image{
  height: 140px;
}

.blog_platforms_user_edit hr:last-of-type{
  display: none;
}

.filter-form .checkbox{
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-form--dropdown{
  display: inline-block;
  margin-right: 15px;
  .dropdown-menu{
    padding: 15px;
    min-width: 205px;
  }
}

.flex-row{
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  align-content:center;
  flex-flow: row nowrap;
  justify-content: space-between;
 }

 .flex-item{
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
 }

.more-filters-dropdown{
  position: static;
  .dropdown-menu{
    padding: 15px 30px;
    width: 100%;
    margin-top: -2px;
    .fa-times-circle{
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 22px;
    }
  }
  button .fa-filter{
    color: $denim;
  }
  hr{
    margin: 15px 0;
  }
}

.filter-form--label{
  color: $grey-text;
  font-weight: $normal;
  font-family: $font-family-sans-serif;
  margin-bottom: 5px;
}

.range +  .range{
  margin-top: 15px;
}

.range {
  input{
    float: left;
  }
  .label--age, .label--last_activity_at, .label--total_shops, .label--last_shop_submission{
    display: none;
  }
}

.filter-form--tags{
  display: inline-block;
  font-size: 12px;
  background: rgba(195, 195, 195, 0.68);
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 4px 5px 4px 10px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(83, 83, 83, 0.43);
  i{
    color: rgba(white, 0.6);
    margin-left: 3px;
  }
  .filter-count{
    color: lighten($grey-text, 20%);
    margin-left: 2px;
  }
}

#admin_users_table_wrapper{
  th{
  }
  .name, .email{
    min-width: 120px;
  }
  .actions{
    min-width: 275px;
  }
  .last_active,
  .last_shop{
    min-width: 100px;
  }

  a.audience{
    text-decoration: none;
    cursor: default;
    i.fab.fa-instagram {
      color: black;
    }
  }
}