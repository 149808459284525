input[type=number] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.admin_campaign_requirements_body {
  .page_placeholder,
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 25px 0;
  }
  .page_placeholder {
    margin: 100px 0;
  }
  .form-group {
    margin: 0;
  }
  .input_wrap {
    margin: 0;
    margin-bottom: 6px;
    &#pinterest_url{
      width: 100%
    }
    label {
      display: inline-block;
      padding: 0 5px;
      color: $grey-text;
      font-size: 14px;
      font-weight: 400;
    }
    .error{
      color: $danger-red;
    }
  }
}
