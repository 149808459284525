@keyframes community-unlocked-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.community-logins.code-entry {
  .code-field {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 4px 8px;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    outline: none;
  }

  &.code-success {
    animation: community-unlocked-animation 2s ease-out;
    text-align: center;

    .contents {
      color: white;
      background-color: $--sc-green;
      border-radius: 20px;
      padding: 4px 8px;
    }
  }
}
