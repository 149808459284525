.admin_feeds_body {

  #group-feed-creator, #campaign-feed-creator {
    display: none;
  }

  #feed-form {
    #shop-list-container, #loading, #selected-campaign {
      display: none;
    }
    #selected-campaign h3 {
      cursor: pointer;
      margin-top: 0;
    }
    #shop-list-container {
      legend {
        margin-bottom: 0;
      }
    }
    .ms-container {
      padding: 0;
    }
    .ms-list {
      max-height: 200px;
    }
  }

}