.profile-blog-card {
  .card-body {
    > div {
      display: flex;
      flex-flow: column;
      margin: 20px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 10px;
        font-weight: $--fw-regular;
        color: $--sc-sub-gray;
      }
      .value {
        font-size: $--fs-regular;
        font-weight: $--fw-bold;
      }
      .alert-component {
        margin: 5px auto 0;
      }
    }
    
  }
}