.filters_and_sorts {
  padding: 0 0 5px;
  ul{
    margin: 0;
    list-style: none;
  }
  .dropdown {
    float: left;
    &:not(:last-of-type){
      margin-right: 10px;
    }
    .dropdown-toggle{
      background-color: white;
      color: #2a3a43;
    }
    &.open .dropdown-toggle, .dropdown-toggle:hover{
      background-color: darken(#344853, 5%);
      color: white;
      i{
        color: white;
      }
    }
    i{
      color: $caret-color;
      padding-left: 2px;
    }
  }

  .filter .dropdown .btn-default{
    min-width: 150px;
  }

  .sort_by .dropdown .btn-default{
    min-width: 200px;
  }

  .dropdown-menu{
    background-color: $navy;
    min-width: 230px;
    max-height: 400px;
    overflow: auto;
    border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
    padding: 10px 5px;
    border: none;
    li a{
      border-radius: $border-radius-base;
      transition: none;
      padding: 5px 15px;
      color: white;
      &:hover{
        background-color: darken(#344853, 5%);
      }
    }
  }
  .dropdown-menu-right{
    border-radius: $border-radius-base 0 $border-radius-base $border-radius-base;
  }
  .open .dropdown-toggle{
    background-color: darken(#344853, 5%);
    // border-color: #344853;
    border-radius: $border-radius-base $border-radius-base 0 0;
    i{
      color: white;
    }
  }
  .filters_label, .sort_by_label  {
    margin: 0 10px 0 0;
    line-height: 32px;
    font-size: 13px;
  }
}

@media(max-width: $--bp-small){
  .filters_and_sorts{
    .dropdown-menu{
      width: 100%;
    }
    ul{
      padding: 0;
    }
    .dropdown:not(:last-of-type){
      margin-right: 0;
    }
  }
  .filter, .sort_by{
    @include clearfix();
    position: unset;
  }
  .filters_label{
    float: none !important;
    display: block;
    text-align: center;
  }
  .filter .dropdown{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    .dropdown-toggle{
      width: 100%;
    }
  }
}
