.shop_rounds--container{
  margin: auto;
  color: $grey-text;
  .shop_rounds--actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .shop_rounds-nav-dropdown{
      width: 300px;
      .dropdown-menu{
        width: 300px;
      }
    }
    .shop_rounds--icons{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .round-icon{
        flex: 0;
        font-weight: 400;
        padding: 0px 10px;
      }
      i{
        cursor: pointer;
        font-size: 18px;
      }
      .btn.btn-outline {
        color: $denim;
        border: 1px solid $denim;
      }
    }
  }
  .shop_rounds--top-section{
    .shop_rounds-value{
      min-width: 200px;
      padding: 10px 5px;
      margin: auto;
      
      &:first-of-type {
        margin-left: 0;
        margin-right: 25px;
      }

      &:last-of-type {
        margin-left: 25px;
        margin-right: 0;
      }

      label{
        display: block;
        margin: 8.5px 0;
        font-weight: 700;
      }
      input{
        width: 100%;
        padding: 3px;
        padding-left: 10px;
      }
    }
    .shop_rounds--top-info{
      width: 100%;
      display: flex;
      flex-flow: row wrap;
    }
  }
}
