.internal-profile-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.internal-profile-card-item {
  flex: 40%;
  margin: 10px;
  @media(max-width: $--bp-small){
    flex: 100%
  }
  label{
    margin-bottom: 0;
  }
}

.card-action {
  text-align: right;
}