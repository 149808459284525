.instructions--campaign-requirement {
  .card-header {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: rgba(78, 118, 227, .25);

    .edit_requirement_card_label {
      flex-grow: 1;
    }

    .edit_requirement_card_icons {
      min-width: 35px;
      display: flex;
      justify-content: center;
      .fas.fa-trash {
        color: $--sc-red;
        cursor: pointer;
      }
    }
  }

  .redactor-box {
    margin-bottom: 0;

    .redactor-toolbar {
      border-top: none;
      border-right: none;
      border-left: none;
    }

    .redactor-editor {
      border: none;
    }
  }
}
