.payments_body {

  .well.main {
    padding: 39px 70px;
  }

}

.padded_text {
  padding: 5px 10px;
  margin-bottom: 5px;
}

.supercede_payments {
  margin-top: 0px;
}

.supercede-container {
  margin-top: 5px;
}

.warn_text{
  margin-bottom: 3px;
}

.accordion_body {
  display: none;
}

.chevron_up {
  display: none;
}

.chevron_down {
  display: block;
}
