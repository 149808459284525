.instructions.read-only.requirement_card {
  .task-container {
    padding: 20px 10px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-between;

    > div {
      flex: 1;
      min-width: 150px;
      margin: 5px 0;
      padding: 0 5px;
      &.due-date {
        text-align: right;
        @media(max-width: 800px){
          text-align: left;
        }
      }
    }
  }

  .task-description {
    padding: 10px;
  }

  .requirements-container {
    padding: 20px 20px;
    width: 100%;
    background-color: #f6f6f6;

    .requirements-table {
      width: 100%;
      
      .checkbox-column {
        padding: 0;
        width: 24px;
        color: #6b6b6b; // Darkish grey
        font-size: 12pt;
      }

      & > tbody > tr {
        border-bottom: 1px solid #dddddd;
        &:last-child {
          border-bottom: none;
        }
        & > td {
          padding: 7px 4px;
        }
      }
    }
  }
}
