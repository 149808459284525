.deleted_requirements_container {
  position: relative;
  top: -75px; // using top here to place it 25px below requirement_buttons with bottom 100px margin
  width: 100%;
  padding: 10px 10px;
  background: $--sc-background;
  border-radius: 6px;
  border-left: 12px solid $--sc-teal;
  .container_title {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
  }
  .requirements_list {
    display: inline-block;
    padding: 5px;
  }
}