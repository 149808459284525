.shoppertunity-platform-settings-edit{
  .opportunity_platform_setting_inputs {
    max-width: 300px;
    .input_wrap{
      padding-bottom: 15px;
    }
  }
  hr{
    margin-top: 0;
  }
  .platform-header{
    color: $--sc-blue;
  }
  .tier-form{
    padding-bottom: 15px;
  }
}
