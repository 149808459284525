.admin_payments_body {

  table {
    th.ten_percent {
      width: 10%
    }
    th.twenty_five {
      width: 25px;
    }
    tbody tr {
      cursor: pointer;
    }

    .checkbox {
      margin-top: 10px;

      :disabled {
        cursor: not-allowed;
      }
    }
  }

  form.payment-edit {
    margin-bottom: 20px;
  }

  .datatable tbody tr.reverted td {
    text-transform: uppercase;
    font-weight: 100;
    background-color: #FFF3CD;
  }

  .datatable tbody tr.inactive td {
    background-color: #F8D7DA;
  }

  .datatable tbody tr:hover td {
    background-color: #B0E2FF;
  }

}