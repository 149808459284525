
.round_task_requirements_view{
  .round_task_requirements_count_label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #D1D1D1;
    padding: 15px 20px;
    margin: 0;
  }
  .round_task_requirements_view-description{
    display: flex;
    align-items: center;
    margin: 0 20px;
    padding: 5px 0;
    border-bottom: 1px solid #dddddd;
    .fa-square {
      padding: 0;
      margin-right: 14px;
      color: #6b6b6b; // Darkish grey
      font-size: 12pt;
    }
    &:last-child{
      border-bottom: none;
    }
  }

}

.round_task_requirements_container {
  display: flex;
  flex-flow: column;
  background: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 0 25px;
  &.in_card {
    border: none;
    border-radius: 0;
    margin: 0;
  }

  .requirements_container_title {
    background: #fff;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px 4px 0 0;
  }
  .requirements_header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
    .requirements_header_grip {
      margin: auto auto auto 10px;
      width: 12px;
    }
    .requirements_header_actions {
      margin: auto 8px auto auto;
      width: 34px;
    }
  }

  .requirement_rows_container {
    padding: 10px;
    .placeholder_row {
      border-radius: 3px;
      margin: 5px 2px;
      background: rgba(209, 236, 241, 0.15);
      border: 4px dashed #D1ECF1;
      height: 45px;
    }
    .requirements_row {
      $border-size: 8px;
      margin: 5px auto;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-self: center;
      border-radius: 6px;
      border: 1px solid #eee;
      position: relative;

      &.keyed-color {
        .requirements_row_grip {
          margin-left: 8px;
        }

        &.palette-0 {
          border-left: $border-size solid $--sc-blue;
        }

        &.palette-1 {
          border-left: $border-size solid $--sc-purple;
        }

        &.palette-2 {
          border-left: $border-size solid $--sc-teal;
        }

        &.palette-3 {
          border-left: $border-size solid $--sc-green;
        }

        &.palette-4 {
          border-left: $border-size solid $--sc-red;
        }

        &.palette-5 {
          border-left: $border-size solid $--sc-orange;
        }

        &.palette-6 {
          border-left: $border-size solid $--fc-blue;
        }

        &.palette-7 {
          border-left: $border-size solid $--fc-purple;
        }

        &.palette-8 {
          border-left: $border-size solid $--fc-teal;
        }

        &.palette-9 {
          border-left: $border-size solid $--fc-green;
        }

        &.palette-10 {
          border-left: $border-size solid $--fc-red;
        }

        &.palette-11 {
          border-left: $border-size solid $--fc-orange;
        }
      }

      &.hide-dragging {
        display: none;
      }
      &.dragged-over {
        * {
          pointer-events: none;
        }
      }
      .requirements_field {
        margin: 5px 0;
      }
      .requirements_row_grip {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        color: #c4c4c4;
        margin: auto auto auto 16px;

        &:hover {
          cursor: grab;
        }
        svg {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .requirements_row_actions {
        margin: 5px auto;
      }
    }
  }


    // Mobile view for task submission requirements form
    @media only screen and (max-width: 900px) {

    /* Hide  headers (but not display: none;, for accessibility) */
    .requirements_header {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .requirement_rows_container {
      .requirements_row {
        flex-flow: column;

        .requirements_row_grip {
          display: none;
        }
        .requirements_row_actions {
          margin: 5px 15px 5px auto;
        }
        .requirements_field {
          width: 60%;
          margin: 5px auto 5px calc(20% + 52.5px);

          &:before {
            position: absolute;
            top: 6px;
            left: calc(-25% - 52.5px);
            max-width: 105px;
          }
          &:nth-of-type(2):before { content: "Description"; }
          &:nth-of-type(3):before { content: "Verification"; }
          &:nth-of-type(4):before { content: "Verification Value"; }
          &:nth-of-type(5):before { content: "Frequency"; }
          &:nth-of-type(6):before { content: "#"; }
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .requirement_rows_container .requirements_row {
      .requirements_field {
        width: 100%;
        margin: 25px auto 10px;
        &:before {
          top: -25px;
          left: 20px;
          max-width: 105px;
        }
      }
    }
  }
}
