.shop_rounds--container {
  .task-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 20pt;
    }
  }
}
