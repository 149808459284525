/* Form styles
----------------------------------- */
form {
  margin-top: $--ls-regular;
  margin-bottom: $--ls-regular;
}
input, input.form-control, .form-control {
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.75rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border: 1px solid $--fc-gray;
  border-radius: $--br-default;
  background-color: $--sc-white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  color: $--sc-gray;
  transition: border-color 0.1s ease-out,
              color  0.1s ease-out,
              background-color 0.1s ease-out;
  display: block;
  width: 100%;
  height: 2.5rem;
  will-change: value, content;
  padding: $--ls-x-small $--ls-regular;
}

input[type='checkbox']{
  -moz-appearance: auto;
  -webkit-appearance: auto;
  width: auto;
  height: auto;
}

textarea, textarea.form-control {
  background-repeat: no-repeat;
  background-position: right 1rem top;
  background-size: 0.75rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border: 1px solid $--fc-gray;
  border-radius: $--br-default $--br-default $--br-small $--br-default;
  background-color: $--sc-white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
  line-height: 18px;
  color: $--sc-gray;
  transition: border-color 0.1s ease-out,
              color  0.1s ease-out,
              background-color 0.1s ease-out;
  display: block;
  width: 100%;
  will-change: value, content;
  padding: 10px $--ls-regular;
  min-height: 2.5rem;
  resize: vertical;
}
input::placeholder{
  color:rgba(0, 0, 0, .2);
}

.help-text {
  display: block;
  margin: 0.2rem 0 $--ls-regular;
  font-size: $--fs-regular;
}
label, .label {
  font-size: $--fs-regular;
  line-height: 1.2rem;
  margin: -4px 0 $--ls-small;
  &.horizontal-input-label{
    display: inline-block;
    text-align: right;
    margin-right: $--ls-x-large;
    +input{
      max-width: 65%;
      display: inline-block;
    }
  }
}

.magic-label {
  position: relative;
  padding-top: $--ls-regular;
}

.magic-label label {
  position: absolute;
  top: 0;
  font-size: $--fs-regular;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s ease-out;
}
.magic-label input:placeholder-shown + label {
  opacity: 0;
  transform: translateY(1rem);
}

input:required + .help-text:not(.no-prefix)::before {
  content: '*Required';
}

input:optional + .help-text:not(.no-prefix)::before {
  content: '*Optional';
}

input:placeholder-shown {
  border-color: $--fc-gray;
  background-image: none;
}

// input:valid,
input.valid, .form-control.valid {
  border-color: $--sc-green;
  background-image: url("data:image/svg+xml,%3Csvg width='45px' height='34px' viewBox='0 0 45 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-56.000000, -59.000000%29' fill='%232EEC96'%3E%3Cpolygon points='70.1468531 85.8671329 97.013986 59 100.58042 62.5664336 70.1468531 93 56 78.8531469 59.5664336 75.2867133'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
input.valid + .help-text {
  color: $--sc-green;
}

input:invalid, input.invalid, .form-control.invalid, input.error {
  border-color: $--sc-red;
  &:not(textarea) {
    background-image: url("data:image/svg+xml,%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate%28-128.000000, -59.000000%29' fill='%23F44336'%3E%3Cpolygon points='157.848404 61.9920213 145.980053 73.8603723 157.848404 85.7287234 154.856383 88.7207447 142.988032 76.8523936 131.119681 88.7207447 128.12766 85.7287234 139.996011 73.8603723 128.12766 61.9920213 131.119681 59 142.988032 70.8683511 154.856383 59'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

input:focus, input:invalid:focus, input.invalid:focus, .form-control.focus, .form-control.invalid.focus {
  border-color: $--sc-blue;
}

input:invalid + .help-text, input.invalid + .help-text, .form-control.invalid + .help-text, .help-text.invalid {
  color: $--sc-red;
}

input[type='email']:invalid + .help-text::before,
input[type='email'].invalid + .help-text::before {
  content: 'You must enter a valid email.'
}

input:out-of-range + .help-text::before {
  content: 'Out of range';
}

input[disabled], .input[disabled], .form-control[disabled], fieldset[disabled], textarea[disabled] {
  cursor: not-allowed;
  background-color: $--fc-sub-gray;
}

fieldset {
  // border:1px solid $--sc-gray;
  border-radius: .2rem;
  font-size: .8rem
}
input[type='checkbox'] + label {
  user-select: none;
}

.checkbox {
  position: relative;
  padding: 0.3rem 0;
}

.checkbox label {
  position: relative;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.5;
  cursor: default;
  user-select: none
}

.checkbox input[type='checkbox'] {
  cursor: pointer;
  z-index: 1;
  outline: none;
  width: 1rem;
  height: 1rem;
  position: relative;
  float: left;
  margin-right: 9px;
  padding: 0; // Counter padding above: input, input.form-control, .form-control
  border: 1px solid $--fc-gray;
  border-radius: 0.2rem;
  background-color: $--sc-white;
  transition: background-color 0.2s ease-out;
}

.checkbox.checkbox-right > label {
  padding-left: 0;

  > input[type='checkbox'] {
    position: absolute;
    top: 0;
    right: -31px;
  }
}

.checkbox input[type='checkbox']:focus {}

.checkbox input[type='checkbox']:checked{
  border-color: $--sc-blue;
  background-color: $--sc-blue;
  &:after {
    color: $--sc-white;
    content: "\f00c";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    font-size: .8em;
    padding: 2px;
  }
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: 10px;
  margin-bottom: 0;
}

.radio {
  position: relative
}

.radio label {
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0 1.5rem;
  font-weight: 400;
  line-height: 1;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.radio input[type='radio'] {
  cursor: pointer;
  z-index: 1;
  outline: none;
  display: block;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 50%;
  border-color: $--fc-gray;
  background-color: $--sc-white;
  transition: background-color 0.2s ease-out;
}

td .radio input[type="radio"]{
  position: relative;
  margin: auto;
}

.radio input[type='radio']:hover {
  background-color: $--fc-gray;
}

.radio input[type='radio']:focus  {}

.radio input[type='radio']:checked {
  border-color: $--sc-blue;
  background-color: $--sc-blue;
}

.radio input[type='radio']:checked {
  border-radius: 50%;
  border-width: 5px;
  background-color: white;
}
.radio input[type='radio']:disabled{
  cursor: not-allowed;
}

.select:not(.form-group):not(label), // TODO Dalton - change select class to be more specific
.multi-select {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  min-height: 2.5rem;
  height: auto;

  &:focus, &.focus,
  input:focus, input.focus {
    border-radius: $--br-default $--br-default 0 0;
  }

  &[disabled], &.disabled,
  input[disabled], input.disabled {
    cursor: not-allowed;
    border-radius: $--br-default;
    background-color: $--fc-sub-gray;
  }

  .options {
    z-index: 999;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    font-size: $--fs-regular;
    background-color: $--sc-white;
    border: 1px solid $--fc-gray;
    border-radius: 0 0 $--br-default $--br-default;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      max-height: 140px;
      overflow-y: auto;
      li {
        display: block;
        width: 100%;
        padding: 4px 12px;
        cursor: pointer;
        &:hover {
          color: $--sc-white;
          background-color: $--sc-gray;
        }
        &.no-results{
          cursor: not-allowed;
          background-color: $--fc-orange;
          &:hover {
            color: $--tc-default;
            background-color: $--fc-orange;
          }
        };
      }
    }
  }

  .selected {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }
    ul {
      margin: 0;
      padding: 0;
      height: auto;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      li {
        position: relative;
        list-style: none;
        width: auto;
        display: flex;
        align-items: center;
        flex: 1;
        &:after {
          content: "\f00d";
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: $--tc-light;
          margin: 0 3px 0 auto;
        }
      }
    }
  }

  .limit {
    z-index: 999;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
    font-size: $--fs-regular;
    background-color: $--sc-white;
    border: 1px solid $--fc-gray;
    border-radius: 0 0 $--br-default $--br-default;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    ul {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      li {
        display: block;
        width: 100%;
        padding: 4px 12px;
        list-style: none;
        cursor: default;
        background-color: #fcf8e3;
        color: #c09853;
        &:hover {
          background-color: #fcf8e3;
          color: #c09853;
        }
      }
    }
  }

  .un-select {
    position: absolute;
    top: 10px;
    right: 20px;
    color: $--tc-light;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $--tc-light;
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }
    &:after {
      content: "\f0d7";
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin: 0 3px 0 auto;
    }
  }
}

.multi-select {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: auto;
  min-height: 2.5rem;
  padding: 4px 8px;

  input,
  input:focus {
    flex: 1;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0 8px;
    border: none;
    border-radius: 0;
  }

  > span {
    cursor: pointer;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border: 1px solid $--fc-gray;
    border-radius: $--br-default;
    padding: 2px 6px;
    margin: 2px;
    i {
      color: $--tc-light;
      margin: 0 5px 0 2.5px;
    }
    &[disabled] {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }

  .selected {
    ul {
      li {
        flex: unset;
        width: auto;
        border: 1px solid $--fc-gray;
        border-radius: $--br-default;
        padding: 2px 6px;
        margin: 2px;
        &:before {
          content: "\f00d";
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: $--tc-light;
          margin: 0 3px;
        }
        &:after {
          content: '';
          display: none;
        }
      }
    }
  }

  .placeholder {
    padding: 0 8px
  }
}
//default custom file input field, FileInputGroup.js.jsx
.custom-file-input{
  label{
    max-width: 250px;
    margin: 0;
    padding: $--ls-small $--ls-regular;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border: 1px solid $--fc-gray;
    transition: all 300ms ease;
    border-radius: 50px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    &.right ~ label{
      border-radius: 50px 0 0 50px;
      border-right: 0;
    }
    &.left + label{
      border-radius: 0 50px 50px 0;
      border-left: 0;
    }
    input[type='file'] {
      display: none;
    }
  }
  .input-group-addon{
    cursor: pointer;
  }
}

.input-group-addon, .input-group-addon:first-child {
  border-right: 0;
  background-color: $--fc-sub-gray;
  border-radius: $--br-default 0 0 $--br-default;
}

.input-group-addon{
  &.right {
      border-left: 0;
      border-radius: 0 $--br-default $--br-default 0;
  }
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group,
.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  margin-top: 0;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Override Vendor Styles
// Bootstrap
@media (min-width: 768px) {
  .form-inline .form-control.multi-select {
    display: flex;
    width: 100%;
  }
}
// Redactor
.redactor-box {
  overflow: hidden;
  border-radius: $--br-default;
  .redactor-toolbar {
    border-radius: $--br-default $--br-default 0 0;
  }
  .redactor-editor {
    border-radius: 0 0 $--br-default $--br-default;
  }
}
// Select2
.select2-container, .select2-container.input-sm {
  .select2-choice {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    margin: 0;
    border: 1px solid $--fc-gray;
    border-radius: $--br-default;
    background-color: $--sc-white;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    color: $--sc-gray;
    transition: border-color 0.1s ease-out,
                color  0.1s ease-out,
                background-color 0.1s ease-out;
    width: 100%;
    height: 2.5rem;
    will-change: value, content;
    padding: $--ls-x-small $--ls-regular;
    display: flex;
    align-items: center;
    &:focus{
      outline: none;
    }
    > span:first-child {
      padding: 0;
    }
    .select2-arrow b {
      display: flex;
      align-items: center;
    }
  }
  .select2-choices {
    width: 100%;
    min-height: 2.5rem;
    border-radius: $--br-default;
    padding: 1px 8px;
    .select2-search-choice {
      border: 1px solid $--fc-gray;
      border-radius: $--br-default;
      margin: 5px 0 3px 5px;
      padding: 2px 6px 2px 18px;
      line-height: unset;
    }
    .select2-search-field {
      margin: 3px 0 0 0;
    }
  }
}
.select2-drop {
  overflow: hidden;
  border-radius: 0 0 $--br-default $--br-default;
  .select2-results {
    padding: 0;
    margin: 0;
    .select2-result-label {
      padding: 4px 12px;
      &:hover {
        color: $--sc-white;
        background-color: $--sc-gray;
      }
    }
    .select2-no-results {
      padding: 4px 12px;
    }
  }
}
.select2-container.form-control.small-inline-select{
  display: inline-block;
}
.form-control,
.form-control.select2-container .select2-choices .select2-search-field input,
.form-control.select2-container .select2-choice,
.form-control.select2-container .select2-choices{
  box-shadow: none;
}

// OLD TODO - Dalton - Remove/ consolidate all styles below

/* Form styles */
.label {
  border-radius: 0;
}

/*fixes browse button to fit the field in Firefox*/
input[type="file"]{
  display:block;
  padding:0px;
}


/* overrides conflict in bootstrap styles between select.form-control and .form-control */
select.input-sm{
  line-height:1.5;
}

#invite-bloggers li {
  line-height: 1.6em;
}

// Labels

label, .no-user-select{
  // keep labels from highlighting when clicked
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -user-select: none;
}

.label {
  font-weight: normal;
  margin-right: 3px;
}

.label-success{
  border-radius:0px;
  background-color:$light-green;
  font-weight:$normal;
  font-size:80%;
}

.label-purple {
  background-color: $light-purple;
}


.input-group .btn-info{
  position:relative;
  border-radius:0;
  margin:0;
  box-shadow:none;
  border: none;

}

.input-group .btn{
  box-shadow:none;
}

.datefields {
  width: auto;
  display: inline-block;
  .form-control {
    width: auto;
  }
  .input-sm {
    line-height: 18px;
  }
}

.index-bg-img{
  width:50px;
  height:50px;
  float:left;
  margin-right:10px;
  border-radius:6px;
}

.small-inline-select{
  width: auto;
  display: inline-block;
}

.label-between-select{
  margin-left: 5px;
  margin-right: 5px;
}

.account-setting-form{
  input[type="checkbox"]{
    margin-top: -6px;
  }
  span.checkbox{
    float: left;
    width: 45%;
  }
}

.form-style-one{
  padding: 20px 25px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  &.form-horizontal .form-group{
    margin-left: 0;
    margin-right: 0;
  }
  .redactor-editor, .redactor-box{
    min-height: 150px;
    &:focus{
      outline: 0;
    }
  }
}

.admin-content-right .form-style-one{
  padding: 0 10px;
  box-shadow: none;
}

.form-section--heading{
  padding: 10px;
  background: rgba(black, 0.15);
  border-bottom: solid 1px rgba(black, 0.15);
  font-family: $font-family-sans-serif;
  color: rgba($light-black, 0.9);
  margin-bottom: 5px;
}

.input--six-digits{
  max-width: 95px;
}

.input--three-digits{
  max-width: 75px;
}

.input-dummy{
  background: #eee;
  cursor: not-allowed;
}

.remove_nested_fields_link{
  line-height: 34px;
}

// reset required asterisk style
abbr[title],
abbr[data-original-title] {
  cursor: default;
  border-bottom: none;
  color: darken($red, 10%);
}

#story-view{
  margin-left: 40px;
}
.dropzone .dz-preview .dz-error-message {
    top: 150px!important;
    opacity: 1!important;
}

/*
Multi-select-containers
*/
.ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection {
  border-bottom:2px solid #fff;
  background:#E9E9E9;
}
.ms-list{
  background:#E9E9E9;
}
.ms-container .ms-list{
  border-radius:0;
  border:none;
  border-top:3px solid #CCCCCB;
}
.ms-container{
  width:100%;
  padding:20px;
}
#some .col-md-8{
  width:100%;
}
.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover{
  color:$light-blue;
}
@media all and (max-width: 992px){
  .ms-container .ms-selectable, .ms-container .ms-selection{
    width:100%;
  }
  .ms-container{
    width:100% !important;
  }
}

/*
 * Pure CSS toggle
 */
.toggle {
  display: none;

  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggle-btn{
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .toggle-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &.disabled {
      background-color: #eeeeee;
      border-color: #ccc;
      color: #5c5c5c;
      pointer-events: none;
      cursor: none;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }

    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    border: 1px solid #e8eae9;
    &:after {
      border-radius: 2em;
      background: #fbfbfb;
      transition:
        left .3s cubic-bezier(
          0.175, 0.885, 0.320, 1.275
        ),
        padding .3s ease, margin .3s ease;
      box-shadow:
        0 0 0 1px rgba(0,0,0,.1),
        0 4px 0 rgba(0,0,0,.08);
    }

    &:hover:after {
      will-change: padding;
    }

    &:active {
      box-shadow: inset 0 0 0 2em #e8eae9;
      &:after {
        padding-right: .8em;
      }
    }

    &:checked + .toggle-btn {
      background: #86d993;
      &:active {
        box-shadow: none;
        &:after {
          margin-left: -.8em;
        }
      }
    }
  }

  &:checked + .toggle-btn:after {
    left: 50%;
  }

  // This sets the actual toggle background color when activated, much clearer
  // than the default grey.
  &:checked + .toggle-btn:not(.disabled)  {
    background-color: $--sc-blue;
  }
}

.form-lr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  & > label {
    padding: 0;
    margin: 0;
  }

  & > :last-child {
    max-width: 50%;
  }
}
