.submission.requirements {
  width: 100%;

  &:after {
    content: '';
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: #000;
    transition: opacity .25s ease-in-out;
  }

  .title-bar {
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .5);
    cursor: pointer;
    .title-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      max-width: 1155px;
      margin: auto;
    }
    .status {
      color: #aaa;
      font-size: 20px;
    }
    .title {
      margin: 0 15px;
    }
    .arrow {
      margin: 0 0 0 auto;
    }

    &.passed {
      background-color: #d4edda;
      .status, .title {
        color: #155724;
      }
    }

    &.failed {
      background-color: #f8d7da;
      .status, .title {
        color: #721c24;
      }
    }
  }

  .list-container {
    max-height: 0;
    overflow-y: auto;
    background: #fff;
    transition: max-height .25s ease-in-out;
    > div {
      max-width: 1155px;
      margin: auto;
    }

    .list-description {
      font-size: 12px;
      padding: 10px 15px;
    }
    .list-title {
      font-size: 18px;
      font-weight: 600;
      padding: 10px 15px 0;
    }
    .list-item {
      font-size: 12px;
      padding: 10px 15px;
      width: auto;
      margin: 10px;
      border-radius: 6px;
      border: none;
      background: #eee;
      .item-title {
        font-size: 14px;
        font-weight: 600;
        margin: 5px 0;
      }
      .item-body {
        padding: 5px;
        span {
          color: #fff;
          background: #343a40;
          border-radius: 4px;
          padding: 2.5px 5px;
          font-weight: 600;
          display: inline-flex;
          line-height: 20px;
          justify-content: center;
          align-items: center;
          margin: 2px;
        }
      }

      &.passed {
        background-color: #d4edda;
        .item-body {
          span {
            background-color: #155724;
          }
        }
      }
  
      &.failed {
        background-color: #f8d7da;
        .item-body {
          span {
            background: #721c24;
          }
        }
      }
    }
  }

  &.open {
    &:after {
      opacity: .7;
      top: -200vh;
      width: 100%;
      height: 300vh;
    }
  
    .title-bar {
      .fa-chevron-up {
        transform: rotate(180deg);
      }
    }
    .list-container {
      max-height: 60vh;
      border-top: 1px solid #ccc;
    }
  }

  /* Laptops, Desktops */
  @media (min-width: 1025px) {
    .list-container {
      .list-description {
        font-size: 14px;
      }
      .list-title {
        font-size: 20px;
      }
      .list-item {
        font-size: 14px;
      }
    }

    &.open {
      .list-container {
        max-height: 70vh;
      }
    }
  } 
}
