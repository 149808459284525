.account-settings.change-password {
  & > button {
    width: 100%;

    .form-lr {
      color: $--tc-default;
      margin: 0;
    }
  }
}
