// For month selector
// All divs that have an id that ends with '_2i'
div[id$=_2i] {
  width: 140px !important;
}

.profile-form {
  margin-bottom:15px;

  .fa-circle-minus {
    margin-top: 9px;
    cursor: pointer;
  }
}

.profile--public{
  .social-icon--facebook_page{
    display: none;
  }
}

.profile--private {
  .social-icon--facebook,
  .social-icon--facebook_page{
    margin-right: 0;
    &:after{
     font-size: 12px;
     margin-left: 3px;
     vertical-align: middle;
     display: inline-block;
     margin-bottom: 4px;
     font-family: $font-family-sans-serif;
    }
  }

  .social-icon--facebook{
    &:after{
      content: 'friends'
    }
  }
}

.social-icon--facebook_page{
  &:after{
    content: 'likes';
  }
}

.profile--page-heading{
  margin-right: 6px;
}

.profile--user-role-badge{
  padding: 3px 4px;
  font-size: 12px;
  font-weight: $light;
  letter-spacing: 1px;
  vertical-align: middle;
  background-color: $navy;
  margin-top: 9px;
  .fa-user{
    opacity: .7;
    padding-left: 2px;
    margin-right: -1px;
  }
}

@media(max-width: 700px){
  .profile--user-name{
    font-size: 24px;
    float: none !important;
  }
  .profile--user-role-badge{
    margin: 10px 0 0;
  }
  .profile--control-btns{
    text-align: left;
    margin-top: 10px;
  }
  .profile--blog-details{
    .select2-container{
      display: block;
    }
  }
}

$profile-img-height: 195px;
$profile-img-width:  $profile-img-height;

.profile-img{
  height: $profile-img-height;
  width: $profile-img-width;
  background-color:$form-grey;
  background-size: cover;
  border-radius: $border-radius-base;
}

.profile--inactive-social-link{
  position: relative;
  &:hover{
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
    i{
      color: $light-black;
    }
  }
}

.profile--inactive-social-link:not(.disabled):after{
  display: none;
  content: 'Connect';
  position: absolute;
  top: 29px;
  left: 0;
  width: 100%;
  text-align: center;
  font-weight: $bold;
  font-size: 10px;
  background: $light-black;
  color: white;
  font-weight: normal;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 1px 0;
}

.profile--inactive-social-link:hover{
  &:after{
    display: block;
  }
}

.profile-bio-column, .profile-image-column{
  padding: 0 15px;
  @media (max-width: 615px){
    float: none;
    width: 100%;
    padding: 15px;
  }
}

.profile-image-column{
  float: left;
  width: 225px;
}

.profile-bio-column{
  overflow: hidden;
  min-height: 210px;
  position: relative;
  @media (max-width: 615px){
    width: 100%;
    padding: 15px;
  }
}

.profile--joined-date, .profile--user-activity{
  font-size: 13px;
  white-space: nowrap;
}

.profile--joined-date{
  margin-top: 10px;
  margin-bottom: 2px;
}

.bio-body{
  min-height: 85px;
  line-height: 1.3;
  @media(max-width: 400px){
    word-wrap: break-word;
  }
}

.profile--blog-url{
  display: inline-block;
  float: left;
  margin: 0 20px 0 0;
  @media( max-width:400px ){
    display: block;
    float: none;
    margin: 5px 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
}

.profile--send-msg-btn{
  margin-left: 10px;
}

.profile--privacy-msg{
  border: solid 1px rgba(216, 216, 216, 0.53);
  background: rgba(255, 255, 0, 0.07);
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  margin: 14px 0 0;
}

.profile--completed-shops-col{
  line-height: 76px;
}

.profile-blog-card{
  .blog-categories .badge {
    margin: 0px 4px 4px 0px;
  }
}

.tooltip-icon-wrapper {
  position: relative;
  font-weight: bold;
}
.google_analytics_auth{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  .column{
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
  .ga_auth_connection{
    text-align: right;
    .disconnect-link{
      color: $--sc-red;
      background-color: white;
      &:hover {
        color: $--sc-sub-gray;
        cursor: pointer;
      }
    }
  }
}

/*legacy style*/
.blog-categories .ms-select-header{
  background-color:#F5F5F5;
}

.profile--blog-links{
  min-height: 25px;
  padding-top: 5px;
}

.profile-social-list {
  margin-top: 5px;
  .social-icon-list--icon {
    vertical-align: middle;
  }
}

.profile--private .profile-social-list .social-icon-list--icon{
  font-size: 26px;
  margin-right: 0;
}

.profile--public .profile-social-list .social-icon-list--icon{
  margin-top: 10px;
}

.btn--user-syndication{
  padding: 4px 8px;
  background: lighten($gallery, 2%);
  box-shadow: 0 0 0 1px rgba(black, 0.1) inset;
  border-radius: $border-radius-base;
  margin: 10px 15px 10px 0;
  display: inline-block;
  float: left;
  color: $light-black;
  text-align: center;
  &:hover{
    background: darken($gallery, 2%);
    color: black;
  }
}

@media(max-width: 800px){
  .btn--user-syndication{
    width: 46%;
    margin-right: 6%;
  }
  .profile-social-list li:nth-child(even) a{
    margin-right: 0;
  }
}

@media(max-width: 375px){
  .btn--user-syndication{
    float: none;
    display: block;
    width: 70%;
    margin-right: 0;
  }
}

.profile--private-details label,
.shop_application--about_applicant label{
  display: block;
  margin: 12px 0 0;
  font-weight: $bold;
  font-family: $font-family-sans-serif;
  font-size: 12px;
}

.profile--private-details h3,
.profile--platforms h3{
  border-bottom: solid 1px rgba($navy, 0.15);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.profile--platforms .social-icon-list--with-tier{
  width: 40px;
  float: left;
  margin-right: 10px;
  text-align: center;
  line-height:12px
}

.profile--follower-count{
  margin-left: 1px;
  vertical-align: middle;
  line-height: 1.2;
}

/* Facebook Auth pages */
.facebook-pages {
  width:25%;
  float:left;
}

.facebook-auth-page {
  display: inline;
}

.facebook-auth-input {
  .checkbox input[type="checkbox"] {
    float:none;
    margin-left:0;
  }
  a {
    float:none;
    margin:0;
  }
}

.applicant-blog-link{
  font-size: 20px;
}

.profile--blog-details .blog-details--well{
  padding-top: 10px;
  padding-top: 10px;
  .fa-question-circle{
    padding-right: 5px;
  }
}

#plugin-status .btn-primary {
  cursor: pointer;
}

.applicant-profile-img a {
  height: 300px;
  width: 300px;
  display: block;
  color: transparent;
  background-size: auto 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position:top center;
}

.bg-profile-default{
  background-repeat:no-repeat;
  background-size: auto 100%;
  background-color:$form-grey;
  background-position:top center;
}

#ms-profile_retailer_ids{
  .ms-list{
    height:300px;
    max-height:300px;
  }
}

// on set_payment_settings form, make tooltips wider,
// the body has to be targeting because tooltips are now outside of initiating element
body[data-action="set_payment_settings"]{
  .tooltip-inner{
    max-width: 461px;
  }
}


// Missing info tabs
#profile_nav {
  .has-missing {
    background-color: $holly;
    & > a {
      color: white;
    }
  }
  .tooltip-inner {
    max-width: 400px;
  }
}

.user-shops-card{
  position: relative;
  background: white;
  margin: 15px 0;
  height: 250px;
  border: solid 1px rgba(255, 255, 255, 0.19);
  border-radius: $border-radius-base;
  z-index: 0;

  .user-grade{
    position: absolute;
    right: -1px;
    top: -1px;
    padding: 20px;
    font-size: 18px;
    background: #FFF;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.11) inset;
    border-radius: 50%;
    z-index: 3;
  }

  .name{
    display: inline-block;
    width: 100%;
    max-height:70px;
    padding-left:10px;
    padding-right:10px;
    padding-top:5px;
    font-size: 16px;
    color:$light-black;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 3;
  }

  .date{
    display: block;
    position: absolute;
    left: 30px;
    top: 0px;
    color: #333;
    padding: 5px;
    font-size: 12px;
  }

  .user-content{
    width:100%;
    height:150px;
    position: absolute;
    bottom:0;
    left:0;
    background-position: top center;
    background-repeat:no-repeat;
    background-size:cover;
    border-top-right-radius:31px;
    box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.11),0 0 20px 1px rgba(0, 0, 0, 0.12) inset;
    border-top: solid 1px rgba(0, 0, 0, 0.15);
    &:hover:before{
      background:rgba(black,0);
    }
    &:before{
      display:block;
      width:100%;
      height:150px;
      background:transparent;
      background:rgba(black,.22);
      transition: background .3s ease-in-out;
      border-top-right-radius: 31px;
    }
    &.none{
      border-radius: 0;
      box-shadow: none;
      background: rgba(248, 248, 248, 0.5);
      border-top: solid 1px #FFF;
    }
  }

  .blog-post-image{
    max-height: 120px;
    max-width:100%;
    opacity: 0.25;
    position: absolute;
    right:10%;
    top:5px;
    z-index:-1;
    font-size:0px;
  }

  .content-link{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 7px;
    background: #333;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    max-height:70px;
    color: #eee;
    font-size: 14px;
    text-shadow: -1px -1px 1px #000;
    overflow:hidden;
    z-index: 2;
    &.none{
      color: #C5C5C5;
      color:rgba(101, 101, 101, 0.47);
      text-shadow: none;
      box-shadow: none;
      border: none;
      margin-bottom: 20px;
      font-size: 15px;
      background:transparent;
      text-align: center;
    }
  }
}

i.round-icon{
  position: relative;
  top: 4px;
  left: 10px;
  font-size:20px;
  color:lighten($light-blue,10%);
  width: 27px;
}

.no-link{
  cursor: default;
}

.retailer-fields .form-group {
  margin-bottom: 0;
}

#state_select_wrapper{
  .select2-container{
    width:100%;
  }
}

#profile_settings{
  #profile-picture-preview {
    max-width: 245px;
  }
}

.delete-account {
  color: red;
  text-decoration: underline;
  margin-left: 5px;
}

.exit-survey-title {
  color: $true-blue !important;
}

.exit-survey-text {
  resize: both;
  min-width: 100%;
  max-width: 100%;
}

.exit-survey-input {
  margin-bottom: 10px;
}

#influencer_exit_survey_other_text::-webkit-input-placeholder {
  color: $darkgray;
  font-style: italic;
  padding: 5px 0 0 5px;
}

// profile specific modal css for pinterest and tiktok auth methods
.profiles_body, .social-accounts-card{
  .modal{
    display: block;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    .modal-dialog{
      top: 12%;
    }
  }
}
