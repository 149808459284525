$datatable-interface-border: #ccc;

/*
Table Styles
*/

.table{
  background: white;
}

.table.table-bordered > thead > tr > th,
.table.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}

table.table.table-bordered{
  border-collapse: separate;
  border-spacing: 0;
  border: solid 1px $datatable-interface-border;
  th,
  td {
    border-left-width: 0;
  }
  th:last-child, th:last-child,
  td:last-child,
  td:last-child {
    border-right-width: 0;
  }
  tbody th,
  tbody td {
    border-bottom-width: 0;
  }
}

table.dataTable,.table,.table-bordered{
  font-size: 13px;
  border-radius: 2px;
  border: none;
  a:not(.btn){
    color: $true-blue;
    &:hover{
      text-decoration: underline;
    }
  }
  tbody  tr  td{
    padding: 5px 8px;
    vertical-align: middle;
    ul, li{
      padding: 0;
      list-style: none;
    }
    p{
      margin: 0;
    }
  }
}

// Datatables
.dataTables_wrapper.dt-bootstrap{
  table.dataTable{
    width:100% !important;
  }
  .dataTables_filter{
    > label {
      width: 100%;
      position: relative;
      &:before{
        font-family: "Font Awesome 5 Free";
        content: "\f002";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 10px;
        line-height: 2.5rem;
        opacity: 0.5;
      }
    }
    input[type="search"]{
      margin: 0;
      padding-right: 30px;
      width: 100%;
      font-size: 13px;
      height: 2.5rem;
      border-radius: $--br-default;
      &::-webkit-input-placeholder {
        font-style:italic;
      }
      &::-moz-placeholder {
        font-style:italic;
      }
      &:-moz-placeholder {
        font-style:italic;
      }
      &:-ms-placeholder {
        font-style:italic;
      }
      &:focus{
        outline: 0;
      }
    }
  }
  .dataTables_length {
    .select2-container{
      float: left;
      margin-right: 4px;
    }
    label{
      line-height: 30px;
      margin: 4px 0 0;
    }
  }
}

.dt-length, .dt-info {
  padding-left:0;
}

.dt-colvis, .dt-pagination{
  padding-right: 0;
}

.dt-colvis .dt-buttons{
  &.btn-group{
    .btn{
      border-radius: $border-radius-base;
      &+ .btn{
        margin-left: 15px;
      }
      &.btn-primary:hover{
        background-color: darken($btn-primary-bg, 5%);
        border-color: rgba(18, 91, 154, 0);
      }
    }
  }
}

.dataTables_paginate ul.pagination{
  padding-top: 12px;
}

.dataTables_paginate .pagination {
  li:not(.active) a{
    border-color: $datatable-interface-border;
  }
  li > a {
    padding: 5px 10px;
    border-radius: $border-radius-base;
  }
  li.disabled a{
    color: $light-grey;
  }
  li:not(:last-child) a{
    margin-right:5px;
  }
}

.dataTables_filter {
  margin: 1px 0 4px;
}

.dataTables_scrollHeadInner table.dataTable{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dataTables_scrollBody{
  min-height: 150px;
}

.dataTables_scrollBody table.dataTable{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dataTables_processing{
  border: none;
  text-align: center;
  .datatable_spinner{
    padding-top: 40px;
    font-size: 20px;
    text-align: center;
    color: $true-blue;
    transition-delay: 0.1s;
    i{
      margin-right: 5px;
      font-size: 18px;
    }
  }
}

// column visibility section
.dt-button-collection.dropdown-menu{
  background: $navy;
  padding: 10px;
  width: 425px;
  max-height: 300px;
  overflow: auto;
  font-size: 13px;
  li{
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -user-select: none;
    &:focus{
      outline: none;
    }
    display: block;
    float: left;
    width: 33.3333%;
  }
  li a{
    color: $dusty-grey;
    background: $navy;
    display: block;
    padding: 3px 10px;
    position: relative;
    &:before{
      content: 'x';
      color: $red;
      position: absolute;
      left: 2px;
    }
  }
  li.active a{
    color: white;
    background: none;
    border-radius: $border-radius-base;
    &:hover{
      background: $dark-navy;
    }
    &:before{
      content: '';
    }
  }
}

table.datatable thead tr th.sorting{
  &:after{
    display: none;
  }
  &:hover:after{
    display: block;
  }
}

.fixedHeader-floating thead{
  box-shadow: -1px 3px 20px 0 rgba(0, 0, 0, 0.2);
}

.table--record-size {
  padding-left: 0;
  label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    margin: 8px 0 0;
  }
  select{
    display: inline-block;
    width: 75px;
    margin: 0 3px;
    cursor: pointer;
  }
}

.search-box--label{
  position: relative;
  width: 100%;
  &::before{
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 2.5rem;
    opacity: 0.5;
  }
}