// Subtle gradient animation over the card
@keyframes skeleton-card {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}

.skeleton.skeleton-card{
  overflow: hidden;
  animation: skeleton-card 1s infinite;
  background: linear-gradient(270deg, transparent, #fafafa);
  background-size: 400% 400%;

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .left {
      .big-title {
        border-radius: 20px;
        background-color: $--fc-sub-gray;
        height: 20px;
        width: 48vw;
        margin-bottom: 4px;
      }

      .little-title {
        border-radius: 20px;
        background-color: $--fc-sub-gray;
        height: 14px;
        width: 84%;
      }
    }

    .right {
      .big-metrics {
        border-radius: 20px;
        background-color: $--fc-gray;
        width: 20vw;
        height: 32px;
      }
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .little-text {
      width: 20vw;
      height: 12px;
      border-radius: 20px;
      background-color: $--fc-sub-gray;
    }

    .fake-badge {
      width: 25vw;
      height: 20px;
      border-radius: 20px;
      background-color: $--fc-blue;
    }
  }
}
