/* Font Type */
$--ft-default: 'Lato', Arial, sans-serif;

/* Font Sizes */
$--fs-x-small:     .75em; /* 12px */
$--fs-small:       .8em; /* 14px */
$--fs-regular:     16px; /* 1em */
$--fs-large:       1.125em; /* 18px */
$--fs-x-large:     1.25em; /* 20px */
$--fs-xx-large:    1.5em; /* 24px */
$--fs-xxx-large:   1.6em; /* 28px */

/* Font Weights */
$--fw-light:       300;
$--fw-regular:     400;
$--fw-bold:        700;
$--fw-black:       900;

/* Solid Colors */
$--sc-blue:        #4E76E3;
$--sc-purple:      #8E6BE8;
$--sc-teal:        #A9E4FF;
$--sc-green:       #5DC374;
$--sc-red:         #EC5A68;
$--sc-orange:      #F5B055;
$--sc-gray:        #3D425D;
$--sc-sub-gray:    #8D8EA5;
$--sc-background:  #F1F6FC;
$--sc-white:       #FFFFFF;
$--sc-black:       #000000;
$--sc-link:        #1eaedb;

/* Faded Colors */
$--fc-blue:        #CCE5FF;
$--fc-purple:      #DDD1FD;
$--fc-teal:        #D1ECF1;
$--fc-green:       #DEFFEF;
$--fc-red:         #F8D7DA;
$--fc-orange:      #FFF3CD;
$--fc-gray:        #D7D8DD;
$--fc-sub-gray:    #E7E8EA;
$--fc-background:  #FCFEFF;

/* Text Colors */
$--tc-default: $--sc-gray;
$--tc-light:   $--sc-sub-gray;
$--lc-link:    $--sc-link;
// variables below are used for alerts
//They are darkened to reach above a 4 contrast ratio with their faded color counterparts
$--lc-red:     darken($--sc-red, 20%);
$--lc-blue:    darken($--sc-blue, 5%);
$--lc-green:   darken($--sc-green, 20%);
$--lc-orange:  darken($--sc-orange, 30%);

/* Gradients */
$--gradient-blue-purple: linear-gradient(to right, $--sc-blue 0%, $--sc-purple 100%);
$--gradient-green-teal:  linear-gradient(to right, $--sc-green 0%, $--sc-teal 100%);
$--gradient-orange-red:  linear-gradient(to right, $--sc-orange 0%, $--sc-red 100%);
$--gradient-grey:        linear-gradient(to right, $--sc-gray 0%, $--sc-sub-gray 100%);

/* Brand Colors */
$facebook-color:    #3A5795;
$twitter-color:     #55ACEE;
$pinterest-color:   #BD081C;
$youtube-color:     #e62117;
$linkedin-color:    #0177B5;
$instagram-color:   #000;
$vine-color:        #00bf8f;
$google-plus-color: #DB4437;
$tumblr-color:      #34465D;
$tiktok-color:      #010101;
$instagram-color-gradient: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

/* Layout Spacing */
$--ls-x-small:     4px;
$--ls-small:       8px;
$--ls-regular:     16px;
$--ls-large:       24px;
$--ls-x-large:     32px;

/* Bootstrap Variables */

/* Break Points */
$--bp-small:       576px;
$--bp-medium:      768px;
$--bp-large:       992px;
$--bp-x-large:     1200px;

/* Border Radius */
$--br-small:        4px;
$--br-default:      20px;
$--br-max:          100px;


/* ⚠ WARNING DEPRECATED VARIABLES BELOW -- ENTER AT YOUR OWN RISK ⚠ */

// Font Type
$font-family-sans-serif: $--ft-default;
// Font Weight
$light:     $--fw-light;
$normal:    $--fw-regular;
$semi-bold: $--fw-bold;
$bold:      $--fw-black;
// Containers
$container-sm: $--bp-small;
$container-md: $--bp-medium;
$container-lg: $--bp-x-large;
// Text Color
$grey-text: $--tc-default;
// Blue Colors
$blue-text:                $--sc-blue;
$sofabblue:                $--sc-blue;
$brand-info:               $--sc-blue;
$dark-blue:                $--sc-blue;
$denim:                    $--sc-blue;
$fun-blue:                 $--sc-blue;
$curious-blue:             $--sc-blue;
$blue-text:                $--sc-blue;
$true-blue:                $--sc-blue;
$true-blue-darker:         $--sc-blue;
$link-color:               $--sc-blue;
$link-hover-color:         darken($--sc-blue,10%);
$brighter-link-color:      $--sc-blue;
$navbar-blue:              $--sc-blue;
$header-blue:              $--sc-blue;
$btn-primary-bg:           $--sc-blue;
$pagination-active-bg:     $--sc-blue;
$pagination-active-border: $--sc-blue;
$service:                  $--sc-blue;
$sofabgrey:                $--fc-blue;
$light-blue:               $--fc-blue;
$lighter-blue:             $--fc-blue;
$admin-campaigns-bg:       $--fc-blue;
// Grey Colors
$dark-grey:                $--sc-gray;
$darkgray:                 $--sc-gray;
$light-black:              $--sc-gray;
$navy:                     $--sc-gray;
$dark-navy:                $--sc-gray;
$dropdown-link-active-bg:  $--sc-gray;
$dropdown-link-hover-bg:   $--sc-gray;
$input-active-border:      $--sc-gray;
$caret-color:              $--sc-gray;
$tooltip-bg:               $--sc-gray;
$second_nav_icon-color:    $--sc-gray;
$popover-title-bg:         $--sc-gray;
$lightgrey:                $--fc-gray;
$light-grey:               $--fc-gray;
$silver:                   $--fc-gray;
$silver-2:                 $--fc-gray;
$default-btn-hover-border: $--fc-gray;
$btn-default-border:       $--fc-gray;
$border-grey:              $--sc-sub-gray;
$dusty-grey:               $--sc-sub-gray;
$campaigns-table-planning: $--sc-sub-gray;
$no-product-category:      $--sc-sub-gray;
$gallery:                  $--fc-sub-gray;
$form-grey:                $--fc-sub-gray;
$form-bg-color:            $--fc-sub-gray;
$default-btn-hover-bg:     $--fc-sub-gray;
$table-bg-accent:          $--fc-sub-gray;
$nav-link-hover-bg:        $--fc-sub-gray;
// Green Colors
$sofabgreen:               $--sc-green;
$brand-primary:            $--sc-green;
$light-green:              $--sc-green;
$evergreen:                $--sc-green;
$dark-green:               $--sc-green;
$success-green:            $--sc-green;
$christi:                  $--sc-green;
$progress-bar-success-bg:  $--sc-green;
$entertainment:            $--sc-green;
$success-green-background: $--fc-green;
$success-green-border:     $--fc-green;
// Red Colors
$red:                   $--sc-red;
$error-red:             $--sc-red;
$danger-red:            $--sc-red;
$danger-red-background: $--fc-red;
$danger-red-border:     $--fc-red;
$sporting-goods:        $--fc-red;
// Orange / Yellow Colors
$warning-yellow:  $--sc-orange;
$orange-you-glad: $--sc-orange;
$supernova:       $--sc-orange;
$golden:          $--sc-orange;
$electronics:     $--sc-orange;
$pet:             $--sc-orange;
$caution-yellow:  $--fc-orange;
$food---beverage: $--fc-orange;
// Purple Colors
$cb_purple_light:             $--sc-purple;
$dark-purple:                 $--sc-purple;
$holly:                       $--sc-purple;
$hba:                         $--sc-purple;
$light-purple:                $--fc-purple;
$retail-experience-ecommerce: $--fc-purple;
// Teal Colors
$pelorous:    $--sc-teal;
$consumables: $--sc-teal;
// Other Colors
$navbar-default-bg:         $--sc-white;
$dropdown-link-hover-color: $--sc-white;
$dropdown-link-color:       $--sc-black;
$blue-grey:                 $--fc-background;
// Other Styles
$tooltip-opacity: 1;
$navbar-border-radius: 0;
$navbar-margin-bottom: 0;
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-border-color: transparent;
$btn-primary-border: transparent;
$alert-info-border: transparent;
$border-radius-base: $--br-small;
$global-text-shadow: 0 1px 1px rgba(black, .25);
$caret-width-base: 5px;
$icon_interaction_transition:  0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95) all;
$three-column: 1100px;
$two-column: 1000px;
$one-column: 570px;
$large-screen-gutter: 25px;
$small-screen-gutter: 15px;
$sidebar-breakpoint-large: 1280px;
$sidebar-breakpoint-small: 1020px;
$search-img-width: 162px;
$quote-container-height: 172px;
$body-font-size: $--fs-regular;
@function em($pixels, $context: $body-font-size) {
  @return #{calc($pixels / $context)}em;
}
// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  1000px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;
// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg:                  9999px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;
