.admin_round_tasks_body {

  label {
    padding-top: 6px;
  }

}

#active-influencers {
  span.label.label-purple {
    display: inline-block;
    vertical-align: top;
    margin: 1px 0 0 3px;
  }
}

.shop_app_blog_selector_widget *{
  margin-right: 5px;
}

.shop_app_blog_selector_widget {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
}

.admin_community_round_tasks_body,
.admin_community_general_requirements_body {
  .form {
    .form_input_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row wrap;
      margin: 15px 0;
      &.children_2 {
        .input_wrap {
          width: 48%;
          margin: 0;
        }
      }
    }
    .input_wrap {
      margin: 15px 0;
      &#pinterest_url{
        width: 100%
      }
      label {
        display: inline-block;
        color: $grey-text;
        font-size: 14px;
        font-weight: 400;
      }
      .error{
        color: $danger-red;
      }
    }
  }

  .centered-modal{
    &.in{
      display: flex !important;
    }
    .modal-dialog {
      margin: auto;
    }
  } 
}
