.submission-feedback {
  display: block;
  width: 100%;
  max-width: 1155px;
  padding: 0 12px;
  margin: 10px auto;
  &.primary {
    color: #004085;
  }
  &.info {
    color: #0c5460;
  }
  &.error {
    color: $danger-red;
  }
  .feedback-title {
    font-size: 16px;
    font-weight: 600;
  }
  .feedback-timestamp {
    display: block;
    text-align: right;
  }

  .alert-component {
    margin: 5px auto;
  }
}