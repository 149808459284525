.application_layout.community_login_settings_body {

  .container#main {
    margin: 0;
    width: 100%;
    padding: 0;
    width: 100%;
    max-width: unset;
  }
}

.community_login_settings_container,
.community_theme_settings_container{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  .community_login_settings_fields,
  .community_theme_settings_fields{
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    padding: 15px;
    flex-grow: 1;
    width: 250px;
    margin-right: 20px;
    input {
      width: 100%;
      will-change: value, content;
    }
    textarea {
      width: 100%;
      resize: vertical;
    }
    .community_login_settings_input,
    .community_theme_settings_input{
      position: relative;
      margin-bottom: 10px;
      label, input, textarea{
        width: 100%;
      }

      &.error{
        label, .error_text{
          color: $danger-red
        }
      }
      .info_text{
        color: $--sc-blue
      }
      .primary_color_settings_inputs,
      .background_color_settings_inputs,
      .text_color_settings_inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .settings_color_picker,
        .primary_color_picker,
        .text_color_picker{
          &.color_input{
            padding: 0 12px;
            margin-right: 5px;
            max-width: 50px;
          }
        }
      }
      //css solution to ellipsis when file name is too long for container
      .community-image-upload{
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow:    ellipsis;
      }
      .community_access_warning{
        i{
          margin-right: 8px;
        }
      }
    }
  }
  .community_login_settings_preview,
  .community_theme_settings_preview{
    flex-grow: 4;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    max-width: 800px;
    .community-logins{
      border-radius: $--br-default;
    }
    .community-header-preview{
      border-radius: $--br-default;
      header{
        border-radius: $--br-default;
      }
      .header-preview--skeletons{
        padding: $--ls-regular;
      }
    }
  }
  .community_login_settings_actions,
  .community_theme_settings_actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}