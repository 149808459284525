

.style-guide{
  max-width: 1200px !important;
  font-size: $--fs-regular;
  padding: $--ls-large 0;
  .style-guide-section{
    margin-bottom: $--ls-x-large;
    .section-description{
      padding: 0 $--ls-regular;
      margin-bottom: $--ls-x-large;
    }
    .styles-container{
      padding: 0 $--ls-regular;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin: 0;
    .column {
      min-width: 300px;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      padding: 0 $--ls-regular;
    }
  }
  hr{
    padding: .5px 0;
    margin: $--ls-small 0;
  }
  .section-header{
    margin-bottom: $--ls-large;;
    padding-bottom: $--ls-small;
    border-bottom: 3px solid $--fc-sub-gray;
    font-weight: $--fw-black;
  }
  .sub-header{
    font-weight: $--fw-bold;
  }
  .sub-section{
    margin-bottom: $--ls-x-large;
    .sub-section-header{
      color: $--sc-sub-gray;
      font-weight: $--fw-bold;
    }
    .sub-section-body{

    }
  }
  #colors{
    .color-swatches-container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;
      .swatch{
        text-align: center;
        margin: $--ls-regular;
        .color{
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 100px;
          width: 140px;
          border-radius: $--br-default;
          border: 1px solid $--fc-gray;
          margin-bottom: 4px;
          .color{
            text-shadow: $global-text-shadow;
          }
        }
        &.gradient .color{
          width: 245px;
        }
      }
    }
  }
  #typography{
    .text-color{
      .text-color-white{
        border-radius: $--br-small;
        padding: $--ls-x-small;
      }
    }
    .text-sizing{
      p > span{
        color: $--sc-blue;
        font-size: 12px;
      }
    }
    .icons{
      i{
        padding: 0 $--ls-small;
      }
    }
    .hierarchy-example{
      max-width: 600px;
      padding: 0 $--ls-regular;
      .hierarchy-row{
        display: flex;
        align-items: center;
        h1{
          font-weight: $--fw-black;
        }
        .divider {
          flex-grow: 1;
          border-bottom: 1px solid $--sc-blue;
          margin: 5px
        }
        .hierarchy-row-color{
          display: inline-block;
          height: 14px;
          width: 14px;
          border-radius: $--br-small;
          margin-right: $--ls-x-small;
        }
        p{
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  #buttons{
    .styles-container{
      .button-styles{
        .examples{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          >button{
            margin-right: $--ls-large;
          }
        }
        .code-example{
          display: block;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
  #alerts{
    .alert-styles{
      .examples{
        max-width: 600px;
        margin-bottom: $--ls-large;
      }
    }
  }
  #cards{
    .styles-container{
      .card-style{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        .example, .code-example{
          display: flex;
          flex-direction: column;
          flex-basis: 100%;
        }
        .example{
          flex: 1;
          .card, .card-plus{
            width: 320px;
          }
        }
        .code-example{
          flex: 2;
          pre{
            margin-top: 0;
          }
        }
      }
    }
  }
  #tooltips{
    .tooltip-styles{
      .examples{
        padding: 50px 0;
      }
    }
  }
  #forms{
    .form-styles{
      h2{
        margin-bottom: $--ls-regular;
      }
      .examples{
        display: flex;
        align-items: flex-end;
        >div{
          margin: 0 $--ls-regular;
        }
      }
      .form-input-labels,
      .form-addon-inputs,
      .form-textarea,
      .form-select-inputs{
        .examples{
          margin-bottom: $--ls-regular;
        }
      }
      .form-textarea{
        .examples{
          textarea{
            max-width: 400px;
          }
        }
      }
    }
  }

  //overide prismjs prism.css value for this strange random text highlighting - JW
  pre[class*="language-"]{
    border-radius: 20px;
  }
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    background: inherit;
  }
}