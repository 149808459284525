#messages table td{
	border-top: solid 1px #eee;
}

tr.message{
	color: #000;
	&:hover{
    cursor: pointer;
	}
	&.true{
		color:darken($light-grey,17%);
		background: #FAFAFA;
  }
}

.message_profile_pic{
	max-width:120px;
	border: solid 1px #E4E4E4;
	@include border-radius(5px);
}

.message_text{
	font-size:16px;
	line-height:1.25;
	margin:0;
	margin-bottom:10px;
	padding:1em 0;
	border-top: solid 1px #EEE;
	border-bottom: solid 1px #eee;
}

.message_body textarea{
	min-height:100px;
}

.conversations_body {
  label {
    line-height: 26px;
  }
  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > li {
        margin: 0 2.5px;
        cursor: pointer;
        padding: 5px 10px;
        list-style: none;
        // border-radius: 3px;
        color: #187bd1;
        background-color: #fff;
        border: 1px solid #ddd;
        &:hover {
          color: #1360a3;
          background-color: #eeeeee;
        }
        &[data-disabled=true] {
          color: #D1D1D1;
          background: #fff;
          cursor: not-allowed;
        }
        &[data-currentPage=true] {
          cursor: default;
          color: #fff;
          background-color: #187bd1;
        }
      }
    }
  }
}

.user-event--row {
  text-align: center;
  .user-event--container {
    display: inline-block;
    width: 100%;
    .user-event {
      color: $dark-grey;
      border-radius: 4px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      padding: 2px 0 4px 0;
      .user-event--detail {
        flex: 1 100%;
        align-self: center;
      }
    }
  }
}