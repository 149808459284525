.faq-menu{
    ul{
      list-style: none;
      padding-left: 0px;
      h3{
        padding-left:5px;
        }
      .btn{
        margin:5px;
        width:100%;
        text-align: left;
        }
      }
}
.faq-section{
  margin-bottom: 6px;
  padding: 6px 8px;
  font-size: 15px;
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius:5px;
  h4{
    margin: 0;
    padding: 4px 0;
  }
}
.faq-answers{
  height:200px;
}

.faq-hide{
  display:none
}

form.ticket-form{
  .alert{
    border-radius:4px;
    width: 48.5%;
    margin-left: 25.7%;
  }
  .fa, .fas, .fab, .far{
    margin-right:5px;
  }
}

#knowledgebase-search-input{
  margin: 25px auto;
  width: 75%;
}

.category-menu{
  position: relative;
  white-space: nowrap;
  margin: 10px auto 0 auto;
  padding: 10px;
  box-shadow: 0 0 0 1px rgba(225, 231, 234, 0.45);
  border-radius: $border-radius-base;
  background: #fff;
  color: $blue-text;
  .menu-icon{
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
  }
  .menu-title{
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
    h3{
      margin: 0 0 0 40px;
    }
  }
  &:hover{
    transition: all .1s ease-in-out;
    box-shadow: 1px 1px 5px 4px rgba(225, 231, 234, 1);
    text-decoration: underline;
      color: $dark-blue;
  }
}

.articles-collapse{
  position: relative;
  width: 98%;
  margin: 0 auto 0 auto;
  background: lighten($light-grey, 5%);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media(max-width: 850px){
  #knowledgebase-search-input{
    width: 90%;
  }
  #knowledgebase-container{
    width: 100%;
    padding: 0;
    margin: 0;
    .row{
      margin: 0 auto;
      padding: 0;
    }
  }
}

#article{
  p{
    font-size: 1.1em;
    margin-top: 25px;
    text-indent: inherit;
  }
}

#knowledgebase-container{
  ul{
    margin: 0;
    padding: 20px 20px 10px 40px;
    list-style-type: none;
    li{
      margin: 0 auto;
      padding: 9px 0;
      font-size: 1.2em;
      a{
        color: $grey-text;
        &:hover{
        text-decoration: underline;
        color: $dark-blue;
        }
      }
    }
  }
}
