.sc-tooltip {
  z-index: 99;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: max-content;
  height: auto;
  max-width: 300px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  background-color: $--sc-gray;
  text-transform: initial;
  &.hidden {
    visibility: hidden;
  }
  &.light {
    background-color: $--sc-background;
    color: $--sc-black;
    box-shadow: 1px 2px 3px 1px $--sc-black;
    .tooltip-arrow {
      border-bottom: 8px solid $--sc-background;
    }
  }
  .tooltip-message {
    white-space: normal;
    text-align: left;
    &.center {
      text-align: center;
    }
  }
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $--sc-gray;
  }
}