.submission.annotation-row{
  padding-left: 4px;
  display: flex;
  align-items: stretch;
  
  .left {
    flex: 1;

    blockquote {
      font-size: 10pt;
      border-left: 3px solid $denim;
      margin-bottom: 4px;
    }

    .comment {
      color: black;
    }
  }

  .right {
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $light-green;
    color: white;
    font-size: 12pt;
    
    &.done {
      background-color: $warning-yellow;
    }
  }
}
