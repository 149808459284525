.chip-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.chip {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 6px 6px 0;
  padding: 4px 12px;
  font-size: $--fs-small;
  border-radius: $--br-default;

  &:last-of-type {
    margin-right: 0;
  }

  &.default {
    color: $--sc-white;
    background-color: $--sc-sub-gray;
  }
}