body[data-controller="admin/promoters_v2"] {
  #promoter-view{
    .group_thumb{
      background-size: cover;
    }
    .promoter-datatable--blogpost:visited {
      color: #7701e2;
    }
    td:first-of-type{
      text-align: center;
    }
    td:nth-of-type(3){
      max-width: 155px !important;
      white-space: normal;
      word-wrap: break-word;
    }
    td:nth-of-type(5){
      min-width: 155px !important;
    }
    td:last-of-type{
      text-align: center;
      max-width: 50px;
      i {
        cursor: pointer;
      }
      .disabled {
        pointer-events: auto;
        cursor: not-allowed;
        color: lightgrey;
      }
    }

  }

  #promoter-facebook-ad-campaign-view {
    td.js-datefield {
      input[disabled] {
        color: darkgray;
      }
    }
  }

  .creation-errors {
    & > ul {
      list-style-type: unset;
    }
  }

  #promoter_ad_notify_cs{
    .modal-body{
      h4, i {
        display: block;
        text-align: center;
      }
    }
  }

  #promoter-scheduler-view_wrapper{
    i.fa, i.fas, i.fab, i.far{
      padding: 0 5px;
    }

    .targeting-options{
      :disabled{
        pointer-events: auto;
        cursor: not-allowed;
      }
    }
  }

  .ui-autocomplete.ui-front{
    z-index: 3000;
  }

  .ui-autocomplete-loading{
    background: white url('select2-spinner.gif') 98% center no-repeat;
  }

  .ui-autocomplete {
    max-height: 100px;
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #detailed-targeting-selected-container{
    ul{
      border: none;
    }
  }

  #detailed-targeting-options{
    height: 34px;
    vertical-align: middle;
    padding-left: 12px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  .select2-hidden {
    display:none !important;
  }



  $wizard-grey: #e8e8e8;
  $wizard-lightgrey: #666;
  $wizard-border-color: #e5e5e5;

  .wizard-header {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 710px;

    .content-link{
      color: #444;
      &:hover{
        text-decoration: underline;
      }
    }

    .wizard-close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $wizard-lightgrey;
      cursor: pointer;
      line-height: 22px;
    }
  }

  .modal-body.promoters {
    position: relative;
    display: flex;
    flex: 1 100%;
    flex-flow: column;
    padding: 15px;

    .modal-inner-content {
      display: flex;
      flex-flow: column;
      flex: 1 100%;
      & > div {
        display: flex;
        flex: 1 100%;
      }
    }

    .section {
      display: flex;
      flex: 1 100%;
      flex-flow: column;
      border-radius: 6px;
      border: 1px solid $wizard-border-color;
      &.top {
        margin-bottom: 10px;
      }
      &.bottom {
        margin-top: 10px;
      }
      &.right {
        margin-left: 10px;
      }
      &.left {
        margin-right: 10px;
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      min-height: 45px;
      padding: 5px 10px;
      border-bottom: 1px solid $wizard-border-color;
      & > * {
        flex: 1 100%;
      }
      select {
        height: 30px;
        padding-left: 5px;
      }
      .select-wrapper {
        &:before {
          line-height: 30px;
        }
      }
    }

    .section-body {
      label {
        opacity: .8;
      }
    }

    .section-footer {
      display: flex;
      align-items: center;
      min-height: 45px;
      margin-top: auto;
      padding: 5px 10px;
      border-top: 1px solid $wizard-border-color;
      &:empty {
        display: none;
      }
    }

    .select-wrapper{
      position: relative;
      width: 170px;
    }

    label {
      float: none;
      display: block;
      margin-bottom: 6px;
      padding-top: 7px;
      text-align: left;
      font-size: 12px;
      line-height: 13px;
      font-weight: 700;
      font-family: $font-family-sans-serif;
      &.error{
        margin-bottom: 15px;
        padding: 0;
        text-align: center;
        &:empty{
          display: none;
        }
      }
    }

    select {
      text-indent: 1px;
      text-overflow: '';
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }

    .checkbox{
      input[type='checkbox']{
        margin-top: 0;
      }
    }

    .btn-primary{
      height: 34px;
    }

    .form-control {
      border-color: #DAE3E8;
    }

    .overflow-scroll{
      overflow-y: auto;
    }
  }

  .modal-next{
    align-self: flex-end;
    max-height: 50px;
    margin: auto 0 0;
    padding: 15px 0 0;
    i {
      padding-left: 10px;
      font-size: 12px;
    }
  }

  .ad-nav {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: space-evenly;
    max-width: 80%;
    margin: 10px auto 0;
    padding: 0 10px;

    .nav-item {
      position: relative;
      padding-bottom: 25px;

      label {
        position: absolute;
        bottom: 0;
        left: -50%;
        width: 60px;
        max-width: none;
        margin: auto;
        color: #999;
        font-weight: 400;
      }

      a {
        display: block;
        width: 30px;
        height: 30px;
        padding: 3px;
        border-radius: 50%;
        border: 3px solid $wizard-border-color;
        background-color: $wizard-border-color;
      }

      &.active{
        a {
          border-color: #89c441;
          background-color: #fff;
        }
        label {
          color: #333;
          font-weight: 700;
        }
      }

      &.complete{
        a {
          border-color: #89c441;
          background-color: #89c441;
          &:hover{
            cursor: pointer;
          }
          &:after{
            content: '\f00c';
            font-family: 'Font Awesome 5 Free';
            color: #fff;
            font-size: 12px;
          }
        }
        .disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }


    .nav-line {
      align-self: flex-start;
      width: 100%;
      height: 3px;
      margin-top: 14px;
      background: $wizard-border-color;
      &.active{
        background-color: #89c441;
      }
      &.complete{
        background-color: #89c441;
      }
    }
  }


  .wizard-loading-spinner {
    display: flex;
    flex: 1 100%;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    font-size: 36pt;
    .icon {
      color: #86c535;
    }
    .text {
      h3 {
        color: #757575;
        font-weight: 100;
      }
    }
  }

  .ad-selection {
    flex-flow: column;
    .section{
      flex: auto;
      flex-grow: initial;
      flex-shrink: initial;
      .section-body {
        padding: 15px;
        &.ad-type-checklist {
          max-height: 320px;
          padding-left: 30px;
          .checkbox:first-of-type {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
    }
  }

  .image-selection {
    max-height: 512px;
    .image-list {
      display: flex;
      flex: 1;
      flex-flow: row wrap;
      padding: 10px;
      overflow-y: scroll;
    }
    .wizard-image {
      position: relative;
      width: calc((100% / 3) - 4%);
      margin: 2%;
      padding-top: calc((100% / 3) - 4%);
      border-radius: 6px;
      background-size: cover;
      background-color: $wizard-grey;
      background-position: center;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      max-height: 100px;
      cursor: pointer;
      &.selected {
        box-shadow: 0 0 3px 4px #97d24b;
      }
      .edit-btn {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 40px;
        height: 40px;
        padding-top: 5px;
        padding-left: 1px;
        color: white;
        font-size: 15pt;
        text-align: center;
        border-radius: 50%;
        background-color: #007bd8;
      }
    }
  }

  .image-editor {
    .cropper-target {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .image {
        max-height: 453px;
        max-width: 100%;
      }
      .placeholder-text {
        margin-bottom: auto;
        padding-top: 20px;
        font-size: 15pt;
        font-weight: 100;
      }
    }

    .toolbar {
      &.blank{
        border-color: #fff;
      }
      .controls {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-evenly;
        margin: auto;
        .fa, .fas, .fab, .far {
          cursor: pointer;
          font-size: 15pt;
          color: $wizard-lightgrey;
        }
      }
    }
  }

  .wizard-copy-editor,
  .wizard-review-editor {
    .copy-preview {
      .select-wrapper {
        max-width: 150px;
      }

      .preview {
        overflow: hidden;
        display: flex;
        flex: 1 100%;
        align-items: center;
        justify-content: center;
        margin: 10px;
      }

      .iframe-wrapper {
        display: flex;
        flex-flow: column;
        overflow: hidden;
        iframe {
          margin: auto;
          max-height: none!important;
        }
      }

      .MOBILE_FEED_STANDARD {
        width: 320px;
        height: 455px;
      }

      .DESKTOP_FEED_STANDARD {
        width: 502px;
        height: 493px
      }

      .RIGHT_COLUMN_STANDARD {
        width: 259px;
      }

      .INSTAGRAM_STANDARD {
        width: 320px;
        height: 525px;
        iframe {
          min-width: 335px;
        }
      }

      .INSTAGRAM_STORY {
        max-width: 320px;
        height: 567px;
        iframe {
          min-width: 335px;
        }
      }

      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        color: $wizard-lightgrey;
        span {
          font-size: 12pt;
          margin: 0 15px;
        }
        .fa, .fas, .fab, .far {
          font-size: 13pt;
        }
      }
    }

    .copy-form {
      flex-flow: column;
      .form-group{
        flex: 1;
        height: 0;
        margin: 0;
        padding: 10px;
      }
      input,
      textarea {
        margin-bottom: 5px;
      }
      .ad-text,
      .ad-description {
        resize: vertical;
      }
      .validation-area {
        .validation {
          display: block;
        }
      }
    }
  }

  .ad-review {
    .table-wrapper {
      overflow-x: hidden;
      position: relative;
      display: flex;
      flex-flow: column;
      width: 100%;
      border-radius: 6px;
      border: 1px solid $wizard-border-color;
    }
    .table-scroll {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1;
      max-height: 510px;
    }
    table.table.table-bordered {
      margin: 0;
      border: none;
      thead tr{
        height: 29px;
        span{
          position: absolute;
          top: 0;
          width: 100%;
          height: 30px;
          margin-left: -9px;
          padding: 5px;
          background: #fff;
          border: 1px solid $wizard-border-color;
          border-top: none;
        }
      }
      .col-image{
        width: 100px;
        div {
          display: block;
          width: 80px;
          height: 80px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .col-headline,
      .col-text,
      .col-description {
        min-width: 75px;
        max-width: 200px;
      }
      .col-text{
        min-width: 30px;
      }
      .col-type{
        min-width: 50px;
        max-width: 100px
      }
      .col-edit,
      .col-delete{
        width: 50px;
        a {
          display: block;
          margin: auto;
          color: $wizard-lightgrey;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
