@keyframes annotation-drawer-slide {
  0% {
    left: -80vw;
  }

  100% {
    left: 0;
  }
}

.submission.annotation-drawer {
  background-color: white;
  max-height: 100vh;
  overflow-y: scroll;
  width: 400px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  .requested-changes {
    text-align: center;
    font-size: 13pt;
    border-bottom: 1px solid lightgrey;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & > .annotation-row {
    &:first-child {
      border-top: 1px solid lightgrey;
    }

    border-bottom: 1px solid lightgrey;
  }
  
  @media(min-width: 1025px) {
    border-radius: 8px;
    height: 100%;
    margin: 24px;
  }

  @media(max-width: 1024px) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80vw;
    animation: annotation-drawer-slide .1s forwards;
    z-index: 11;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
}
