.community-logins.login-prompt {
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
  padding-left: 10px;
  padding-right: 10px;

  .login-card {
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    margin-left: auto;
    margin-right: auto;
    max-width: 550px;

    .community-logo {
      img {
        width: 100%;
        display: block;
      }
    }

    .community-title {
      text-align: center;
      font-size: 19pt;
      font-weight: 600;
    }

    .community-description {
      padding: 10px 20px;
    }

    .sign-up, .log-in {
      .buttons-title {
        text-align: center;
        border-top: 1px solid #ddd;
        font-size: 13pt;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        padding-top: 4px;
        margin-bottom: 10px;
        max-width: 250px;
      }

      .input-group {
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .failure-info {
      background-color: $--sc-red;
      color: white;
      border-radius: 20px;
      padding: 20px;
      margin-top: 16px;
    }
  }
}
