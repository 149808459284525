.instructions.read-only.instruction_card {
  border: 1px solid lightgrey;
  border-radius: 3px;
  margin: 20px 2px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  word-break: break-word;

  .card_title {
    padding: 10px 15px;
    border-bottom: 1px solid lightgray;
    font-size: 16px;
    font-weight: 600;
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
#print_to_file {
  #instructionsReadOnly {
    .ica p {
      overflow-y: visible;
      }
  }
}
