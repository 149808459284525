.shoppertunity_nav .shoppertunity_nav--mobile-container {
  z-index: 10;
  position: relative;
  display: flex;
  height: 50px;
  min-width: 100px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 2px 10px $dark-grey;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 600;
    color: $fun-blue;
    width: 100%;
    .tab {
      height: auto;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .count-bubble {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        // min-width: 18px;
        // min-height: 18px;
        padding: 3px 6px;
        margin-left: 4px;
        font-size: $--fs-x-small;
        color: $--sc-white;
        background-color: $--sc-blue;
        border-radius: $--br-default;
      }
    }
    i {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  .content{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    ul{
      z-index: 1;
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;
      height: auto;
      width: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      transition: opacity .25s ease-in-out;
      list-style: none;
      background: #fff;
      box-shadow: -1px 2px 10px $dark-grey;
    }

    li {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        font-weight: 600;
        color: lighten($grey-text, 30%);
        text-decoration: none;
        cursor: pointer;
        transition: background-color .25s ease-in-out, color .25s ease-in-out;
        position: relative;
        .count-bubble {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: auto;
          // min-width: 18px;
          // height: 18px;
          padding: 3px 6px;
          margin-left: 4px;
          font-size: $--fs-x-small;
          color: $--sc-white;
          background-color: $--sc-blue;
          border-radius: $--br-default;
        }

        i.fa, i.fas, i.fab, i.far {
          padding-left: 4px;
        }

        &:hover {
          color: $fun-blue;
          background-color: $gallery;
        }
        &.active {
          font-weight: 600;
          color: $fun-blue;
          background-color: $gallery;
        }
        &.has-error {
          color: $--sc-red;
        }
      }
      &:last-child a {
        padding-bottom: 15px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      height: 50px;
      font-weight: 600;
      padding: 0 15px 0;
      color: $grey-text;
      span{
        font-size: 18px;
      }
      i {
        margin-left: auto;
      }
    }
  }

  &.open{
    .content {
      .header {
        color: $fun-blue;
        i {
          transform: rotate(180deg);
        }
        &:hover {
          color: $fun-blue;
          background-color: white;
        }
      }
      ul {
        opacity: 1;
      }
    }
  }
}

