#ui_notifIt{
  position: fixed;
  top: 120px;
  right: -10px;
  padding: 10px;
  padding-right: 40px;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -wekbit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  z-index: 1000;

  &.success {
    background-color: $light-green;
    color: #000;
  }
  &.error , &.alert{
    background-color: $danger-red;
    color: white;
  }
  &.warning{
    background-color: $warning-yellow;
    color: white;
  }
  &.info{
    background-color: $light-blue;
    color: white;
  }
  &.notice{
    background-color: $light-green;
    color: white;
  }
  &.default{
    background-color: #EEE;
    color: #444;
  }
}

#ui_notifIt:hover{
  opacity: 1 !important;
}
#ui_notifIt {
  ul {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    padding-right: 30px;
    margin-bottom:0;
 }
 li {
    list-style-type: none;
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0px;
    }
 }
}

#ui_notifIt_close{
  position: absolute;
  right: 20px;
  top: 15%;
  font-size:20px;
  cursor:pointer;
  a{
    color:#fff;
  }
  a:hover{
    color:$light-grey;
  }
}
