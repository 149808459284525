.influencer-dashboard {
  .earnings-summary-card {
    color: $--tc-default;
    
    @media(min-width: $--bp-medium){
      width: 49%;
      &:first-child {
        margin-right: 1.5%;
      }
    }
    
    .top-row {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      .title {
        color: $--sc-gray;
        flex: 1;
        font-size: $--fs-x-large;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 4px;
      }
    }

    .mid-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 4px;
      .amount {
        flex: 1;
        font-size: $--fs-x-large;
        color: $--sc-green;
        font-weight: $--fw-bold;
        width: 100%;
        white-space: nowrap;
      }
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 4px;
      font-size: $--fs-small;
      .descript {
        flex: 1;
        width: 100%;
        white-space: nowrap;
        color: $--sc-sub-gray;
        border-radius: 20px;
        margin-left: 0;
      }
    }
  }
}