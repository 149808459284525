$sidebar-width: 18%;
$small-screen-sidebar-width: 28%;
$small-screen-content-width: 72%;
$content-right-width: 82%;
$campaigns-left-nav: $--sc-gray;
$campaigns-left-nav-active: $--sc-gray;
$campaigns-left-nav-color: $--fc-sub-gray;

.admin-left-sidebar,
.admin-content-right{
  position: relative;
  min-height: 1px;
  float: left;
}

.sidebar-left-content-right-wrapper{
  min-height: 650px;
  background-color: white;
  position: relative;
  @include clearfix();
  &:before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $campaigns-left-nav;
    width: $sidebar-width;
    z-index: 10;
    @media(max-width: $sidebar-breakpoint-small){
      width: $small-screen-sidebar-width;
    }
  }
  &.sidebar_collapsed .admin-left-sidebar,
  &.sidebar_collapsed:before,
  .sidebar_collapsed #campaign_navigation{
    width: 2%;
    min-width: 45px;
    transition: 0.25s ease width;
  }
  &.sidebar_collapsed #campaign_navigation{
    visibility: hidden;
  }
}

.admin-content-right{
  min-height: 650px;
  overflow: auto;
  width: $content-right-width;
  background: white;
  padding: 20px 30px;
  @media(max-width: $sidebar-breakpoint-small){
    width: $small-screen-content-width;
  }
  &.nopad{
    padding: 0;
    > .row{
      margin: 0;
    }
  }
}

.sidebar_collapsed .admin-content-right{
  width: 94%;
  transition: 0.25s ease width;
}

.sidebar_collapsed .admin-content-right{
  width: 94%;
  transition: 0.25s ease width;
}

.admin-left-sidebar {
  width: $sidebar-width;
  color: white;
  z-index: 1000;
  #campaign_navigation {
    ul {
      list-style: none;
      padding-left: 0px;
      small a{
        color: $--sc-white;
        background: #353535;
      }
    }
    li.list-header {
      &.active > a {
        background:$campaigns-left-nav-active;
        position: relative;
        font-weight: $normal;
        // active caret
        &:before{
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f0da";
          position: absolute;
          right: 20px;
          color: $campaigns-left-nav-active;
          font-size: 21px;
          position: absolute;
          right: -6px;
          top: 7px;
          z-index:1000;
        }
      }
    }

    a {
      display: block;
      padding: 5px 0 5px $small-screen-gutter;
      color: $campaigns-left-nav-color;
      font-weight: $light;
      &:hover{
        background: $campaigns-left-nav-active;
        i{
          color: $gallery;
        }
      }
      @media(min-width: $sidebar-breakpoint-large){
        padding-left: $large-screen-gutter;
      }
    }

    .shop-name > a {
      font-size: 13px;
      color: #A5A5A5;
      font-weight: $normal;
      i{
        font-size: 7px;
        margin-left: 5px;
        color: rgba(black, 0.7);
      }
      .shop-name-ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 88%;
        vertical-align: middle;
      }
    }

    .shop-name:not(.active) > a:hover{
      color: darken($gallery, 5%);
      i{
        transition: 0.2s cubic-bezier(0.4, 0, 1, 1) all;
        transform: translateX(-1px) scale(1.4);
        opacity: 1;
      }
    }

    ul.sub-nav li a{
      text-indent: 5px;
    }

    .shop-links.active.sub-nav{
      padding: 5px 0;
      background: darken($campaigns-left-nav-active, 5%);
      border-bottom: solid 2px rgba(black,.4);
    }

    .shop-links.active a{
      font-size: 12px;
      background: darken($campaigns-left-nav-active, 5%);
      padding-left: 50px;
      color: #a9a9a9;
      &:hover{
        background: black;
      }
    }

    .shop-name.active {
      & > a {
        background: $gallery;
        color: rgba(black, 0.6);
        font-weight: $normal;
        border-right: solid 1px rgba(black, 0.26);
      }
    }

    .shop-links > .active > a {
      background: black;
      li a{
        font-size: 12px;
      }
    }

    .shop-links {
      display:none;

      &.active {
        display:block;
      }
    }

  } // End #campaign_navigation
  @media(max-width: $sidebar-breakpoint-small){
    width: $small-screen-sidebar-width;
    z-index: 100;
  }
} // End .campaign-left-nav

.admin-left-sidebar--toggle{
  background: rgba(34, 34, 34, 0.55);
  opacity: 0.7;
  position: absolute;
  right: 1px;
  top: 0;
  color: #fff;
  font-size: 16px;
  outline: 0;
  padding: 0 10px;
  width: 45px;
  border-radius: 0;
  &:hover, &:active, &:focus{
    opacity: 1;
    background: #222;
    color: #fff;
  }
}

.admin-left-sidebar--toggle-icon{
  font-size: 21px;
}

.sidebar_collapsed .admin-left-sidebar--toggle-icon:before{
  content: "\f101";
}
