.campaign_requirement {
  .campaign_requirement_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.info-box {
  padding: 10px 15px;
}
