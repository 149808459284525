.influencer-dashboard.payment-list {
  & > .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .filter-options {
      width: 110px;
      text-align: left;
      position: relative;

      select {
        // Remove the awful default arrows on the select box
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .fas.fa-caret-down {
        position: absolute;
        right: 14px;
        top: 14px;
      }
    }
  }
}