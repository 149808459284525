.admin_data_points_body {

  pre {
    background-color: transparent;
    border: none;
  }

  .formula-toggle {
    cursor: pointer;
  }

}