/* Button styles
----------------------------------- */

.btn, .button, button {
  display: inline-block;
  font-family: inherit;
  background-color:  $--sc-blue;
  width: auto;
  padding: $--ls-small $--ls-regular;
  margin: $--ls-x-small $--ls-x-small;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $--sc-white;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  border-radius: 50px;
  border: 1px solid transparent;
  transition: all 300ms ease;
  .caret, i {
    margin: 0 5px;
  }
}
.btn.primary,
.button.primary,
.btn-primary, //LEGACY CLASS, DO NOT USE
button.primary {
  background-color: $--sc-blue;
  color: $--sc-white;
}
.btn.secondary,
.button.secondary,
.btn-default, //LEGACY CLASS, DO NOT USE
button.secondary {
  // background-color: transparent;
  background-color: $--sc-white;
  color: $--sc-blue;
  border: 1px solid $--fc-sub-gray;
}
.btn-info, //LEGACY CLASS, DO NOT USE
.btn.floating,
.button.floating,
button.floating {
  background-color: $--sc-white;
  color:  $--sc-gray;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn.floating:hover,
.button.floating:hover,
button.floating:hover,
.btn.floating:active,
.button.floating:active,
button.floating:active {
  background-color: $--fc-blue;
  color:  $--sc-blue;
}
.btn-danger, //LEGACY CLASS, DO NOT USE
.btn.danger,
.button.danger,
button.danger {
  background-color: $--sc-red;
  color: $--sc-white;
}

.btn-success, //LEGACY CLASS, DO NOT USE
.btn.success,
.button.success,
button.success {
  background-color: $--sc-green;
  color: $--sc-white;
}


.btn:hover, .button:hover,
.btn:active, .button:active,
.btn:focus, .button:focus{
  transition: all 300ms ease;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
}

.btn.disabled,
.button.disabled,
button.disabled,
.btn[disabled],
.button[disabled],
button[disabled] {
  background-color: $--fc-gray;
  color: $--sc-white;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.btn-outline,
.outline {
  background-color: transparent !important;
  border: 1px solid $--sc-blue;
  color: $--sc-blue !important;
}

.outline:hover,
.btn-outline:hover {
  color: $--sc-white !important;
}

.btn-outline.primary,
.outline.primary,
.btn-primary.outline {
  border: 1px solid $--sc-blue;
}

.btn-outline.primary:hover,
.outline.primary:hover,
.btn-primary.outline:hover {
  background-color: $--sc-blue !important;
}

.btn-outline.secondary,
.outline.secondary,
.btn-secondary.outline {
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.btn-outline.secondary:hover,
.outline.secondary:hover,
.btn-secondary.outline:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.btn-outline.success,
.outline.success,
.btn-success.outline {
  border: 1px solid $--sc-green;
  color: $--sc-green !important;
}

.btn-outline.success:hover,
.outline.success:hover,
.btn-success.outline:hover {
  background-color: $--sc-green !important;
  color: $--sc-white !important;
}

.btn-outline.danger,
.outline.danger,
.btn-danger.outline {
  border: 1px solid $--sc-red;
  color: $--sc-red !important;
}

.btn-outline.danger:hover,
.outline.danger:hover,
.btn-danger.outline:hover {
  background-color: $--sc-red !important;
  color: $--sc-white !important;
}

.btn.no-action,
.button.no-action,
button.no-action {
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    cursor: default;
  }
}

.btn-outline,
.outline {
  background-color: transparent !important;
  border: 1px solid $--sc-blue;
  color: $--sc-blue !important;
}

.outline:hover,
.btn-outline:hover {
  color: $--sc-white !important;
}

.btn-outline.primary,
.outline.primary,
.btn-primary.outline {
  border: 1px solid $--sc-blue;
}

.btn-outline.primary:hover,
.outline.primary:hover,
.btn-primary.outline:hover {
  background-color: $--sc-blue !important;
}

.btn-outline.secondary,
.outline.secondary,
.btn-secondary.outline {
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.btn-outline.secondary:hover,
.outline.secondary:hover,
.btn-secondary.outline:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.btn-outline.success,
.outline.success,
.btn-success.outline {
  border: 1px solid $--sc-green;
  color: $--sc-green !important;
}

.btn-outline.success:hover,
.outline.success:hover,
.btn-success.outline:hover {
  background-color: $--sc-green !important;
  color: $--sc-white !important;
}

.btn-outline.danger,
.outline.danger,
.btn-danger.outline {
  border: 1px solid $--sc-red;
  color: $--sc-red !important;
}

.btn-outline.danger:hover,
.outline.danger:hover,
.btn-danger.outline:hover {
  background-color: $--sc-red !important;
  color: $--sc-white !important;
}



// OLD

// .btn {
//   & + &{
//     margin-left: 10px;
//   }
// }

// .btn-group.open {
//   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
// }

// .btn-primary, .btn-info{
//   text-shadow: $global-text-shadow;
// }

// .btn-primary.disabled{
//   color: #eee;
// }

// .btn.btn-default,
// .btn-default{
//   &:hover, &:focus, &:active, &.active{
//     background-color: $default-btn-hover-bg;
//     border-color: $default-btn-hover-border;
//   }
// }

// .btn.btn-bgr{
//   background: #555;
//   color: white;
//   border: none;
//   font-size: 18px;
//   min-width: 100px;
//   letter-spacing: 1px;
//   text-transform: lowercase;
//   height: 40px;
//   padding-bottom: 7px;
//   line-height: 1;
//   &:hover{
//     background: #222;
//   }
//   &.btn-primary{
//     background: $btn-primary-bg;
//     &:hover{
//       background: darken($btn-primary-bg, 10%);
//     }
//   }
// }

// .btn.btn-cancel{
//   color: $grey-text;
//   text-transform: lowercase;
//   letter-spacing: 1px;
//   height: 40px;
// }

// .btn.btn-md{
//   padding: 3px 6px;
//   font-size: 13px;
// }

// .btn-close-x{
//   border: 0;
//   background: transparent;
//   color: $red;
//   font-size: 30px;
//   padding: 0;
//   line-height: 1;
//   &:hover, &:active, &:focus{
//     color: darken($red, 5%);
//     outline: 0;
//   }
// }


// .btn{
//   text-transform: capitalize;
//   &:focus{
//     box-shadow: none;
//   }
//   &.grey_link{
//     color: $grey-text;
//   }
// }

// .btn--icon-left, .btn--angle-icon-right{
//   opacity: 0.65;
//   text-shadow: none;
// }

// .btn--angle-icon-right{
//   margin-left: 10px;
// }

// .btn--icon-left{
//   margin-right: 3px;
// }

// .btn-primary{
//   position: relative;
//   z-index:2;
// }

// .btn-success{
//   background-color: $light-green;
//   border: 1px solid $light-green;
//   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
//   &:hover, &:focus{
//     background: $light-green;
//     border: 1px solid $light-green;
//   }
// }

// .btn-info{
//   position: relative;
//   border:none;
// }

// .btn-xs:before {
// content: '';
// border: none;
// }
