.promoter-ad-metrics-uploader{
  .card-body{
    .ad-upload-inputs{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: wrap;
      .form-group, .input_wrap{
        margin: 0 15px 0 0;
      }

      .button-group{
        margin-left: auto;
      }
    }
  }
}