.requirements_container {

  .placeholder-card {
    border-radius: 3px;
    margin: 20px 2px;
    background: rgba(209, 236, 241, 0.15);
    border: 4px dashed #D1ECF1;
    height: 345px;
    
    &.instruction-note {
      height: 47.5px;
    }
  }

  .requirement_card_wrapper{
    display: flex;
    flex-flow: column;
    border: 1px solid #dedede;
    border-radius: 3px;
    margin: 20px 2px;
    box-shadow: -5px 5px 10px #ebebeb,
                5px -5px 10px #ffffff;
    background: #fff;

    &.draggable {
      cursor: grab;
    }

    &.hide-dragging {
      display: none;
    }

    &.dragged-over {
      * {
        pointer-events: none;
      }
    }

    .card_header{
      padding: 5px 10px;
      border-bottom: 1px solid #dedede;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      background: #f6f6f6;
      .validation_section {
        padding-right: 4px;
      }
      .requirement_card_label {
        flex-grow: 1;
      }
      .requirement_card_icons{
        min-width: 105px;
        margin: 0 0 0 auto;
        padding: 5px 0 0;
        height: 30px;
        .far{
          margin: 0px 10px;
          cursor: pointer;
          &.fa-check-square{
            color: $dark-green;
          }
          &.disabled {
            background-color: #eeeeee;
            border-color: #ccc;
            color: #5c5c5c;
            cursor: not-allowed;
            &:active {
              pointer-events: none;
            }
          }
          &:hover:not([disabled]) {
            &.fa-edit{
              color: $denim;
            }
            &.fa-copy{
              color: #000;
            }
            &.fa-trash{
              color: #d9534f;
            }
          }

        }
        .fas {
          margin: 0px 10px;
          cursor: pointer;

          &.disabled {
            background-color: #eeeeee;
            border-color: #ccc;
            color: #5c5c5c;
            cursor: not-allowed;
            &:active {
              pointer-events: none;
            }
          }
          &:hover:not([disabled]) {
            &.fa-trash{
              color: #d9534f;
            }
          }
        }
      }

      .requirement_card_icons_order {
        min-width: 105px;
        margin: 0 0 0 auto;
        padding: 5px 0 0;
        height: 30px;
      }
      .fas.fa-bars {
        margin: 0px 10px;
        pointer-events: none;
      }
    }
    
    .internal-notes-description,
    .description_area{
      .description--view{
        padding: 20px;
        border-bottom: 1px solid #dedede;
        p {
          margin: 0;
        }
      }
    }
    .internal-notes-description,
    .general-requirements-description {
      .redactor-box{
        margin-top: 0;
        .redactor-toolbar{
          border-top: 0;
        }
      }
    }
    .redactor-box{
      margin-bottom: 0;
      border-radius: 0;
      .redactor-toolbar{
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
      .redactor-editor {
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-radius: 0;
      }
    }
    .requirements_container {
      display: flex;
      flex-flow: column;
      margin: 0;
      .requirements_container_title {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.requirement_btn_footer {
  display: flex;
  justify-content: center;

  .add_requirement_btn {
    color: #177bd0;
    margin: 10px 4px;
    border-color: #177bd0;
    i {
      margin: 0 5px 0 0;

      &.fa-caret-down {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }
}
