.influencer-dashboard.task-list {
  & > .filter-bar {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .filter-options {
      width: 110px;
      text-align: left;
      position: relative;

      select {
        // Remove the awful default arrows on the select box
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .fas.fa-caret-down {
        position: absolute;
        right: 14px;
        top: 14px;
      }
    }
  }
}

.influencer-dashboard.task-card {
  color: $--tc-default;

  &.complete{
    background-color: $success-green-background;
    border: 1px solid $success-green-border;
    .status{
      color: $success-green;
    }
  }
  &.issues{
    background-color: $danger-red-background;
    border: 1px solid $danger-red-border;
    .status{
      color: $danger-red;
    }
  }

  .task-row {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;

    .task {
      flex: 1;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
      &.title {
        font-weight: $--fw-bold;
      }
      &.due_date {
        font-style: italic;
      }
    }
    &.bottom {
      justify-content: space-between;
      align-items: center;
      margin-right: 4px;
      width: 70%;
    }
  }
}
