.carousel-container-component {
  position: relative;
  margin: 0;

  &.desktop-arrows {
    padding: 0;

    .desktop-arrow {
      display: none;
    }
  }

  @media screen and (min-width: $--bp-medium) {
    &.desktop-arrows {
      padding: 0px 24px;

      .desktop-arrow {
        display: inline;
        font-size: 20pt;
        position: absolute;
        top: calc(50% - 20pt);
        cursor: pointer;
        color: $--sc-gray;

        &.fa-chevron-left {
          left: 0;
        }
        &.fa-chevron-right {
          right: 0;
        }
      }
    }
  }

  .carousel {
    scrollbar-color: transparent transparent;
    scrollbar-width: 0px;
    -ms-overflow-style: none;

    &:hover {
      cursor: default;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border: none;
    }

    &.indicators {
      &:not(.no-expand) {
        @media screen and (min-width: $--bp-small) {
          display: none;
        }
      }
      
      // Using a mixin so that we can share the same layout b/t .no-expand and the size breakpoint
      @mixin carousel-dot-alignment {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 40%;
      }

      @media screen and (max-width: $--bp-medium) {
        @include carousel-dot-alignment;
      }

      &.no-expand {
        @include carousel-dot-alignment;
      }

      .dot {
        flex: 1;
        background-color: $--fc-gray;
        border: 1px solid $--fc-gray;
        border-radius: 5px;
        display: inline-block;
        height: 10px;
        width: 24px;
        max-width: 24px;
        margin-right: 4px;
        &.active {
          background-color: $--sc-gray;
          box-shadow: inset 2px 0px 2px -2px $--fc-gray;
        }
      }
    }
  }
}
