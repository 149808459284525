.shoppertunity_nav .shoppertunity_nav--container {
  display: flex;
  justify-content: center;
  margin: auto;
  font-size: 15px;
  max-width: 1000px;
  overflow: auto;
  white-space: nowrap;
  .shoppertunity_nav--link{
    color: lighten($grey-text, 30%);
    margin: 0 20px;
    padding: 15px 0px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
      color: $fun-blue;
      font-weight: bold;
      border-bottom: 3px solid $fun-blue;
    }
    .count-bubble {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: auto;
      // min-width: 18px;
      // height: 18px;
      padding: 3px 6px;
      margin-left: 4px;
      font-size: $--fs-x-small;
      color: $--sc-white;
      background-color: $--sc-blue;
      border-radius: $--br-default;
    }

    i.fa, i.fas, i.fab, i.far {
      padding-left: 4px;
    }

    &:hover{
      color: $fun-blue;
    }

    &.has-error {
      color: $--sc-red;
    }
  }
}

