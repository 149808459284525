/**
 * Example styling for tokenizer plugin.
 * Prefix may be overriden with 'namespace' option.
 * Don McCurdy, 2013.
 */

.tknz-wrapper { /* The apparent input */
/*     width: 350px;
     height: 250px;
     margin: 50px auto;
     padding: 10px;
     overflow: auto;
     color: #fefefe;
     background: #fefefe;
     font-family: "Courier", Times, sans-serif;
     border: solid 2px #DFDFDF;
     border-radius:10px;*/
}

.tknz-focus{ /* Apparent input in active state */
/*    box-shadow: 0px 0px 6px #5c7db7;
    border: solid 2px transparent;*/
}

.tknz-list{
/*  position: absolute;
  margin-top: 40px;*/
  padding-bottom: 20px
}


.tknz-wrapper-label { /* Label for the field */
    float: left;
    color: #000;
    margin: 5px 5px 0 2px;
}

.tknz-input { /* The actual input */
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    border: none;
    outline: none;
    background: #fefefe;
    border: 1px solid $blue-grey;
}

.tknz-input-wrapper {
/*    float: left;
    position: relative;
    top: 4px;*/
}

.tknz-token { /* An individual token */
    background: $gallery;
    padding: 2px 7px;
    color: $dusty-grey;
    margin: 2px;
    float: left;
    border-radius: 4px;
    max-width: 340px;
    cursor: pointer;
}

.tknz-token-label { /* Token's label */
    word-break: break-word;
}

.tknz-token-x { /* Delete button for token */
    color: $dusty-grey;
    padding-left: 7px;
    position: relative;
    top: 1px;
}
.tknz-token-x:hover {
    text-shadow: 0px 0px 3px #eee;
    color: $light-black;
    font-weight: bold;
}

.tknz-suggest { /* Autosuggest modal */
    position: absolute;
    top: 27px;
    left: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(120,120,120, 0.3);
}

.tknz-suggest ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: left;
}

.tknz-suggest-li {
   color: #000;
   padding: 0px 5px;
}

.tknz-suggest-li:nth-child(2n) {
    background-color: #f0f0f0;
}

.tknz-suggest-li.tknz-sel {
    color: #fff;
    background-color: #5c7db7;
}

/** MEDIA **/

@media screen and (max-width: 480px) {
	.tknz-input {
		width: 90%;
	}
}
