label {
  cursor: pointer ;
}

span {
  font-family: "Lato", sans-serif ;
  font-style: normal  ;
  font-weight: normal ;
  font-size: 14px ;
  line-height: 17px ;
}

.additional-guardrails {
  input[type="checkbox"] {  
    bottom: 1px; 
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 4px 22px 18px -19px;
    vertical-align: bottom;
    position: relative;
    top: -1px;
  } 
}

.radio label{
  padding: 0 2.5rem 0 48px;
  font-weight: 400;
  display: inline-flex;
  text-indent: -15px;
}

// GuardCaption CSS

.tag-input-container {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .3em;
}

.tag-item {
  display: inline-block;
  padding: .5em .75em;
  color: white;
  border-radius: 5px;
}

.tag-item-success {
  background-color: $--sc-green;
}

.tag-item-danger {
  background-color: $--sc-red;
}

.tag-item .tag-close {
  height: 20px;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  cursor: pointer;
}

.tag-input {
  flex-grow: 1;
  padding-left: 2px;
  border: none;
  outline: none;
}