.page_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 25px 0;
  .display_description {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    max-width: 800px;
    color: #5C5C5C;
  }

  .display_icon {
    position: relative;
    display: block;
    width: 100%;
    max-width: 405px;
    height: 225px;
    margin: 0 0 50px;
    .icon_layer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 25px;
      width: 75%;
      height: 65%;
      border-radius: 25px;
      border: 1px solid #f0f0f0;
      &.layer_1 {
        background-color: #ececec;
        float: right;
      }
      &.layer_2 {
        position: absolute;
        background-color: #fff;
        top: 75px;
        left: 0;
        box-shadow: 0px 5px 25px -5px rgba(49, 49, 49, 0.3);
      }
      .internal_layer {
        display: block;
        height: 12px;
        border-radius: 25px;
        margin: 15px 0;
        &.layer_1 {
          width: 50%;
          background: #b4b4b4
        }
        &.layer_2 {
          width: 90%;
          background: #e1e1e1;
        }
      }
    }
  }
}
