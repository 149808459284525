.admin_member_applications_body {

  // applicant show page
  .member_application_status_form{
    .profile-label{
      text-align: right;
      color: $dark-grey;
    }
    .radio-inline, .checkbox{
      padding-top: 0;
    }
    .radios{
      font-size: 1.25em;
    }
    .disabled{
      cursor: not-allowed
    }
    #tier-select span{
      display: block;
    }
  }
}

#admin_member_apps  {
  .ma_description, .ma_reason_for_joining{
    min-width: 250px;
  }
}
