.users-delete-account, .users-delete-account:hover {
  .form-lr {
    margin-bottom: 0;

    label {
      cursor: pointer;
    }
  }

  color: $--tc-default;
}
