.round-tasks.selection-row {
  &:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  display: flex;
  align-items: center;
  cursor: pointer;

  .left {
    padding: 4px 8px;
    font-size: 13pt;

    .fas.fa-check-circle {
      color: $light-green;
    }
  }

  .right {
    flex: 1;
    font-size: 10pt;
    padding: 4px;
  }
}
