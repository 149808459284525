.round-tasks.template-legend {
  margin: 4px;
  
  .legend-title {
    margin-left: 5%; // This keeps the title aligned with the table header/descriptions above
    padding: 4px 8px 8px 0px;
    font-weight: 600;
  }

  .legend-body {
    display: flex;
    flex-wrap: wrap;
  }
}
