.requirement_checklist--container{
  max-width: 1000px;
  min-width: 300px;
  margin: 15px auto;
  color: $grey-text;
  padding: 0 15px;
  .requirement_checklist--no-requirements{
    text-align: center;
  }
  .requirement_checklist--list{
    .requirement_checklist--approval-label{
      text-align: right;
    }
    .requirement_row{
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;
      .requirement_row--info{
        border: 1px solid $light-grey;
        flex-grow: 3;
        font-size: 12px;
        padding: 10px 15px;
        margin-right: 10px;
        border-radius: 6px;
        border: none;
        background: #eee;
        .requirement_row--description{
          font-size: 14px;
          font-weight: 600;
          margin: 5px 0;
        }
        .requirement_row--humanized{
          padding: 5px;
          span {
            color: #fff;
            background: #343a40;
            border-radius: 4px;
            padding: 2.5px 5px;
            font-weight: 600;
            display: inline-flex;
            line-height: 20px;
            justify-content: center;
            align-items: center;
            margin: 2px;
          }
        }
        &.passed {
          background-color: #d4edda;
          .requirement_row--humanized {
            span {
              background-color: #155724;
            }
          }
        }
        &.failed {
          background-color: #f8d7da;
          .requirement_row--humanized {
            span {
              background: #721c24;
            }
          }
        }
      }
      .requirement_row--approval{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        color: $dusty-grey;
        &.passed{
          .fa-check-circle{
            color: $success-green;
          }
        }
        &.failed{
          .fa-times-circle{
            color: $danger-red;
          }
        }
        i{
          margin: 0 10px;
          &.fa-check-circle-o:hover{
            color: $success-green;
          }
          &.fa-times-circle-o:hover{
            color: $danger-red;
          }
        }
      }
    }
  }
  .requirement_checklist--actions{
    text-align: center;
    .influencer-feedback--label{
      text-align: left;
      width: 100%;
      .influencer-feedback{
        margin: 10px 0px;
        width: 100%;
        font-weight: normal;
      }
    }
    .btn:disabled {
      pointer-events: none;
    }
    .approve-btn{
      background-color: $success-green;
    }
    .reject-btn{
      background-color: $danger-red;
    }
  }

}
.validation-history--section{
  .validation-history--table {
    display: flex;
    flex-direction: column;

    .table-header {
      margin: 0px;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 15px 30px;
      .row{
        min-height: 30px;
      }
    }
    .row {
      display: flex;
      padding: 0px 15px;

      .cell {
        flex: 2;
        &.comment-column{
          flex: 5;
        };
      }
    }
    .validation-history--row {
      border: 1px solid $light-grey;
      border-radius: 6px;
      padding: 15px;
      margin-top: 10px;
      &:hover{
        box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
      }
    }
  }
}
.admin-manual-instagram-preview{
  .analytics-screenshot{
    width: 100%;
    max-width: 100%;
  }
  .submission-information{
    max-width: 655px;
    margin: auto;
  }
  .view-count-input{
    padding-top: 15px;
    .tooltip-icon-wrapper{
      display: inline-block;
      position: relative;
    }
    i{
      padding: 0px 5px;
    }
  }
}

.manual-metrics{
  .manual-metrics-instructions{
    text-align: center;
    p{
      max-width: 500px;
      min-width: 300px;
      margin: 8px auto;
    }
  }
  .manual-metrics-btn{
    margin: 8px auto;
    text-align: center;
  }
  .alert-component{
    max-width: 500px;
  }
}
