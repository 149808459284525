$landingpage-tabs-height: 473px;
$landingpage-content-padding: 30px;
$landingpage-shadow: 6px 6px 0px rgba(0,0,0,0.20);
$landingpage-header: 124px;
$landingpage-footer: 227px;
$brighter-link-color: $--sc-blue;
$blogger-shop-nav-grey: lighten($--sc-sub-gray, 5%);

// Alerts

.alert_holder{
  @include clearfix();
}

// end Alerts

// Quick fix for blogger shop pages
#shop-page-title {
  margin-top:20px;
}

.btn-shop-contact {
  margin-top:20px;

  @media screen and (min-width:665px) {
    float:right;
    margin-top:0;
  }
}

#blogger-shop-navigation {
  margin-top:20px;

  @media screen and (min-width:655px) {
    text-align:center;
  }
}

.social_fabrics_body,
.shoppertunities_body,
.shop_applications_body {

  #shop_main_navigation{
    padding-left:$landingpage-content-padding;
    padding-right:$landingpage-content-padding;
    text-align:center;
  }

  #shop_main_navigation {
    .nav > li > a:hover,
    .nav > li > a:focus,
    .nav > li.dropdown.open > a{
    }
    .nav .dropdown {
      .dropdown-menu > li {
        margin-bottom: 0;
        a {
          color: $grey-text;
          padding: 9px 0;
          text-shadow: none;
          background: transparent !important;
        }
      }
    }
    span.caret {
      position:absolute;
      bottom:-5px;
      color:$brand-primary;
      left: 44.48%;
    }
    .active {
      background:$brand-primary;
    }
  }

  .toggle-layout-button {
    // keep from highlighting when clicked
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -user-select: none;
    padding: 0;
    color: #fff;
    background: transparent;
    border: none;
    &:first-child{
      margin-right: 12px;
    }
    i{
      opacity: .2;
      font-size: 18px;
      vertical-align: middle;
    }
    &:focus, &:active{
      outline: 0;
    }
    &.list-layout-is-active .list-layout-icon,
    &.card-layout-is-active .card-layout-icon {
      opacity: 1;
    }
    @media (max-width: 850px){
      display: none;
    }
  }

  .sofab-tabs--btn.hidden--tab{
    padding-left: 25px;
    &:after{
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f1f8";
      opacity: 0.45;
      position: absolute;
      left: 10px;
      top: 9px;
    }
  }

  .edit-shop-app {
    margin-top: 20px;
  }

  ul.nav.nav-pills{

    display:inline-block;
    margin:0;
    padding:0;

    li{
      margin-bottom:3px;
      :hover {
        background:$dusty-grey;
      }
      &:last-child {
        margin-left:0;

        @media screen and (min-width:477px) {
          margin-left:2px;
        }
      }
    }

    li a{
      background:$blogger-shop-nav-grey;
      border-radius:0;
      color:#000000;
      padding: 4px 10px;
      position:relative;
    }

    li.active a{
      background-color:$dark-grey;
      color:#FFFFFF;
    }

  }
  .tab-content{

    ul.nav.nav-pills{
      display:inline-block;

      li a{
        color:$grey-text;
      }

      li.active a{
        color:#000000;
        background-color:transparent;
      }

    }
  }
}
.shoppertunities_body{
  .modal-body{
    padding:20px;
  }
  #shoppertunity_survey_modal{
    .modal-header h3{
      margin: 0
    }
    .modal-body{
      padding: 0
    }
  }
}

// Quick fixes for shop_title before release
.full-width-page-title--heading .modal{
  color: #000;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
}

.full-width-page-title--heading small{
  line-height: 1.2;
}

.full-width-page-title--heading a i{
  color: $dusty-grey;
}

.shops-tabs--col{
  @media (max-width: $screen-md-min){
    text-align:left;
    margin-top: 10px;
  }
}

.toggle-layout-button-group {
  line-height: 34px;
   @media (max-width: $screen-md-min){
    margin-top: 10px;
  }
}

/* Admin Shop Pages */
.admin_shoppertunities_body,
.admin_round_tasks_body,
.admin_shop_applications_body,
.admin_instructions_body,
.admin_shop_bloggers_body,
.admin_shop_posting_body,
.admin_questions_body,
.admin_rounds_body,
.admin_round_bloggers_body{

  #definition-tab {
    li.round_task {
      list-style: none;
      min-height: 40px;
    }
    .shop-desc-title{
      padding-bottom: 10px;
      border-bottom: solid 1px $light-grey;
    }
  }

  .questions-list {
    list-style-type: decimal;
    li.question {
      font-size: 1.5em;
      line-height: 20px;
      padding: 1em 0;
      border-bottom: 2px solid #fff;
      &:last-child {
        border-bottom: none;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .shop-desc{
    clear:both
  }

  #shoppertunity-image-preview {
    max-width: 245px;
  }

  .verified-shop {
    color: $dark-green;
  }

}

.admin_shoppertunities_body{
  .input-error {
    background: rgba(red, .08) !important;
  }

  .remove-field-link{
    line-height: 34px;
  }

  .add-client {
    line-height: 31px;
  }
  .new-task{
    display:inline-block;
    margin-bottom:15px;
  }

  .tt-menu {
    max-height:350px;
    overflow-y:auto;
  }

  .proximity-filter-area {
    margin-bottom: 15px;
  }

  .importing-msg {
    line-height: 34px;
    vertical-align: center;
    text-align: middle;
    color: $danger-red;
  }
}

.admin_shoppertunities_body .tknz-list,
.proximity-postal-codes{
  max-height: 125px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  padding: 3px;
  width: 97%;
  float: none;
}

.admin_shoppertunities_body .tknz-list{
  margin: auto;
}

.proximity-postal-codes{
  margin: 5px 0 0 0;
}

.round .col-sm-12 .round_task{
  float:left;
  margin: 8px 30px 8px 0;
}

.tab-content section{
  min-height:100px;
}

#note-toggle, #audit-toggle {
  cursor: pointer;
}

h2.note-toggle {
  margin: 0 0 20px;
}

#notes-body{

  .datefields {
    width: auto;
    display: inline-block;
    .form-control {
      width: auto;
    }

    .input-sm {
      line-height: 18px;
    }

  }

  .control-label, .note-label{
    width:10%;
  }

  .note-time-stamp{
    width:100%;
    text-align:right;
    display:block;
    line-height:15px;
    margin-left:-3px;
  }
}

.shop_row{
  margin-bottom:20px;
}

.edit_shoppertunity{

  .list-group{
    margin-bottom:35px;
  }

  a.pull-right.btn{
    margin:5px 0;
  }

}

.remove_task{
  margin: 5px 0;
}

#task_container{
  .list-group{
    margin-bottom:35px;
  }
}

.task-title{
  margin-top:0;
}

.task-article{
  margin-bottom:15px;
}

.shops-container{
  //background-color:$form-grey;
  padding: 0 0 15px;
  margin: 0 0 20px;

  .applied-shop-cards, .in-review-shops, .not-selected-shops {
    background-color: white;
  }

  .cards {
    margin: 15px 0;
    background-color:$form-grey;
  }

}
.shop-app-ribbon{
  position:absolute;
  top:5%;
  width:100%;
  height:auto;
  color:#FFFFFF;
  text-align:center;
  padding: 8px 0;
}

.ribbon-approved{
  border-top: 3px solid $light-green;
  border-bottom: 3px solid $light-green;
  background: $light-green; //IE
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, $light-green 10%, $light-green 90%, rgba(0,0,0,0) 90%, rgba(0,0,0,0) 100%), rgba(0,0,0,.35);
}

.ribbon-pending{
  border-top: 3px solid $light-blue;
  border-bottom: 3px solid $light-blue;
  background: $light-blue; //IE
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, $light-blue 10%, $light-blue 90%, rgba(0,0,0,0) 90%, rgba(0,0,0,0) 100%), rgba(0,0,0,.35);
}

.ribbon-denied{
  border-top: 3px solid $danger-red;
  border-bottom: 3px solid $danger-red;
  background: $danger-red; //IE
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, $danger-red 10%, $danger-red 90%, rgba(0,0,0,0) 90%, rgba(0,0,0,0) 100%), rgba(0,0,0,.35);
}

#error-area {
  padding-top: 10px;
  display: none;
}

#withdraw_alert, #resend_alert {
  display: none;
}

.soc-col{
  text-align:center;
}

#instructions-tab {
  .btn-group {
    li.active {
      .fa-ban {
          color: white;
          font-weight: bold;
        }
        .fa-check-circle {
          color: white;
        }
        .fa-question-circle {
          color: white;
        }
    }
    .fa-ban {
      color: $danger-red;
      font-weight: bold;
    }
    .fa-check-circle {
      color: $light-green;
    }
    .fa-question-circle {
      color: $warning-yellow;
    }
    &.open {
      .btn {
        color: black;
      }
    }
  }
  .instructions-wrapper {
    margin-top: 20px;
  }
}

.instructions-body{
  .old-instruction{
    h1,h2,h3,h4,h5 {
      font-weight: bold
    }
  }
}

.instruction-input{
  font-size: 14px;
  font-weight: 400;
  font-family: $font-family-sans-serif !important;
  color: #333333;
  padding-left: 20px;
}

.instruction-update-notes{
  color: #31708f;
  .review-notice{
    display: block;
    font-weight: bold;
  }
  .notes-updated-at{
    font-weight: bold;
  }
}

// Shop Influencers tab
#results-tab {
  #pending-influencers, #active-influencers {
    table {
      width: 100%;
      tr {
        height: 30px;
      }
      th {
        padding: 0 15px;
      }
      td {
        padding: 0 15px;
      }
    }
    .state-label {
      display: inline-block;
      width: auto;
      padding: 2px 12px;
      text-align: center;
      color: $--sc-white;
      border-radius: $--br-default;
      &.pending {
        background-color: $warning-yellow;
      }
      &.instructions_sent {
        background-color: $holly;
      }
      &.active {
        background-color: $light-green;
      }
      &.declined {
        background-color: $danger-red;
      }
    }

    .rb-action,
    .rb-action-reactivate {
      width: 120px;
    }
  }
  @media screen and (max-width:1000px){
    .influencer-btns{
      margin-bottom:20px;
  }
  }
}

.results-detail {
  table {
    width: 100%;
    tr {
      height: 30px;
    }
  }
}
.reach-container .row{
  background-color:#FFFFFF;
  border: 1px solid $form-grey;
  margin-left:0;
  margin-right:0;
}
.app-collapsible{
  margin-bottom:15px;
  .panel-heading{
    background-color:$light-blue;
    color:#FFFFFF;
    a{
      color:#FFFFFF;
    }
  }
  .panel-default{
    margin-top:20px;
  }
}

.applied-shops{
  width:100%;
  background-color:#FFFFFF;
}

.additional-note{
  .btn{
    display:block;
    margin: 5px auto 5px auto;
  }
}
.display-note{
  background-color:#FFFFFF;
  padding:5px;
}

table.dataTable.admin_shops{
  thead > tr > th{
    &.campaign{
      width:17% !important;
    }
    &.mission{
      width:45% !important;
    }
    &.due_date{
      width:9% !important;
    }
      &.state{
      width:10% !important;
    }
  }
}

#show_more{
  text-align: center;
  margin: 40px 0 20px;

  i{
      font-size:40px;
      display: none;
      color: $true-blue;
  }
}

#shops_loading{
  text-align: center;
  margin-top: 20px;
  font-size: large;
}

.no-shops-alert{
  margin-top: 15px;
}

// Card view
article.shop-card {
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background: none repeat scroll 0% 0% rgb(255, 255, 255);
  margin: 10px 0;
  padding: 0;
  position: relative;
  overflow-y: hidden;
  &:hover {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.6);
    .shop-hero {
      height: 10px !important;
      transition: height .5s ease-in-out;
    }
    .shop-preview {
      display: block;
    }
  }

  .shop-image {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .shop-preview{
    text-align: center;
    font-weight: 300;
    font-size: .9em;
    overflow-y: hidden;
    padding: 15px 15px;
    display: none;
    border-top: 1px solid $light-green;
    p {
      margin: 0;
    }
  }
  .shop-card-body {
    border-top: 1px solid $light-green;
    border-bottom: 1px solid $light-green;
    .shop-title {
      margin-top: 0;
      font-size: 1.2em;
      font-weight: 300;
      padding: 15px 10px;
    }
  }
  .shop-details {
    padding: 10px;
    font-size: 0.8em;
    color: $grey-text;
    position: relative;
    bottom: 0;
    width: 100%;
    background: white;
    i {
      margin-right: 3px;
    }
    .shop-payments {
      margin-left: 2px;
      i {
        margin-right: 4px;
      }
    }
    .shop-apply {
      position: absolute;
      right: 5px;
      top: 15%;
      font-size: 3em;
      a {
        color: $dark-purple;
        &:hover {
          color: darken($dark-purple, 5%)
        }
      }
    }
  }
  .shop-hero {
    position: relative;
    overflow-y: hidden;
    transition: height .2s ease-in-out;
  }
}
.shop-card-key {
  width: 110px;
  margin-top: -15px;
  table {
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
  }
  td {
    padding: 2px 1px;
    font-size: 0.8em;
    color: $grey-text;
  }
}

#accordion {
  a {
    text-decoration: none;
    .panel-heading {
      background-color: #d9edf7 !important;
    }
  }
}
#collapse_import {
  max-height: 300px;
  overflow: auto;
  a {
    text-decoration: none;
    color: inherit;
  }
}

.tknz-wrapper {
  height: initial !important;
  margin: auto !important;
  border-radius: initial !important;
}

.tknz-wrapper-label {
  display: none !important;
}

//Shoppertunities Edit

.admin_shoppertunities_body {

  .round-planner{
    margin-bottom:20px;
  }

  .task-btns {
    line-height: 34px;
    width: 35px;
  }

  .center-vertically {
    line-height: 34px;
  }

  .round-row {
    border-radius: 4px;
    margin-bottom: 16px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

    &:first-of-type{
      margin-top: 15px;
    }
    .row{
      margin: 0;

      &:first-of-type {
        border-radius: 4px 4px 0 0;

        &.heading {
          padding: 8px 4px 8px 4px;
          background-color: $lightgrey;
          text-align: right;
        }
      }

      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }
    }
    .task-row:last-of-type{
      padding-bottom: 15px;
    }
  }
}

.round-planner--top-info-section{
  padding-top: 5px;
  position: relative;
}

.round-planner--delete-round-link{
  padding: 0 4px;
  font-size: 14px;
  color: rgba($red, 0.7);
  &:hover{
    color: rgba($red, 1);
  }
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid $curious-blue;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 422px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: $curious-blue;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: $curious-blue;

}

.tt-suggestion p {
  margin: 0;
}

.admin-edit-shop-form label.btn-primary{
  color: white;
}

.admin-shops-preview-image{
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);
  margin-top: 5px;
}

.input-group-addon{
  background: white;
  .fa, .fas, .fab, .far{
    color: $navy;
  }
}

a.show-hide{
  font-size: 14px;
  &:after{
    content: "Hide Filters";
  }
  &.collapsed:after{
    content: "Show Filters";
  }
}

#mem-loc-filters{

  @media screen and (min-width:1000px){
    .demo-filters .col-md-3:not(:last-child){
      padding-right: 0
    }
  }
}

.admin-shoppertunities--name{
  font-size: 16px;
  margin: 0;
}

.influencer-tasks{
  .progress-text{
    font-size: 14px;
    padding-top: 10px;
  }
  #task_table{
    @media(max-width: $screen-md-min){
      overflow-x: scroll;
    }
  }
  table{
    a{
      cursor: pointer;
    }
    .state-select{
      width: 40px;
    }
    .task-name{
      padding-right: 10px;
    }
    .task-description{
      vertical-align: top;
      width: 45%;
      p{
        padding-bottom: 5px;
        span {
          font-size: 90% !important;
        }
      }
      li a{
        color: $link-color;
      }
    }
    .alert-action{
      color: $alert-danger-text;
      font-weight: bold;
    }
    .task-action{
      width: 110px;
    }
    .btn-task-action{
      min-width: 90px;
    }
    @media(max-width: 600px){
      h4{
        font-size: 14px;
      }
      .state-select{
        width: 25px;
      }
    }
  }
}


// Round Instructions

.round_instructions_wrapper{
  font-family: $font-family-sans-serif;
  background: white;
  @media(max-width: $screen-sm-min){
    font-size: 13px;
  }
}

.instruct_nav_col{
  padding-right: 0;
}

.left-instructions-nav{
  z-index: 100;
  > a{
    display: block;
    padding: 10px;
    background: white;
    &:not(:last-of-type){
      border-bottom: solid 1px #eee;
    }
  }
  &.affix{
    width: 285px;
    @media(max-width: $screen-sm-min){
      position: static;
    }
  }
  &.affix-bottom{
    position: absolute;
    width: 100%;
  }
  @media(max-width: $screen-sm-min){
    .back_to_top{
      display: none;
    }
  }
}

.instruct_content_col{
  z-index: 101;
  border-left: solid 1px #eee;
  background: white;
  padding-left: 25px;
  @media(max-width: $screen-sm-min){
    border-left: none;
    padding-left: 15px;
  }
}

.instruct_label{
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  @media(max-width: $screen-sm-min){
    font-size: 14px;
  }
}

.instruct_anchor{
  margin: 0 0 5px;
  padding-top: 15px;
  @media(max-width: $screen-sm-min){
    font-size: 22px;
  }
}

.instruct_section{
  border-bottom: solid 1px #eee;
  padding: 15px 0;
  &:first-of-type{
    padding-top: 10px;
  }

  .instruction_grid {
    // If the page is big enough flex the container and space the cards out
    @media(min-width: 1050px) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .instruct_payment_col {
        min-width: 200px;
        max-width: calc(25% - 16px);
        min-height: 150px;
      }
    }

    // Otherwise, use block formatting and make everything full width
    @media(max-width: 1049px) {
      .instruct_payment_col {
        width: 100%;
        height: 124px;
      }
    }

    .instruct_payment_col{
      display: inline-block;
      text-align: left;
      background: white;
      border: 1px solid #DDDDDD;
      border-radius: 20px;
      margin-bottom: 12px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0px;
      padding-top: 5px;

      .payment-title {
        width: 100%;
        padding-left: 8px;
        border-bottom: 1px solid #DDDDDD;
        font-size: 11pt;
        margin-top: 8px;
        text-indent: 6px;

        .payment-content {
          margin-top: 4px;
          font-size: 12pt;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }

      .payment-description {
        padding: 6px;
        font-size: 7pt;
        margin-left: 12px;
        margin-top: 6px;
      }

      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //justify-items: center;

      .help-block {
        text-align: center;
      }
    }
  }
}

.instruct_checklist{
  padding: 10px;
  margin: 10px 0;
  border: solid 1px #eee;
  border-radius: 2px;
  background: rgba(234, 239, 242, 0.47);
  h5{
    font-weight: 600;
    margin: 0 0 7px;
  }
  li{
    padding: 5px 0;
    &:not(:last-of-type){
      border-bottom: solid 1px #ededed;
    }
  }
  .fa-check-square-o{
    opacity: 0.5;
    margin-right: 3px;
  }
}

.instruct_panel_group{
  margin-bottom: 5px;
  .panel-heading{
    padding-left: 15px;
    color: $link-color;
    font-size: 14px;
    font-weight: 400;
    border: none;
    text-align: left;
    width: 100%;
    &:hover{
      text-decoration: underline;
    }
  }
}

.instructions_btn_group{
  padding: 20px 0 10px;
  .btn{
    font-size: 12px;
    text-transform: none;
    + .btn{
      margin-left: 40px;
    }
  }
  @media(max-width: $screen-md-min){
    text-align: left;
    .btn{
      margin-left: 0;
      font-size: 12px;
      margin-bottom: 10px;
      + .btn{
        margin-left: 0;
      }
    }
  }
}

.instructions-actions{
  a{
    margin-top: 15px;
  }
}

// Shops show page

.shop_details--action_header, .shop_details--label{
  font-family: $font-family-sans-serif;
}

.shop_details--action_header{
  position: static;
  width: 100%;
  margin-bottom: 35px;
  font-weight: 600;
  transition: all 300ms ease;
  @media( $screen-md-min ){
    font-size: 13px;
    .btn{
      margin-top: 5px;
    }
  }
  .fa-info-circle{
    opacity: 0.3;
  }
  .btn{
    text-transform: none;
    pointer-events: all;
    margin-left: 4px;
    font-size: 14px;
    &#connect-fb-page{
      opacity: 1
    }
    .fa, .fas, .fab, .far{
      margin-left: 2px;
      color: rgba(black, 0.45);
      font-size: 12px;
    }
  }
  &.affix-top{
    .round_not_selected, .round_is_selected{
      border-bottom: solid 1px transparent;
    }
    .round_not_selected{
      border-color: #e6e6e6;
    }
  }
  &.affix{
    background: white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  &.round_checkbox_checked_js{
    .round_is_selected{
      position: static;
      opacity: 1;
    }
    .round_not_selected{
      position: absolute;
      opacity: 0;
      top: -9999px;
    }
  }
  @media(max-width: $screen-md-min) {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $--sc-white;
    z-index: 2;
    box-shadow: 0px -1px 5px 0 rgba(0, 0, 0, 0.2);
    margin: 0px;
  }
}

.round_is_selected,.round_not_selected{
  padding: 15px;
  transition: 0.35s cubic-bezier(0.4, 0, 1, 1) opacity;
}

.require_instagram_notice_js{
  .btn{
    opacity: 1;
  }
}

.round_is_selected{
  background: $evergreen;
  color: white;
  opacity:0;
  position: absolute;
  top: -9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    opacity: 1;
    border-color: transparent;
    &:hover, &:active, &:focus{
      border-color: transparent;
    }
  }
}

.shop_details--round_select_button {
  position: absolute;
  top: 0;
  right: 0;

  @media(min-width: $screen-sm-max) {
    right: 20px;
  }
}

.shop_details--round_checked {
  box-shadow: 0px 2px 6px 1px rgba(93, 195, 116, 0.4) !important;
  &:hover {
    box-shadow: 0 8px 16px 1px rgba(93, 195, 116, 0.4) !important;
  }
}

.shop_details--round_tasks {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;

  .badge {
    margin: 2px 5px 2px 0;
  }
}

.shop_details--top_box_row {
  margin-bottom: 35px;
  display: flex !important;
  flex-direction: row;
  width: 100%;
  margin-top: 0.6rem;
  padding: 0.6rem;

  @media only screen and (max-width: $screen-md-min) {
    flex-direction: column;
    padding: 0px;

    .shop_details--top_box {
      display: flex;
    }
  }
}

.shop_details--top_box_col {
  float: left;
  position: relative;
  flex-grow: 1;

  img {
    max-width: 100%;
  }

  @media(min-width: $screen-md-min) {
    height: 95px;
    text-align: center;

    img {
      max-height: 95px;
    }
  }

  @media(max-width: $screen-md-min) {
    width: 33.333333%;
  }

  @media(max-width: $screen-sm-max) {
    width: 100%;
    min-height: 30px;
    margin-bottom: 0.9rem;

    .shop_details--label {
      margin-left: 1rem;
    }

    .shop_details--label::after {
      content: ':';
    }

    .shop_details--box_item {
      margin-left: 1.1rem;
    }

    img {
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}

.shop_details--top_box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  .shop_details--label{
    padding-bottom: 2px;
    font-weight: 600;
    opacity: 0.4;
  }
}

.shop_details--box_item{
  font-size: 15px;
  .fa, .fas, .fab, .far{
    opacity: 0.7;
  }
}

.shop_details--cl_contact{
  font-size: 14px;
  margin-bottom: 20px;
  b{
    display: block;
    font-weight: 600;
  }
}

.shop_details--hide_shop_btn{
  a{
    color: $--sc-gray;
    &::before{
      margin-right: 5px;
      content: "\f1f8";
      font-family: "Font Awesome 5 Free";
    }
  }
}

.shop_details--unhide_shop_btn{
  a{
    color: $--sc-blue;
    &::before{
      margin-right: 5px;
      content: "\f06e";
      font-family: "Font Awesome 5 Free";
    }
  }
}

.round_card--row{
  padding: 7px 15px 7px;
}

.round_card{
  border-radius: 15px;
  box-shadow: 0 0 0 1px rgba(174, 182, 186, 0.5);
  background: white;
  margin-bottom: 30px;
  color: #555;
  overflow: hidden;
  // maybe change this
  h1{
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
  &.round_card--checked_js {
    box-shadow: 0 0 0 2px $evergreen;
    .round_card--checkbox_wrapper{
      label{
        background: white;
        color: black;
        &:hover{
          background: rgba(238, 238, 238, 0.4);
        }
      }
      .fa, .fas, .fab, .far{
        color: $evergreen;
        &:before{
          content: "\f046";
        }
      }
    }
  }
  .round_card--due_date {
    margin-bottom: 15px;
  }
}

.round_card--recommended_bid {
  color: $curious-blue;
  display: inline-block;
}

.round_card--bid_description {
  padding-left: 0;
}

.round_card--shop_name{
  padding-top: 0;
}

.round_card--description {
  position: relative;
  overflow: hidden;
  .round_card--description-body {
    max-height: 80px;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  .round_card-description-fade {
    position: absolute;
    height: 20px;
    width: 100%;
    top: 60px;
    background: #fff;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.shop_details--shop_description{
  font-size: 16px;
  line-height: 1.5;
  padding-right: 10px;
}

.round_card--show_more{
  white-space: nowrap;
}

.shop_details--label{
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  color: $--sc-sub-gray;
}

.shop_details--supertext{
  z-index: 1;
}
.round_card--checkbox_wrapper{
  label{
    font-weight: normal;
    color: $brighter-link-color;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    display: block;
    background: rgba(238, 238, 238, 0.4);
    border-bottom: solid 1px rgba(128, 128, 128, 0.25);
    &:hover{
      background: rgba(238, 238, 238, 1);
    }
  }
  input[type="checkbox"]{
    display: none;
  }
  .fa, .fas, .fab, .far{
    display: block;
    float: left;
    margin-left: 7px;
    margin-top: 4px;
    margin-right: 8px;
  }
}

.round_card--round_task_btn{
  border: solid 1px #eee;
  background: rgba(238, 238, 238, 0.4);
  border-radius: 2px;
  font-size: 11px;
  margin: 5px 5px 8px 0;
  padding: 3px 5px;
  color: #444;
  cursor: default;
  .fa, .fas, .fab, .far{
    margin-right: 2px;
    opacity: .7;
    font-size: 10px;
  }
  &:hover{
    color:#444
  }
}

.round_card--bid_payment_container{
  float: left;
  width: 100%;
  .open-bid-payment-group{
    margin-top: 6px;
  }

  @media screen and (min-width:500px){
    float: right;
    width: auto;
    .open-bid-payment-group{
      margin-top: 0;
      display: inline-block;
    }
    .open-bid-payment-group:nth-child(2){
      margin-left: 12px;
    }
  }
}

.influencer-invite-modal {
  .form-group {
    position: relative;
  }
  .input-group-addon {
    border-color: $blue-grey;
    border: 1px solid $--fc-gray;
  }
  .modal-body{
    select{
      appearance: menulist;
    }
  }
  .users {
    margin: 0;
    padding: 0 0 0 4px;
    width: 90%;
    height: auto;
    max-height: 150px;
    overflow: auto;
    &.disabled {
      background-color: #e3e3e3;
      cursor: no-drop;
    }
    li {
      list-style-type: none;
      padding: 3px 7px 4px;
      cursor: pointer;
      &.total-results {
        cursor: default;
        pointer-events: none;
      }
      &.no-results {
        background-color: #fcf8e3;
        color: #c09853;
        cursor: default;
        pointer-events: none;
      }
      &.disabled {
        background-color: #e3e3e3;
        cursor: no-drop;
      }
      img {
        height: 40px;
        width: 40px;
        margin: 0 10px 0 0;
      }
    }
    &.list {
      z-index: 1;
      position: absolute;
      border-top: none;
      border-radius: 0 0 3px 3px;
      li {
        &:hover {
          color: white;
          background: $navy;
        }
      }
    }
    &.selected {
      li {
        img {
          height: 25px;
          width: 25px;
        }
        .closable:after{
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\f00d";
          opacity: 0.45;
          position: absolute;
          right: 10px;
          bottom: 9px;
        }
      }
    }
  }
}

td {
  .edit-invite {
    cursor: pointer;
    .app {
      margin-right: 4px;
    }
  }
}

#invited-password-modal{
  .modal-header{
    background-color: $denim;
    color: white;
  }
  .modal-body{
    .row{
      margin: 5px 0;
      text-align: center;
      &.modal-description{
        width: 80%;
        margin: auto;
        .lock-icon{
          position: relative;
          display: inline-block;
          border-radius: 50%;
          padding: 10px 22px;
          background-color: $lighter-blue;
          color: $golden;
          .lock-icon-dots{
            position: absolute;
            color: $light-black;
            right: 31%;
            top: 37%;
            font-size: 45px;
            font-weight: bold;
          }
        }
        p {
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          color: $dark-blue;
          margin-bottom: 20px;
        }
      }
      input{
        display: inline-block;
        width: 200px;
        border-width: 2px;
      }
      .password-warning{
        color: $danger-red;
      }
    }
  }
  .btn {
    min-width: 85px;
    margin: 15px auto;
    &.accepted{
      background-color: $dark-green;
    }
  }
}

.shoppertunities_conversations_body {
  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > li {
        margin: 0 2.5px;
        cursor: pointer;
        padding: 5px 10px;
        list-style: none;
        // border-radius: 3px;
        color: #187bd1;
        background-color: #fff;
        border: 1px solid #ddd;
        &:hover {
          color: #1360a3;
          background-color: #eeeeee;
        }
        &[data-disabled=true] {
          color: #D1D1D1;
          background: #fff;
          cursor: not-allowed;
        }
        &[data-currentPage=true] {
          cursor: default;
          color: #fff;
          background-color: #187bd1;
        }
      }
    }
  }
}

.shoppertunity-rounds--table{
  border: 1px solid #ddd;
  .no-content-row{
    text-align: center;
  }
}

.shoppertunities-filter--button,
.shoppertunities-sort--button{
  display: flex;
  align-items: center;
  .count-bubble {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    // min-width: 18px;
    // height: 18px;
    padding: 3px 6px;
    margin-left: 4px;
    font-size: $--fs-x-small;
    color: $--sc-white;
    background-color: $--sc-blue;
    border-radius: $--br-default;
    margin-right: 5px;
  }
}

.shoppertunities-filter--overlay,
.shoppertunities-sort--overlay{
  @media(max-width: 575px){
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: 998;
  }
}

.shoppertunities-filter--card,
.shoppertunities-sort--card{
  width: 350px;
  @media(max-width: $--bp-small){
    position: fixed;
    width: 100%;
    height: 70vh;
    top: unset;
    bottom: 0;
    left: 0;
    margin: 0;
    border-radius: $--br-default $--br-default 0 0
  }

  .card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-body{
    display: flex;
    flex-wrap: wrap;
    .filter-container{
      width: 100%;
    }
    .filter, .sort{
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name .label-description{
        color: $--sc-sub-gray;
      }
      .name-selected-container{
        width: 100%;
        max-width: 90%;
        margin: 0 auto 0 0;
        .name{
          width: 20%;
        }
        .selected{
          width: 100%;
          font-size: smaller;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      i{
        font-weight: 900;
      }
    }
  }
  .card-footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn{
      width:100%;
      @media(max-width: $--bp-small){
        width: 325px;
        position: absolute;
        bottom: 0;
        margin-bottom: 15px;
      }
    }
  }
}

.shoppertunities-filter--card{
  .name{
    width: 20%;
  }
  .card-body{
    max-height: 85%;
    overflow: auto;
    @media(min-width: $--bp-small){
      max-height: 233px;
      margin-bottom: 10px;
    }
    .filter-option{
      width: 100%
    }
  }
}

.shoppertunities-sort--card{
  @media(min-width: $--bp-small){
    margin-right: 15px;
    right: 0;
  }
  .card-body{
    .sort {
      cursor: pointer;
      .name {
        width: 80%;
      }
      .radio{
        width: 5%;
        padding: 0;
        margin: 0 0 20px 0;
      }
    }
  }
}

.custom-dropdown{
  margin: -20px -14px;
}

// Round task requirement styling
@import 'components/campaign/campaign_requirements'
