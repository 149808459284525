article.round {
  // margin-bottom: 20px;
  h4 {
    margin: 0;
  }
  .round-action-links a{
    display: block;
    margin-bottom: 5px;
    width: 130px;
  }
  hr { border-top: 2px solid white; }
}
.rounds-dropdown{
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}
.rounds-dropdown li a{
  overflow:hidden;
  text-overflow:ellipsis;
}
.round-nmbr{
  text-align:left;
  padding-top:10px;
  padding-bottom:10px;
  font-size:28px;
}
.rounds-dropdown a.current-round{
  color:#FFFFFF;
  text-shadow:0px 0px 3px rgba(0, 0, 0, 0.38);
  background-color: $light-green;
}
.rounds-dropdown a.current-round:hover{
  text-shadow:none;
}
section .open .btn-default.dropdown-toggle{
  color:$dark-grey;
}

tr.round {
  cursor: pointer;
}
