.external_social_auth_wrapper {
  .social_accounts_wrapper, .finish_auth_wrapper{
    display: flex;
    justify-content: center;
  }

  .social_accounts_wrapper{
    padding: 30px 15px;
  }
  
  @media screen and (min-width: $--bp-medium){
    .social-accounts-card{
      width: 33%;
    }
  }
}