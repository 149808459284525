.round-tasks.template-legend-row {
  padding: 4px 8px;
  font-size: 11pt;
  font-weight: 400;
  margin: 1px 4px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background-color: white;
  display: flex;
  align-items: center;

  .fa-circle {
    padding-right: 8px;

    &.palette-0 {
      color: $--sc-blue;
    }

    &.palette-1 {
      color: $--sc-purple;
    }

    &.palette-2 {
      color: $--sc-teal;
    }

    &.palette-3 {
      color: $--sc-green;
    }

    &.palette-4 {
      color: $--sc-red;
    }

    &.palette-5 {
      color: $--sc-orange;
    }

    &.palette-6 {
      color: $--fc-blue;
    }

    &.palette-7 {
      color: $--fc-purple;
    }

    &.palette-8 {
      color: $--fc-teal;
    }

    &.palette-9 {
      color: $--fc-green;
    }

    &.palette-10 {
      color: $--fc-red;
    }

    &.palette-11 {
      color: $--fc-orange;
    }
  }
  .template-legend-row--delete{
    position: relative;
    padding-left: 10px;
    .fa-times {
      cursor: pointer;
      color: #8D8EA5; //$--sc-sub-gray
      &:hover {
        color: $danger-red; //$--sc-danger-red
      }
    }
  }
}
