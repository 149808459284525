.submission-blog-draft {
  max-width: 800px;
  margin: auto;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;

  .annotator-wrapper {
    img {
      max-width: 100%;
    }
  }

  .btn.btn-block {
    margin: 3px;
  }

  .blog-draft-buttons {
    display: flex;
    align-items: center;
  }

  .redactor-box {
    .redactor-toolbar[role=toolbar] {
      z-index: 9;
    }
  }
}
