@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(indeterminate) {
  0% {
    left: -35%;
    right:100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@include keyframes(indeterminate-short) {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.material-progress-bar{
  overflow: hidden;
  width: 100%;
  height: 4px;
  margin: auto;
  background-color: rgba($silver, 0.5);
  &.absolute-top {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.absolute-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .bar{
    height: 4px;
    border-radius: 2px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-color: $denim;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.5s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    &:after {
      content: '';
      position: absolute;
      background-color: $denim;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.5s;
    }
  }
}