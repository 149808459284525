.bid_negotiations-card{
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 10px;
  .bid_info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .bid_info--round{
      font-size: 24px;
      margin: 0;
    }
    .bid_info--status{
      margin: 0px 10px;
      padding: 5px 8px;
      color: #eee;
      font-weight: bold;
      border-radius: 10px;
      font-size: 14px;
      &.pending{
        color: #111;
        background-color: $supernova;
      }
      &.declined{
        background-color: $danger-red;
      }
      &.accepted{
        background-color: $dark-green;
      }
    }
    .bid_info--due_date{
      width: 100%;
      label: {
        font-weight: bold;
      }
    }
  }
  .bid_info--tasks{
    display: inline-block;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    label{
      width: 100%;
      margin: 0;
    }
    .bid_info--tasks--item{
      margin-top: 8px;
      margin-right: 8px;
      font-size: 12px;
      padding: 5px 8px;
      border-radius: 10px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      background-color: $lighter-blue;
      color: $denim;
    }
  }
  .bid_info--description{
    margin-top: 30px;
  }
  .bid_info--bids{
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .bid_info--original_bid,
    .bid_info--suggested_bid {
      display: inline-block;
      text-align: right;
      font-size: 16px;
      label{
        width: 100%;
        display: block;
      }
      span {
        display: block;
        text-align: right;
      }
    }
    .bid_info--original_bid{
      margin-right: 50px;
    }
  }
  .bid_info--notes{
    width: 100%;
    margin-top: 15px;
    label{
      font-weight: bold;
    }
  }
  .bid_info--buttons{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .bid_info--error_messaging{
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    color: $danger-red;
    margin: 15px 0;
    i{
      width: 100%;
    }
    p{
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }

  }
  .modal{
    .modal-header{
      font-weight: bold;
    }
    .bid_info--modal--close_btn{
      float: right;
      border-radius: 50%;
      border: none;
      &:hover{
        color: $danger-red;
      }
    }
  }
}

.bid_history{
  display: flex;
  align-content: center;
  flex-direction: column;
  h5{
    color: $denim;
    cursor: pointer;
  }
  table{
    text-overflow: ellipsis;
    text-align: right;
    font-size: 12px;
    tr{
      border-bottom: solid 1px $blue-grey; //$border-grey
    }
    th, td{
      min-width: 70px;
      padding: 0 5px;
    }
    th{
      text-align: left;
    }
    td{
      text-align: center;
      vertical-align: top;
      max-width: 70px;
      &.bid_history--table--note_cell,
      &.bid_history--table--reason_cell{
        max-width: 320px;
        text-align: left;
      }
    }
  }
}