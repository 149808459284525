.account-settings.category-chip {
  display: inline-block;
  border-radius: 20px;
  background-color: $--sc-sub-gray;
  color: white;
  cursor: pointer;
  padding: 3px 6px;
  font-size: 9pt;
  margin-right: 2px;
  margin-bottom: 2px;
}
