.connected-networks-container {
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  
  .connected-networks-form-card {
    .input-wrap {
      padding: 0 0 20px;
    }
    .blog-form > .remove-button-wrapper {
      padding-top: 20px;
      text-align: center;
    }
    .card-footer{
      text-align: center;
      background: $--sc-white;
    }
  }
}
