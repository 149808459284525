$footer-height: 132px;
$footer-bg-color: $--sc-blue;
$footer-spacing: em(30px, 14px);
#footer{
  background: #fff;
  .bottom-nav{
    padding-top: $footer-spacing;
    padding-bottom: $footer-spacing;
    background: $footer-bg-color;
    min-height: $footer-height;
    color: #fff;
    ul{
      list-style:none;
      padding: 0;
      margin: 0;
    }
    a{
      color: $blue-grey;
      font-size: em(13px, 14px);
      line-height: 1.75;
      white-space: nowrap;
      &:hover{
        text-decoration: underline;
      }
    }
    .list-header{
      padding:0 0 3px 0;
      font-weight:600;
      font-size: em(18px, 14px);
    }
    .support--link-group{
      margin-top: 28px;
      right: 3%;
      padding-right: 0;
      @media(max-width: $screen-sm-max){
        right: 0;
      }
      @media(max-width: $screen-xs-max){
        margin-top: 0;
      }
    }
    .footer--section-heading{
      font-weight: $light;
      display: inline-block;
      font-size: em(26px, 14px);
    }
    .footer-social-media{
      text-align: right;
      @media (max-width: $screen-sm-max){
        text-align: left;
        .sofab-social-network-icons a{
          margin: 0px 10px 0 0;
        }
      }
    }
    .footer--section-heading{
      border-bottom: 2px solid $light-green;
      padding-bottom: 12px;
      margin-bottom: 12px;
      margin-top: 10px;
    }
    .sofab-social-network-icons a{
      color: #fff;
      margin: 0 5px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.44);
      &:hover{
        text-decoration: none;
      }
      &:last-of-type{
        margin-right: 0;
      }
    }
    .sofab-social-network-icons--icon{
      font-size: 38px;
    }
  }

  .platforms-container{
    font-weight:700;
    padding-top: $footer-spacing;
    padding-bottom: $footer-spacing;
    .footer--section-heading{
      color: $true-blue;
      margin: 0;
      @media(max-width: $screen-sm-max){
        > span{
          display: inline-block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .platforms--column{
    @media (max-width: $screen-sm-max){
      text-align: left;
      width: 20%;
    }
    @media (max-width: 825px){
      width: 33.333%;
      min-height: 70px;
      .platforms--platform{
        margin-top: 15px;
      }
    }
    @media (max-width: 525px){
      width: 50%
    }
  }
  .platforms--platform{
    display: inline-block;
    margin-top: 6px;
  }
}

.footer--hr{
  color: lighten($silver, .3);
  margin: 0;
}

.footer--copyright{
  padding-top: $footer-spacing;
  padding-bottom: $footer-spacing;
  font-size: em(12px, 14px);
  color:$silver;
  .footer--cb-logo{
    margin: 0 5px;
    vertical-align: middle;
  }
}

.footer--cb-logo{
  width: 160px;
  height: 38px;
  display: inline-block;
}

.footer--credit{
  text-transform: uppercase;
  letter-spacing: 2px;
}

.footer--copyright-links{
  @media(max-width: $screen-sm-max){
    text-align: left;
  }
}

.footer--copyright-link:hover{
  text-decoration: underline;
}

.footer--copyright-disclaimer{
  @media(max-width: $screen-xs-max){
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
