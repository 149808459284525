.submission.manual-analytics {
  margin-top: 8px;
  margin-bottom: 20px;

  .manual-analytics-title {
    font-size: 12pt;
    margin-bottom: 2px;
    padding: 4px;
  }
}
