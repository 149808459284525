/* Badge Styles
 *
 * Note: use the background style classes in variables.scss if you want to override the color
 *
----------------------------------- */
.badge {
  display: inline-block;
  width: auto;
  padding: 2px 6px;
  text-decoration: none;
  text-align: center;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  border-radius: 50px;
  border: 1px solid transparent;
  color: $--sc-white;
}
