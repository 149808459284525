.well {
  background-color: white;
  border: none;
}

.sub-text {
  margin-top: -9px;
}

#url-error {
  display: block;
}

.fb-video-url {
  display: none;
  width: 60%;
}

.fb-vid {
  .selected{
    background: $facebook-color;
    color: white;
  }
  .item {
    padding: 10px;
  }
  .carousel-control {
    width: 0;
    top: 50%;
    color: $facebook-color;
  }
  .carousel-indicators {
    li {
      background: grey;
    }
    bottom: -40px;
    .active {
      background: $facebook-color;
    }
  }
  .vid-right {
    left: auto;
    right: 10px;
  }
  .vid-left {
    left: 2px;
  }
}

section.content-to-do {
  background-color: $form-grey;
  padding: 20px;
  margin-bottom: 30px;
  ul {
    padding-left: 20px;

    li {
      margin: 5px 0;
    }

    .rule-title {
      font-size: 1.3em;
      font-weight: 300;
      margin-right: 10px;
    }
  }
}

.admin_contents_body {
  fieldset {
    // Chrome had a user agent style
    // min-width that fucked up shit
    min-width: 100%;
    .select2-container {
      margin-bottom: 10px;
      max-width: 735px; //keep select box in container width in Firefox
    }
  }

  .accept-checkbox {
    font-size: 20px;
    margin: 0 auto;
    width: 90px;
    input {
      margin-top: 7px;
    }
  }

  .accept_or_reject_radios label{
    font-size: 22px;
    margin-right: 15px;
  }
}

.validation-icon-box {
  position: absolute;
  top: 2px;
  right: 20px;
  width: 20px;
  height: 30px;
  padding-top: 4px;
  text-align: center;
  background: white;
}

.content_form, #edit_content {
  .right {
    text-align: right;
  }
  .redactor-box{
    textarea,.redactor-editor {
      min-height: 300px;
    }
    #content_draft_attributes_body{
      background-color: #FFFFFF;
      color: #555555;
      border: 1px solid #CCCCCC
    }
  }
}

.draft-body img{
  max-width: 100%;
}

#preview-img{
  width: 100%;
  margin: 25px auto;
  display: block;
}
.thumb,.vidthumb{
  border: 1px solid #000000;
  width: 150px;
  margin: 10px 5px 0 0;
  vertical-align: middle;
}

.carousel-instagram-outer{
  margin: 0 auto 40px;
  width: 100%;
  max-width: 640px;
  height: 640px;
  #carousel-instagram{
    width: 100%;
    height: 100%;
    text-align: center;
    .carousel-indicators{
      bottom: -30px;

      li{
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        background: none;
        text-indent: 0;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 3px;
        height: 28px;
        width: 28px;
        color: grey;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        border: 1px solid #d3d3d370;
      }

      li.active{
        color: white;
        padding-top: 6px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #66c154;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        border: none;
      }
    }
    .carousel-inner{
      height: inherit;
      .item{
        height: inherit;
        .image_container{
          height: inherit;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        video::-webkit-media-controls-panel {
          width: 700px;
          margin: auto;
        }
      }
    }
    .carousel-control {
      top: 17px;
      background-image: none !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .fa, .fas, .fab, .far {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      &:hover .fa,
      &:hover .fas,
      &:hover .far,
      &:hover .fab {
        color: $cb_purple_light;
        opacity: 1.0;
      }
    }
  }
}

.instagram-caption{
  max-width: 640px;
  p{
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  textarea{
    min-height: 52px;
  }
  width: 80%;
  margin: auto;
  padding-bottom: 60px;
}

.instagram-video-wrapper{
  display:flex;
  width:100%;
  max-height:100%;
  position:relative;

  .video {
    width: 100%;
    border: 1px solid black;
    margin: auto;
  }
  .playpause {
    text-align: center;
    color: white;
    position: absolute;
    top: calc( 50% - 30px);
    left: calc( 50% - 30px);
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 0;
    span{
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #6e62ac;
      text-align: center;
      padding-top: 16px;
      .fa-play {
        padding-left: 6px;
      }
    }
  }
}

.tiktok-draft-outer{
  margin: 0 auto 40px;
  width: 100%;
  max-width: 640px;
  height: 640px;
  #tiktok-draft{
    width: 100%;
    height: 100%;
    text-align: center;
    .tiktok-inner{
      height: inherit;
      .item{
        height: inherit;
        video::-webkit-media-controls-panel {
          width: 700px;
          margin: auto;
        }
      }
    }
  }
}

.tiktok-video-wrapper{
  display:flex;
  width:100%;
  max-height:100%;
  position:relative;

  .video {
    width: 100%;
    border: 1px solid black;
    margin: auto;
  }
  .playpause {
    text-align: center;
    color: white;
    position: absolute;
    top: calc( 50% - 30px);
    left: calc( 50% - 30px);
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 0;
    span{
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #6e62ac;
      text-align: center;
      padding-top: 16px;
      .fa-play {
        padding-left: 6px;
      }
    }
  }
}

.tiktok-caption{
  max-width: 640px;
  p{
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  textarea{
    min-height: 52px;
  }
  width: 80%;
  margin: auto;
  padding-bottom: 60px;
}

.required_post_elements {
  .tooltip-question{
    padding-left: 0px;
  }
  i {
    padding-left: 10px;
  }
}

$sidebar-primary: #3a3a3a;
$sidebar-stripe: #f5f5f5;
$sidebar-open-width: 360px;

.sidebar-flex {
  @media(max-width: 620px) {
    .sidebar-icon {
      display: none;
    }
  }

  .row {
    width: 100%;
    margin: 0;
  }

  @media(min-width: 621px) {
    padding-left: 108px !important; /* Force left padding for the sidebar to not overlap */
    display: flex;
    position: relative;
    width: 100%;
    max-width: none;

    .sidebar-icon {
      cursor: pointer;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 30px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 101;
      background-color: $sidebar-primary;
      color: #dedede;
      width: 82px;

      .content {
        text-align: center;
        .fa,.fas, .fab, .far {
          font-size: 26pt;
        }
      }
    }

    & > .card-wrapper--white-bg {
      flex: 1;
      max-width: 100%;
    }
  }

  .sidebar-popover {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: $sidebar-open-width;
    height: 100%;
    z-index: 102;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    overflow-y: scroll;

    /* Hide scrollbars... */
    &::-webkit-scrollbar {
      width: 0 !important ;
    }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    /* Slide into the page */
    left: -$sidebar-open-width;
    animation: slide 0.5s forwards;
    @keyframes slide {
      100% {
        left: 0;
      }
    }

    &.slide-out {
      left: 0;
      animation: slideBack 0.5s forwards;
      @keyframes slideBack {
        100% {
          left: -$sidebar-open-width;
        }
      }
    }

    & > .sidebar-popover-controls {
      text-align: right;
      background-color: $sidebar-primary;

      .sidebar-close {
        cursor: pointer;
        margin-right: 4px;
        margin-top: 1px;
      }
    }

    & > .sidebar-popover-title {
      margin-top: 0;
      text-align: center;
      font-weight: 100;
      background-color: $sidebar-primary;
      padding-bottom: 24px;
      color: white;
    }

    .sidebar-listing {
      padding-top: 8px;
      padding-bottom: 8px;
      padding: 8px;

      &:nth-child(2n) {
        background-color: $sidebar-stripe;
      }

      .title {
        font-weight: 800;
        text-align: center;
        color: #0b5ca4;
      }

      .quote-container {
        text-align: justify;
        hyphens: auto;
        .quote {
          font-style: italic;
        }
      }
    }
  }
}

$ig-content-mobile-break: 640px;

.ig-content-selector {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;

    .post, .story {
        flex: 1;
        display: inline-block;
        margin: 4px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        cursor: pointer;
        border: 3px solid transparent;
        padding: 0;

        .preview {
            width: 100%;
            position: relative;
            text-align: center;
            img, video {
                max-width: 100%;
                min-height: 200px;
                border-radius: 4px;
                padding: 4px;
            }
            i {
              position: absolute;
              left: 0;
              right: 0;
              margin-top: 50%;
              color: $light-green;
              font-size: 8em;
              z-index: 1000;
            }
        }

        .caption-text {
            padding: 6px;
            font-size: 11pt;
            height: 100px;
            overflow-y: hidden;
            display: fixed;
            bottom: 0;
        }

        &.post-selected, &.story-selected{
            border: 3px solid $cb_purple_light;
        }

        @media(max-width: $ig-content-mobile-break) {
            flex-basis: 100%;
        }
    }

    .story {
      padding: 20px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 -2px 10px -1px rgba(0, 0, 0, .2);
    }

    .padding-card {
        flex: 1;
    }

    .arrow {
        flex: 0;
        font-size: 30pt;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 6px;
        justify-content: center;

        @media(max-width: $ig-content-mobile-break) {
            order: 9;
            flex-basis: 50%;
        }
    }

    .button-bar {
        flex-basis: 100%;
        display: flex;
        flex-direction: row-reverse;
    }
}

.instagram-story-view{
  display: block;
  max-width: 1200px;
  margin: auto;
  .instagram-story-view--card{
    display: inline-block;
  }
  .story_card{
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 40px 15px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #DDDDDD;
    .story_content{
      margin: auto;
      width: 225px;
      height: 400px;
      img, video {
        max-height: 100%;
        max-width: 100%;
      }
    }
    i{
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 30%;
      color: $danger-red;
      font-size: 15em;
      z-index: 1000;
    }
    .btn{
      display: inline-block;
      margin-top: 15px;
      color: $danger-red;
      border-color: $danger-red;
      background: white;
      &.rejected {
        color: #eee;
        background: $danger-red;
      }
    }
    .story_rejection_reason{
      margin: 20px 0;
      display: block;
      text-align: left;
      textarea {
        display: block;
        width: 100%;
        font-weight: normal;
      }
    }
    .refresh-button{
      position: absolute;
      z-index: 3;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $--sc-background;
      box-shadow: 3px 5px 5px rgba(0,0,0,0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      i.refresh-icon{
        position: relative;
        z-index: 0;
        color: $--sc-blue;
        font-size: 1rem;
        margin: auto;
      }
    }
  }
  br {
    width: 100%;
    content: '';
  }
  .content_info{
    display: block;
    text-align: center;
    .content_info-followers{
      display: block;
      max-width: 25%;
      margin: auto;
    }
    #manual_instagram_followers{
      max-width: 25%;
    }
    h4 {
      display: inline-block;
      margin: 30px;
      .content_info-value {
        color: $denim;
      }
    }
  }
  .content_insights{
    width: 100%;
    margin: 10px;
    border-radius: 5px;
    padding: 15px;
    background: #f5f5f5;
    label {
      display: block;
      text-align: left;
      width: 100%;
      .content_insights-value{
        display: block;
        width: 100%;
        font-weight: normal;
      }
    }
  }
  .reject_comment-modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(150,150,150, .75);
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    transition: all 0.7s;
    .reject_comment-modal_close {
      color: #aaa;
      position: absolute;
      right: 0;
      top: 0;
      text-decoration: none;
      padding: 15px 28px;
      &:hover {
        color: $denim;
      }
    }
    .reject_comment-modal_view{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 2em;
      background-color: white;
      border-radius: 5px;
      h4{
        color: $denim;
      }
      .reject_comment-modal_label{
        display: block;
        text-align: left;
        width: 100%;
        .reject_comment-modal_value{
          display: block;
          min-width: 500px;
          font-weight: normal;
        }
      }
      .btn{
        display: block;
        margin: 10px auto;
        background: $danger-red;
        &:hover {
          background: darken($danger-red, 5%);
        }
      }
    }
  }

  .content_grading{
    width: 100%;
    margin: 0 auto;
    .content_grading-dropdown-title {
      display: inline-block;
      text-align: right;
      color: $denim;
      h4{
        margin-top: 6px
      }
    }
    .content_grading-dropdown{
      margin: 10px 0;
      width: 100%;
      }
    }
  }
  .content_actions{
    margin: 40px 0;
    text-align: center;
    .content_actions-rejection {
      margin: 20px auto;
      max-width: 500px;
      label{
        text-align: left;
        display: block;
        width: 100%;
      }
    }
    .content_actions-errorText{
      text-align: center;
      margin: 10px auto;
      h3 {
        color: $danger-red;
      }
    }
    .rejected{
      background: $danger-red;
      &:hover{
        background: darken($danger-red, 5%);
      }
    }
    .accept{
      background: $dark-green;
      &:hover{
        background: darken($dark-green, 5%);
      }
    }
  }
  //shared dropdown settings
  .content_grading-dropdown-select,
  .content_actions-rejection{
    position: relative;
    text-align: left;
    border-radius: 5px;
    padding: 0;
    .btn {
      position: relative;
      width: 100%;
      height: 35px;
      text-align: left;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      i {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    ul{
      width: 100%;
      .dropdown-item{
        display: block;
        padding: 5px;
        color: black;
        width: 100%;
        &.active {
          background: $denim;
          color: #eee;
        }
        &:hover:not(.active) {
          background: $light-grey;
        }
      }
    }
}

.uploads-container{
  .flex-item{
    @media(max-width: $ig-content-mobile-break) {
      width: 100% !important;
    }
  }
}

.youtube-contents {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  justify-content: space-between;

  .card {
    width: 250px;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;
    position: relative;
    overflow-y: hidden;

    &:before {
      content:'';
      width:100%;
      height:100%;
      position:absolute;
      left:0;
      top:0;
      background:linear-gradient(transparent 230px, white);
    }

    .card-preview {
      width: 100%;
    }

    .card-title {
      padding: 6px;
      font-weight: 500;
    }

    .card-body {
      font-weight: 200;
      font-size: 13pt;
      padding: 10px;
      padding-top: 0;
    }

    .selected-video {
      position: absolute;
      color: green;
      left: 50%;
      top: 32%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      background-color: white;
      border-radius: 50%;
      padding: 0px;
      text-align: center;
      width: 51px;
      line-height: 0;
      height: 50px;
    }
  }

  &:last-child:after {
    content: "";
    flex: 0 0 27%;
  }
}

.manual-submission{
  a{
    &:hover{
      cursor: pointer;
    }
  }
  .link-input {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 15px;
    label{
      flex: 2;
    }
    input{
      flex: 5;
    }
  }
}

// Responsive iframe courtesy of https://avexdesigns.com/responsive-youtube-embed/
#youtube-preview {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, embed, object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.js_ig_draft_checkbox {
  cursor: pointer;
}
