.shop-application-container {
  display: flex;
  flex-flow: column;
  .round-card-container {
    margin-bottom: 25px;
  }
  .round-select-container {
    margin-bottom: 25px;
  }
  .blog-selection-container {
    margin-bottom: 20px;
    max-width: 400px;
  }
  .fixed-button-container {
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: $--sc-white;
    box-shadow: 0px 15px 20px 0px #000;
  }

  @media screen and (min-width: $--bp-medium){
    flex-flow: row nowrap;
    .round-card-container {
      width: 40%;
      order: 2;
      margin: 20px;
    }
    .shop-application-form {
      width: 60%;
      order: 1;
      margin: 20px;
    }
  }
}