.influencer-dashboard.payment-card {
  color: $--tc-default;

  .top-row {
    width: 100%;
    margin-bottom: 10px;
    display: flex;

    .shop {
      flex: 1;
      font-size: $--fs-large;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
      
      .job-code {
        font-size: $--fs-small;
      }
    }

    .amount {
      font-size: $--fs-x-large;
    }
  }

  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 4px;
    font-size: $--fs-small;
  }
}
