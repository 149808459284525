.community-logins.entry-buttons {
  text-align: center;

  .btn {
    border-radius: 20px;
    border: 1px solid #ddd;
    background-color: white;
    color: black;

    &.facebook > i {
      color: $facebook-color;
    }

    &.google > i {
      color: $youtube-color;
      padding-right: 2px;
    }

    &.instagram > i {
      color: $instagram-color;
    }
  }
}
