$mobile_break_point: 500px;
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(indeterminate) {
  0% {
    left: -35%;
    right:100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@include keyframes(indeterminate-short) {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.new-member-sign-up{
  padding: 3em;
  max-width: 450px;
  margin: 30px auto 50px;
  background: $gallery;
  hr{
    border-color: rgba($silver, 0.5);
    margin: 5px 0 10px;
  }
  .new-member-requirements{
    margin: 35px 0;
    h4{
      font-weight: bold;
    }
    ul{
      padding: 0 15px;
      li{
        padding: 4px 0;
      }
    }
  }
  .new-member-actions{
    text-align: center;
    position: relative;
    .get-started{
      padding: 6px 40px;
    }
    h4{
      margin: 15px 0;
    }
    .decorated{
      overflow: hidden;
      text-align: center;
      min-height: 20px;
    }
    .decorated > span{
        position: relative;
        display: inline-block;
    }
    .decorated > span:before,
    .decorated > span:after{
        content: '';
        position: absolute;
        top: 50%;
        border-bottom: 1px solid;
        border-color: rgba($silver, 0.5);
        width: 591px; /* half of limiter*/
        margin: 0 20px;
    }
    .decorated > span:before{
        right: 100%;
    }
    .decorated > span:after{
        left: 100%;
    }
  }
}

.sign-up-container{
  max-width: 1210px;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding: 3em;
  background: white;
  @media screen and (max-width: $mobile_break_point){
    padding: 1em 3em 3em;
  }
  h1{
    span {
      text-transform: uppercase;
      color: #1A6FB0;
    }
  }

  .sign-up-nav{
    .progress{
      background-color: rgba($silver, 0.5);
      height: 4px;
      border-radius: 2px;
      box-shadow: none;
      margin: auto;
      .bar{
        height: 4px;
        border-radius: 2px;
        &.disabled{
          background-color: rgba($denim, 0.5);
        }
        &.determinate {
          background-color: $denim;
        }
        &.indeterminate {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            background-color: $denim;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.5s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          }
          &:after {
            content: '';
            position: absolute;
            background-color: $denim;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.5s;
          }
        }
      }
    }
    .nav-info-mobile {
      // Mobile
      @media screen and (max-width: $mobile_break_point){
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: stretch;
        justify-content: center;
        padding: 10px 0;
      }
      // Non-Mobile
      @media screen and (min-width: $mobile_break_point + 1){
        display: none;
      }

      .nav-item {
        flex: 0 1 auto;
        position: relative;
        padding: 0 10px 0 10px;
      }
    }

    .nav-info{
      // Non-mobile
      @media screen and (min-width: $mobile_break_point + 1){
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: stretch;
        justify-content: space-between;
        max-width: 88%;
        margin: 10px auto 0;
        padding: 0 10px;
      }
      // Mobile
      @media screen and (max-width: $mobile_break_point){
        display: none;
      }
      .nav-item {
        flex: 0 1 auto;
        position: relative;
        padding-bottom: 10px;
        &.complete{
          cursor: pointer;
          label {
            cursor: pointer;
          }
        }
        &.active{
          color: #000;
          label {
            font-weight: bold;
          }
        }

        &.disabled{
          color: $dusty-grey;
        }
        label {
          bottom: 0;
          max-width: none;
          margin: auto;
          font-weight: 400;
          font-size: 16px;
          width: auto;
        }
      }
    }
  }

  .sign-up-title {
    h1 {
      margin: 40px 0 20px;
      font-size: 40px;
      font-weight: 300;
      &.active span {
        color: $denim;
        text-transform: uppercase;
      }
    }
  }

  .sign-up-step {
    > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: start;
      > .column {
        &:first-of-type {
          flex-basis: 70%;
        }
        &:last-of-type {
          flex-basis: 30%;
        }
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
      }
    }

    .form {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: start;
      max-width: 650px;
      @media screen and (max-width: $mobile_break_point){
        flex-flow: column;
      }
      .column {
        flex: 1;
        min-width: 300px;
        @media screen and (max-width: $mobile_break_point){
          min-width: 0;
          width: 100%;
        }
      }
      .form_input_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        max-width: 95%;
        margin: 15px 0;
        &.children_2 {
          .input_wrap {
            width: 48%;
            margin: 0;
          }
        }
      }
      .input_wrap {
        max-width: 95%;
        margin: 15px 0;
        &#pinterest_url{
          width: 100%
        }
        label {
          display: inline-block;
          padding: 0 5px;
          color: $grey-text;
          font-size: 14px;
          font-weight: 400;
        }
        .error{
          color: $danger-red;
        }
      }
    }
  }
}

.sign-up-tos-info{
  .terms-of-service{
    width: 100%;
    height: 500px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 2px solid #ccc;
    border-radius: 8px;
    > div {
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      padding: 0 20px 20px;
    }
  }
  .agreement{
    color: $dusty-grey;
    margin: 0;
  }
}

.sign-up-payment-info{
  h4{
    margin-top: 25px;
    margin-bottom: 20px;
  }
  p{
    color: $dusty-grey;
    margin: 0;
  }
}

.sign-up-social-info{
  h4{
    margin-top: 25px;
    margin-bottom: 20px;
  }
  h3{
    color: $fun_blue;
    margin: 20px 0 10px 0;
    .below_limit{
      color: $danger-red;
    }
    .over_limit{
      color: $dark-green;
    }
  }
  p{
    color: $dusty-grey;
    margin: 0;
  }
  .external-auth{
    margin: 20px 0 0px 0;
    justify-content: left;
    a{
      margin: 0px 15px;
    }
  }
  .sign-up-follower-warning{
    display: inline-block;
    margin: 0 0 0px 0;
    color: $danger-red;
    border-radius: 5px;
  }
  .modal{
    display: block;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    .modal-dialog{
      top: 12%;
    }
    .instagram-body{
      P{
        color: black;
      }
      ul{
        list-style: none;
        input, label{
          height: 30px;
          display: inline-block;
          width: auto;
          margin: 0;
          vertical-align: middle;
        }
        input{
          margin-right: 15px;
        }
      }
    }
  }
}

.sign-up-footer{
  justify-self: flex-end;
  align-self: flex-end;
  margin: auto 0 0;
  .continue{
    margin-top: 20px;
    float: right;
  }
}

.sign-up-finish-info {
  h4 {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  p {
    color: $dusty-grey;
    margin: 0;
  }
  .sign-up-loading-spinner {
    .icon{
      font-size: 85pt;
      .danger {
        color:$danger-red;
      }
    }
  }
}

.accordion {
  min-width: 300px;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  .title {
    color: $grey-text;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    i {
      margin-left: 10px;
    }
    &:hover {
      color: #000;
    }
  }
  .body {
    padding: 0 20px;
    .body_content {
      overflow: hidden;
      font-size: 14px;
      max-height: 0;
      padding: 0;
      transition: all 0.4s ease-in-out;
      ul {
        padding: 0;
        list-style: disc;
        list-style-position: inside;
        li {
          margin-bottom: 15px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.open {
    .body {
      .body_content {
        max-height: 1000px;
        padding: 20px 0;
      }
    }
    i {
      transform: rotate(180deg);
    }
  }
}