$large-padding: 6.25em;

.welcome_body{
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-y: scroll;
  padding-top: 0;
  font-weight: inherit;
  font-size: $body-font-size;
  background: transparent;
  .contain{
    width: 100%;
    max-width: 1080px;
    padding: 0 2em;
    margin: 0 auto;
    @media (max-width: 350px){
      padding: 0 16px;
    }
  }
  h1,h2,h3,h4,h5{
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 300;
  }
  h1{
    padding: 0;
    font-size: em(52px);
  }
  h2{
    font-size: 2.5em;
  }
  h3{
    font-size: 1.625em;
    line-height: 1.4;
    margin: 0;
    color: #5C5F61;
  }
  img{
    display: block;
    max-width: 100%;
    height: auto;
  }
  h2 sup.trademark{
    top: -6px;
    font-size: 0.7em;
    margin-left: -4px;
    margin-right: -4px;
  }
  .alert-error, .alert-danger, .alert-error, .alert-alert, .alert-notice, .alert-info{
    margin-bottom: 0;
  }

  .header{
    padding-top: 0;
    padding-bottom: 2em;
    &.landing{
      position: relative;
      height: 550px;
      overflow: hidden;
      > .contain{
        padding-top: 10px;
        @media(max-width: 500px){
          padding: 1em;
        }
      }
      .logo{
        @media (max-width: 500px){
          float: left !important;
          padding-left: 0;
        }
      }
    }
    .image-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: url('sofab_welcome_header_bg.jpg');
      background-color: $sofabblue;
      background-position: center;
      background-size: cover;
    }
    .sub-header{
      color: white;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.68);
      margin: 0;
      &.h1{
        font-weight: 600;
        font-size: 5.25em;
        letter-spacing: .025em;
      }
      &.h2{
        font-weight: 400;
        font-size: 3.5em;
      }
      @media (max-width: 790px){
        &.h1{
          font-size: 3.5em;
        }
        &.h2{
          font-size: 2.5em;
        }
      }
      @media (max-width: 500px){
        &.h1{
          font-size: 3em;
        }
        &.h2{
          font-size: 2em;
        }
      }
    }
    .down-arrow{
      display: none;
    }
    @media (min-width: 650px) and (max-height: 650px){
      .down-arrow{
        display: block;
        .fas.fa-angle-down{
          margin-top: 80px;
          color: white;
          opacity: .3;
          text-shadow: 1px 1px 4px #000;
        }
      }
    }
    @media (max-width: 790px){
      &.landing{
        height: 460px;
      }
    }
    @media (max-width: 500px){
      &.landing{
        background: transparent;
        height: auto;
        padding: 0;
      }
    }
  }
}
//end welcomebody

//Buttons
.button{
  display: inline-block;
  padding: 1em 2em;
  color: #fff;
  background: transparent;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 14px;
  border-radius: $border-radius-base;
  transition: .3s ease background;
  vertical-align: middle;
  &:hover, &:focus, &:active{
    color: #fff;
  }
  &.blue{
    background: $denim;
    &:hover{
      background: darken($denim, 5%);
    }
  }
  &.clear{
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.73) inset;
    &:hover{
        background: rgba(255, 255, 255, 0.24);
    }
  }
  &.white{
    background: #fff;
    color: #333;
    &:hover{
      background: #eee;
    }
  }
  &.with-icon{
    padding: 1em 2.5em 0.875em 2.142857em;
  }
  i.fa, i.fas, i.fab, i.far{
    font-size: 11px;
    position: relative;
    left: 12px;
    &.fa-play-circle{
      font-size: 16px;
    }
  }
  + .button + .button{
    margin-left: 20px;
  }
}

button.button, input[type="submit"].button{
  border: none;
}
// end buttons

.nav-wrap{
  border-bottom: solid 2px rgba(148, 213, 0, 0.46);
  padding: 1.5em 0;
}

.header-nav{
  padding: 14px 0 0;
  a{
    color: white;
    padding: .875em 1em;
    font-size: em(14px);
    font-weight: 300;
    letter-spacing: 2px;
    transition: .3s ease background;
    border-radius: $border-radius-base;
    &:first-of-type{
      margin-right: 10px;
    }
    &:hover{
      background: rgba(white, .2);
    }
  }
}

.button.mobile{
  display:none;
}

.hero-intro{
  padding-top: 140px;
  text-align: center;
  @media (max-width: 790px){
    padding-top: 120px;
  }
  @media(max-width: 500px){
    padding: 2em 0;
    font-size: 15px;
    .contain{
      padding: 1em 0;
    }
    .sub-header{
      font-size: 1.5333em;
    }
  }
}

.z{
  padding: $large-padding 0;
  img{
    border-radius: 2px;
    width: 100%;
  }
  h2{
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 400;
    color: #0D0C0C;
    white-space: nowrap;
    @media(max-width: 750px){
      font-size: 2.25em;
    }
  }
  .contain{
    display: table;
    @media (max-width: 500px){
      display: block;
    }
  }
  .welcome--content-center{
    text-align: center;
  }
  .welcome--content-left{
    padding-left: 0;
    &.copy{
      padding-right: 20px;
      margin-bottom: 20px;
      @media (max-width: 675px){
        h3{
          margin-bottom: 20px;
        }
      }
    }
    img{
      float: left;
      padding-right: 30px;
    }
    .steps{
      margin-bottom: 10px;
      .group{
        margin-bottom: 10px;
      }
      .step{
        font-size: 14px;
        background: $sofabblue;
        color: #FFF;
        margin-right: 8px;
        margin-top: 4px;
        padding-top: 2px;
        border-radius: 50%;
        font-weight: 400;
        display: block;
        float: left;
        line-height: 1.5;
        height: 26px;
        width: 26px;
        text-align: center;
      }
      h3{
        margin-bottom: 0;
        font-size: 1.5625em;
        float: left;
        width: 90%;
        @media (max-width: 850px){
          width: 85%;
        }
      }
      @media(max-width: 675px){

      }
    }
  }
  .welcome--content-right{
    padding-right: 0;
    &.copy{
      padding-left: 30px;
      @media (max-width: 675px){
        h2{
          margin-top: 20px;
        }
      }
    }
    img{
      padding-left: 30px;
    }
  }
  .welcome--content-left, .welcome--content-right{
    width: 50%;
    display: table-cell;
    vertical-align: middle;
    @media (max-width: 870px){
      font-size: 14px;
      line-height: 1.2;
    }
    @media (max-width: 760px){
      font-size: 13px;
    }
    @media (max-width: 675px){
      width: 100%;
      display: block;
      padding: 0;
      margin: 0 auto;
      img{
        float: none;
        width: 100%;
        padding: 0;
        margin: auto;
      }
      &.copy{
        padding: 0;
      }
    }
    @media (max-width: 500px){
      display: block;
      width: 100%;
      float: none !important;
    }
  }
  &.z-middle{
    background: $lightgrey;
    h3{
      margin-bottom: 20px;
    }
  }
  &.z-last h3{
    margin-bottom: 20px;
  }
  @media (max-width: 675px){
    padding: calc($large-padding / 2) 0;
  }
  @media (max-width: 500px){
    padding: calc($large-padding / 2) 0;
    font-size: 13px;
  }
}

.testimonials{
  text-align: center;
  padding: 150px 0;
  background: url('sofab-testimonials-bg.jpg') no-repeat center center;
  background-size: cover;
  h2{
    color: #D7D7D7;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.92);
    margin-bottom: 1em;
    margin-top: 0;
    font-size: 3em;
  }
  @media(max-width: 900px){
    padding: 6.25em 0;
  }
  @media (max-width: 675px){
    padding: calc($large-padding / 2) 0;
    h2{
      font-size: 2em;
    }
  }
  @media (max-width: 500px){
    font-size: 14px;
    .testimonial{
      height: 140px;
    }
  }
}

.testimonial{
  width: 31%;
  margin-right: 20px;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  height: 180px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  text-align: left;
  padding: 1em;
  background: #FFF;
  border-radius: 2px;
  border-top-left-radius: 10px;
  text-indent: 40px;
  z-index: 8;
  .quote-wrap{
    display: table;
    height: 100%;
    margin-top: 20px;
  }
  q{
    display: table-cell;
    vertical-align: middle;
    line-height: 1.3;
    font-weight: 300;
  }
  &:last-of-type{
    margin-right: 0;
  }
  .testimonial-portrait{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: block;
    float: left;
    box-shadow: 0 0 0 2px #FFF;
    position: absolute;
    top: -10px;
    left: -5px;
    z-index: 3;
  }
  .name{
    border-top-right-radius: 2px;
    border-top-left-radius: 10px;
    position: absolute;
    top: 0;
    padding: 5px;
    left: 0;
    width: 100%;
    padding-left: 45px;
    background: #373434;
    color: #FFF;
    font-weight: 300;
    @media (max-width: 930px){
      padding-left: 32px;
    }
  }
  .blog_url{
    display: block;
    color: #C5C5C5;
    font-size: 13px;
    line-height: 1.25;
    font-weight: 400;
  }
  @media (max-width: 900px){
    height: 200px;
    .name{
      font-size: 0.875em;
    }
  }
  @media (max-width: 800px){
    display: block;
    width: 80%;
    margin: 40px auto;
    height: 140px;
    &:last-of-type{
      margin-right: auto;
    }
  }
  @media (max-width: 600px){
    width: 100%;
  }
  @media (max-width: 500px){
    height: 160px;
  }
}

.apply-now-banner{
  padding: calc($large-padding / 2);
  text-align: center;
  background: $sofabblue;
  h2{
    margin: 0;
    margin-right: 20px;
    color: white;
    font-size: 2.5em;
    display: inline;
    vertical-align: middle;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.63);
    @media (max-width: 895px){
      margin-bottom: 20px;
      display: block;
      font-size: 2em;
    }
    b{
      font-weight: 400;
    }
  }
  @media(max-width: 800px){
    padding: calc($large-padding / 2) 0;
  }
  @media(max-width: 550px){
    font-size: 13px;
    h2{
      text-shadow: none;
    }
  }
}

.footer{
  background: #fff;
  color: #b9b9b9;
  padding: 1.53846em;
  font-size: 0.8125em;
  img{
    display: inline;
    margin: 0 5px;
  }
  .cright{
    line-height: 47px;
  }
  .trademark_disclaimer{
    margin-top: 20px;
    font-size: 11px;
    text-align: center;
  }
  .privacy_policy_link{
    margin-left: 15px;
  }
  @media(max-width: 675px){
    padding: 2em 0;
  }
  @media(max-width: 500px){
    br.hide-mobile{
      display: none;
    }
    .pull-left, .pull-right{
      float: none !important;
      text-align: center;
    }
    .cright{
      margin-bottom: 10px;
      line-height: 20px;
    }
  }
}

@media(max-width: 660px){
  .button.mobile{
    display: inline-block;
  }
  .button.desktop{
    display: none;
  }
}
@media(max-width: 600px){
  .header-nav{
    text-align: center;
  }
}
@media(max-width: 500px){
  .welcome_body{
    .nav-wrap{
      border: none;
    }
    .header .logo{
      float: left !important;
      width: 50%;
    }
    .header .header-nav{
      float: right !important;
      text-align: right;
      width: 50%;
      padding-top: 5px;
    }
    .header .logo img{
      margin: 0;
    }
    .nav_apply_link{
      display: none;
    }
  }
}
@media(max-width: 350px){
  .welcome_body{
    font-size: 13px;
  }
}

// Login/PW Reset/Application page
.sessions_body, .password_resets_body, .member_applications_body, .user_sign_up_body{
  height: auto;
  padding-top: 0;
  .header{
    padding-bottom: 0;
    background: #585858;
    .nav-wrap{
      border-bottom: none;
    }
  }
  .not-member{
    margin-bottom: 50px;
    .apply-now{
      text-transform: uppercase;
      font-size: 1.1428571429em;
    }
  }
  form{
    width: 90%;
    margin: 0 auto;
    padding: 2em;
    background: #EDEDED;
    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.35);
    label.error{
      color: $error-red;
      position: relative;
    }
    label{
      color: $darkgray;
      font-weight: normal;
    }
    .email-input, .password-input, input[type='submit'] {
      margin: 0 auto 20px;
    }

    .email-input, .password-input{
      position: relative;
      input{
        padding-left: 50px;
      }
      .fa, .fas, .fab, .far{
        width: 40px;
        position: absolute;
        color: rgba(205, 194, 192, 0.48);
        top: 0;
        left: 0;
        border-right: solid 1px rgba(185, 179, 191, 0.14);
        padding: 0 10px;
        line-height: 42px;
      }
    }

    .forgot_password a{
      color: $--tc-default;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0.3rem 0;
      font-size: $--fs-regular;
    }

    input[type='submit'] {
      width: 100%;
      padding: 10px;
    }

    h3{
      margin-top: 0;
      text-align: center;
    }

    &#login-form, &#password_reset{
      max-width: 400px;
      margin: 65px auto 20px;
      padding: 3em;
      &#password_reset{
        padding: 2em;
        @media(max-width: 400px){
          font-size: 13px;
          padding: 1em;
        }
        @media(max-width: 320px){
          h2{
            font-size: 2em;
          }
          p{
            font-size: 16px;
          }
        }
      }
      h2{
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
      }
      hr{
        border-color: #6B6A6A;
      }
    }
  }
  .alert-error, .alert-danger, .alert-error, .alert-alert, .alert-notice, .alert-info{
    position: absolute;
    top: 98px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 10000;
  }
}

// Member Application form styles

#new_member_application{
  padding: 0 15px;
  max-width: 800px;
  margin: 30px auto 50px;
  @media(max-width: 400px){
    padding: 1em;
  }

  // headings
  h2{
    margin-bottom: 20px;
  }
  h4{
    @media (max-width: 500px){
      font-size: 16px;
    }
  }
  //----

  // form elements
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #c0c0c0;
  }
  ::-moz-placeholder {  /* Firefox 19+ */
    color: #c0c0c0;
  }
  :-ms-input-placeholder {
    color: #c0c0c0;
  }

  .select2-container.select.required.form-control.error a{
    border: solid 1px lighten($error-red, 20%);
  }
  .select2-container .select2-choice{
    border: none;
  }
  .select2-dropdown-open .select2-choice {
    border: 1px solid #5897fb;
  }

  input:not([type="checkbox"]), textarea{
    margin: 0;
    padding: 6px 10px;
    border-radius: $border-radius-base;
    border: none;
    box-shadow: none;
    &.error{
      box-shadow: 0 0 0 1px lighten($error-red, 20%);
    }
  }
  textarea{
    margin-bottom: 15px;
  }
  label.error{
    font-size: 13px;
    top: 0;
    margin: 0;
    display:block;
  }
  label:not(.error){
    color: #5C5F61;
    margin-bottom: 2px;
  }
  hr{
    border-color: rgba($silver, 0.5);
    margin: 5px 0 10px;
  }
  .member-app--platform-checkbox{
    width: 20px;
    height: 13px;
    display: inline-block;
    margin: 0;
    &:focus, &:active{
      outline: 0;
      box-shadow: none;
    }
  }
    // wrapper, label, input
  .form-group{
    @include make-xs-column(12);
    @include make-sm-column(6);
    min-height: 76px;
    margin-bottom: 0;
    abbr{
      cursor: default;
      border: none;
    }
  }
  .full .form-group{
    width: 100%;
  }
  // ----

  // membership requirements list
  ul{
    margin-bottom: 20px;
    font-size: 13px;
    li{
      padding: 3px;
    }
    @media(max-width: 500px){
      padding: 1em 0;
    }
  }
  // ----

  // network selection button group
  .btn-group{ // need better class name
    margin: 5px 0;
    @media(max-width: $screen-xs-max){
      .btn {
        width: 33.3333%;
      }
    }
    @media(max-width: $screen-xs-min){
      .btn{
        width: 100%;
      }
    }
  }
  label.btn{
    position: relative;
    color: $light-black;
    .social-icon--blog{
      color: $supernova;
    }
    .sofab-social-network-icons--icon{
      opacity: 0.6;
    }
    .social-icon--youtube{
      color: $youtube-color;
    }
    .social-icon--instagram{
      color: $instagram-color;
    }
    .social-icon--pinterest{
      color: $pinterest-color;
    }
    .social-icon--twitter{
      color: $twitter-color;
    }
    .social-icon--vine{
      color: $vine-color;
    }
  }

  // submit button group
  .btns{
    margin: 10px 0 20px;
    .btn.disabled{
      text-shadow: none;
      background: #DFDFDF;
      border: none;
    }
  }
  // ------
}

// error message when email already exists
.email-error{
  position: absolute;
  top: 600px;
  left: 50%;
  width: 250px;
  padding: 10px;
  z-index: 1;
  background: $error-red;
  color: #FFF;
  @media (max-width: 700px){
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 2em;
  }
}
// ----------
