.communities--show {
  .redactor-box {
    margin-bottom: 0;
    
    .redactor-toolbar {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $--fc-gray;
    }

    .redactor-editor {
      border: none;
    }
  }
}
