.admin_community_instruction_notes_body {
    .form {
        .input_wrap {
            margin: 15px 0;

            label {
                display: inline-block;
                color: $grey-text;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}