.well.tier_inputs{
  padding: 10px;
  .form-group{
    margin-bottom: 0;
  }
}

.Green_tier{
  background: rgba( green, 0.2);
}

.Bronze_tier{
  background: rgba( #A86A39, 0.2);
}

.Silver_tier{
  background: rgba( silver, 0.2);
}

.Gold_tier{
  background: rgba( gold, 0.2);
}