/* Card Styles
----------------------------------- */
.card {
  position: relative;
  margin:0 0 $--ls-regular;
  padding: $--ls-large $--ls-x-large;
  background: $--sc-white;
  border: 1px solid #DDDDDD;
  border-radius: $--br-default;
  &[disabled] {
    cursor: not-allowed;
    background-color: $--fc-sub-gray;
    > * {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }
}

.card.popup {
  z-index: 999;
  @media(min-width: $--bp-small){
    position: absolute;
    top: 50px;
    overflow: hidden;
  }
}

.card.clickable, .card-plus.clickable{
  background: $--sc-white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  border-radius: $--br-default;
  cursor: pointer;
  transition: all 300ms ease;
  &[disabled] {
    cursor: not-allowed;
    background-color: $--fc-sub-gray;
    > * {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }

  &:hover, &:active, &:focus{
    background: $--sc-white;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
    border-radius: $--br-default;
    transition: all 300ms ease;
  }
}

.card-plus {
  position: relative;
  background: $--sc-white;
  border: 1px solid #DDDDDD;
  border-radius: $--br-default;
  margin: 0 0 $--ls-regular;
  &[disabled] {
    cursor: not-allowed;
    background-color: $--fc-sub-gray;
    > * {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }
  .card-header, .card-footer{
    padding: $--ls-regular $--ls-x-large;
    font-weight: $--fw-bold;
    background-color: $--fc-sub-gray;
    border-bottom: 1px solid #DDDDDD;
  }
  .card-header{
    border-radius: $--br-default $--br-default 0 0;
  }
  .card-footer{
    border-radius: 0 0 $--br-default $--br-default;
  }
  .card-body{
    background: $--sc-white;
    padding: $--ls-large $--ls-x-large;
    border-radius: $--br-default;
  }
  .card-hide-link{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.card-plus.multi{
  margin: 0 180px 16px;
}

.card.clickable, .card-plus.clickable{
  display: block;
  background: $--sc-white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  border-radius: $--br-default;
  cursor: pointer;
  transition: all 300ms ease;
  &[disabled] {
    cursor: not-allowed;
    background-color: $--fc-sub-gray;
    > * {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }

  &:hover, &:active, &:focus{
    background: $--sc-white;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
    border-radius: $--br-default;
    transition: all 300ms ease;
  }
}

// OLD
// use this to build a card wrapper - place between the container and row
.card-wrapper--white-bg{
  padding: 15px;
  border-radius: $border-radius-base;
  background: white;
  box-shadow: 0 0 0 1px rgba(225, 231, 234, 0.45);
  position: relative;
}

.image-editor-card {
  width: 100%;
  margin: 25px 0 25px 0;
  background-color: $--sc-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
  border-bottom-left-radius: $--br-default;
  border-bottom-right-radius: $--br-default;
  transition: all 300ms ease;
  position: relative;
  hr {
    margin: 0px 5px 2px 5px;
  }
  img {
    width: 100%;
    height: auto;
    background-color: $--fc-sub-gray;
  }
  .aspect-calculation {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    border: 2px $--sc-red solid;
    display: flex;
    flex-direction: column;
    background-color: $--sc-white;
    align-items: center;
    transition: all 300ms ease;
    z-index: 1;
    font-weight: bold;
    color: $--sc-red;
    opacity: 0;
  }
  .aspect-width {
    position: absolute;
    top: -22px;
    left: 0;
    border: 2px $--sc-red dashed;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease;
    z-index: 1;
    font-weight: bold;
    color: $--sc-red;
    opacity: 0;
  }
  .aspect-height {
    position: absolute;
    top: -22px;
    right: -22px;
    border: 2px $--sc-red dashed;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    transition: all 300ms ease;
    z-index: 1;
    font-weight: bold;
    color: $--sc-red;
    opacity: 0;
  }
  .editor-aspect-suggestion {
    padding: 5px;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
    .notice-icon {
      margin-right: 5px;
      background-color: $--sc-blue;
      color: $--sc-white;
      padding: 5px;
      border-radius: 50%;
      border: 1px transparent solid;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .editor-toolbar {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    :first-child{
      margin-right: auto;
    }
    button.editor-toolbar-item {
      border-radius: 50%;
      border: 1px transparent solid;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      color: $--sc-blue;
      background: $--sc-white;
      padding: 5px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        margin: 0;
      }
    }
    .square {
      border-radius: 20px !important;
    }
  }
}
