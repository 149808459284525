

html {
  background: $--sc-background;
  font-family: $--ft-default !important;
  font-size: $--fs-regular;
  font-weight: $--fw-regular;
  box-sizing: border-box;
  color: $--tc-default;
}

body {
  margin: 0;
  overflow: auto;
  padding: 0.05px 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  font-weight: $--fw-regular;
  font-size: $--fs-regular;
  font-family: inherit;
  &.no-scroll{
    // hack to keep scrollbar there but remove scrolling
    position: fixed;
    width: 100%;
    overflow-y: scroll;
  }
  &.modal-open .affix{
    // hack to keep things fixed with affix from shifting on modal open
    width: 98.9%;
  }
}

/* Typography
----------------------------------- */
a, .a {
  color: $--lc-link;
  text-decoration: none;
  &:hover{
    color: $link-hover-color;
  }
}

h1, .h1 {
  font-size: $--fs-xx-large;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
h2, .h2 {
  font-size: $--fs-x-large;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
h3, .h3 {
  font-size: $--fs-large;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
h4, .h4 {
  font-size: $--fs-regular;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
h5, .h5 {
  font-size: $--fs-small;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
h6, .h6 {
  font-size: $--fs-x-small;
  font-weight: normal;
  margin: 0 0 4px 0;
  line-height: normal;
}
p, .p {
  font-size: $--fs-regular;
  font-weight: normal;
  margin: 0 0 8px 0
}
hr {
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, .1);
}

/* Text Sizes
----------------------------------- */
.text-size-xxx-large {
  font-size: $--fs-xxx-large;
}
.text-size-xx-large {
  font-size: $--fs-xx-large;
}
.text-size-x-large {
  font-size: $--fs-x-large;
}
.text-size-large {
  font-size: $--fs-large;
}
.text-size-regular {
  font-size: $--fs-regular;
}
.text-size-small {
  font-size: $--fs-small;
}
.text-size-x-small {
  font-size: $--fs-x-small;
}

/* Text Formats
----------------------------------- */
.text-format-downcase {
  text-transform: none;
}

.text-format-upcase {
  text-transform: uppercase;
}

.text-break-word {
  word-wrap: break-word;
}

/* Text Weights
----------------------------------- */
.text-weight-light {
  font-weight: $--fw-light;
}
.text-weight-regular {
  font-weight: $--fw-regular;
}
.text-weight-bold {
  font-weight: $--fw-bold;
}
.text-weight-black {
  font-weight: $--fw-black;
}

/* Text Colors
----------------------------------- */
.text-color-default {
  color: $--sc-gray;
}
.text-color-subdued {
  color: $--sc-sub-gray;
}
.text-color-blue {
  color: $--sc-blue;
}
.text-color-purple {
  color: $--sc-purple;
}
.text-color-teal {
  color: $--sc-teal;
}
.text-color-green, .cb-green  {
  color: $--sc-green;
}
.text-color-red {
  color: $--sc-red;
}
.text-color-orange {
  color: $--sc-orange;
}
.text-color-white {
  color: $--sc-white;
}

/* Background Colors
----------------------------------- */
//Solid Colors
.background-color-body {
  background-color: $--sc-background;
}
.background-color-gray {
  background-color: $--sc-gray;
}
.background-color-subdued {
  background-color: $--sc-sub-gray;
}
.background-color-blue {
  background-color: $--sc-blue;
}
.background-color-purple {
  background-color: $--sc-purple;
}
.background-color-teal {
  background-color: $--sc-teal;
}
.background-color-green {
  background-color: $--sc-green;
}
.background-color-red {
  background-color: $--sc-red;
}
.background-color-orange {
  background-color: $--sc-orange;
}
.background-color-white {
  background-color: $--sc-white;
}
.background-color-black {
  background-color: $--sc-black;
}
.background-color-link {
  //NOTE: FOR STYLE GUIDE ONLY
  background-color: $--sc-link;
}

//Faded Colors
.background-color-faded-gray {
  background-color: $--fc-gray;
}
.background-color-faded-subdued {
  background-color: $--fc-sub-gray;
}
.background-color-faded-blue {
  background-color: $--fc-blue;
}
.background-color-faded-purple {
  background-color: $--fc-purple;
}
.background-color-faded-teal {
  background-color: $--fc-teal;
}
.background-color-faded-green {
  background-color: $--fc-green;
}
.background-color-faded-red {
  background-color: $--fc-red;
}
.background-color-faded-orange {
  background-color: $--fc-orange;
}
.background-color-faded-body {
  background-color: $--fc-background;
}

//Gradient Colors
.background-grad-blue-purple {
  background: $--gradient-blue-purple;
}
.background-grad-green-teal {
  background: $--gradient-green-teal;
}
.background-grad-orange-red {
  background: $--gradient-orange-red;
}
.background-grad-gray {
  background: $--gradient-grey;
}

/* Layout helpers
----------------------------------- */
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

/* Layout styles
----------------------------------- */

// .container {
//   max-width: $--bp-x-large;
//   margin: 48px auto;
//   padding: 0 16px;
//   position: relative;
// }
// .container.full-width {
//   max-width:none;
//   width:100vw;
//   margin: 0;
// }

/* Content Layout Grid
----------------------------------- */

// .row {
//   width: 100%;
//   display: table;
//   table-layout: fixed;
// }
// .col {
//   display: table-cell;
// }









// OLD

// Height utilities

.vh-100 {
  height: 100vh;
}

.vh-75 {
  height: 75vh;
}

.vh-50 {
  height: 50vh;
}

.vh-25 {
  height: 25vh;
}

// Utility classes for adding and removing margin
.mg-0{
  margin: 0;
}

.mg-10 {
  margin: 10px;
}

.mt-0{
  margin-top: 0;
}

.mt-10{
  margin-top: 10px;
}

.mb-0, h1.mb-0, h2.mb-0, h3.mb-0, h4.mb-0, h5.mb-0, h6.mb-0{
  margin-bottom: 0;
}

.mb-5{
  margin-bottom: 5px;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-15{
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-10{
  margin-left: 10px;
}

.ml-15{
  margin-left: 15px;
}

.ml-30{
  margin-left: 30px;
}

.mr-0{
  margin-right: 0;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10{
  margin-right: 10px;
}

.mr-15{
  margin-right: 15px;
}

.pd-10{
  padding: 10px;
}
.pd-30{
  padding: 30px;
}

.pd-10-30{
  padding: 10px 30px;
}
.pl-0{
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pd-0{
  padding: 0;
}

.pr-0{
  padding-right: 0;
}
.pt-5{
  padding-top: 5px;
}

.pb-10{
  padding-bottom: 10px;
}

.not-last-of-type:last-of-type{
  display: none;
}

.ws_no_wrap{
  white-space: nowrap
}

.d-inline{
  display: inline;
}

.d-block{
  display: block;
}

.text-left-sm{
  @media(max-width: $screen-sm-min){
    text-align: left;
  }
}

.text-center-sm{
  @media(max-width: $screen-sm-min){
    text-align: center;
  }
}

pre.wrap_text{
  overflow: visible;
  white-space: pre-line;
  word-break: break-word;
}

.container{
  max-width:$container-lg;
  width:90%;
  @media(max-width: 1100px){
    width: 96%;
  }
}

.flex-container {
  flex: 1;
  width: 100%;
}

.main-container{
  min-height: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
  &.no-padding {
    padding: 0
  }
  &.full-width {
    width: 100%;
    max-width: none;
  }
}

.admin_layout {
  .container{
    max-width: 100%;
    width: 100%;
    padding-left: $small-screen-gutter;
    padding-right: $small-screen-gutter;
    @media(min-width: $sidebar-breakpoint-large){
      padding-left: $large-screen-gutter;
      padding-right: $large-screen-gutter;
    }
  }
  &:not(.admin_shop_posting_body){
    .main-container{
      background-color: $blue-grey;
    }
  }
}

a:hover, a:focus, a:active {
  text-decoration: none;
}

a[disabled], button.disabled, .btn.disabled {
  pointer-events: none;
}

.clear{
  clear:both;
}

// !! DO NOT DELETE - This is a JS dependent class !!
// ==================
.is-hidden{
  display: none;
}
// ==================

.no-list-style{
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.t-transform-none{
  text-transform: none !important
}

.breadcrumb{
  margin-bottom:20px;
}

h1.page-title{
  margin-top:0px;
  font-size:30px;
}

@media screen and (min-width:768px) {
  .row-flex {
    display: flex;
  }

  .vbottom {
      align-self: flex-end;
  }
}


.tooltip-container .btn[disabled] {
  pointer-events: none;
}

// use this to add overlay that covers screen
.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background: rgba(black, 0.3);
  transition: .2s ease opacity;
  opacity: 0;
  z-index: 1040;
  &.in{
    transform: translateY(0);
    opacity: 1;
  }
}

.loading-spinner{
  position: relative;
  &:after{
    display: inline-block;
    text-align: center;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f110" !important;
    color: $true-blue;
    opacity: 0.8;
    font-size: 100px;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 15%;
    animation: fa-spin 2s infinite linear;
  }
  > div{
    opacity: 0.3;
  }
}
.modal-loading-spinner{
  display: flex;
  flex: 1 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  .icon, .text {
    font-size: 36pt;
    text-align: center;
    color:$dark-grey;
  }
  .success {
    color: $light-green;
  }
  .error {
    color: $error_red;
  }
}

.admin_earned_media_lists_body{
  .full-width-page-title--wrapper{
    text-align: center;
  }
}

// hacky solution, need better - JC
.admin_campaigns_body[data-action="edit"],
.admin_campaigns_body[data-action="update"],
.admin_campaigns_body[data-action="create"],
.admin_shoppertunities_body[data-action="edit"],
.admin_shoppertunities_body[data-action="update"],
.admin_shoppertunities_body[data-action="create"],
.admin_shop_posting_body[data-action="edit"],
.admin_questions_body[data-action="new"],
.admin_questions_body[data-action="edit"],
.admin_rounds_body[data-action="new"],
.admin_rounds_body[data-action="create"],
.admin_instructions_body[data-action="edit"],
.admin_contests_body[data-action="new"],
.admin_contests_body[data-action="edit"],
.admin_twitter_parties_body[data-action="edit"],
.admin_instagram_highreach_summaries_body[data-action="new"],
.admin_instagram_highreach_summaries_body[data-action="edit"],
.admin_contests_body[data-action="edit"],
.admin_twitter_parties_body[data-action="edit"]{
  .full-width-page-title--wrapper{
    text-align: left;
  }
}

// Popover overrides
.popover{
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  border: none;
  a{
    font-weight: $semi-bold;
  }
}

.popover-title{
  color: white;
  border: none;
}
// end popovers

.nav > li > a{
  outline:0; /* prevents dotted border on selected elements in FireFox */
}

.alert-white{
  background: white;
  border: solid 1px rgba(153, 153, 153, 0.24);
}

.alert-no-radius{
  border-radius: 0;
}

.alert-narrow{
  padding: 4px 8px;
}

.alert ul { list-style-type: none }

.no-top {
  top: 0;
}

label.error {
  color:$error-red;
  font-weight:normal;
}

.system-alert {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding-right: 15px;
  padding-left: 15px;
}

.alert-notice {
  @extend .alert-success;
}

.alert-error, .alert-alert {
  @extend .alert-danger;
}

.icon-bar {
  background: #ccc;
}
.nav-tabs{
  border-bottom:none;
}

.alert.alert-sm {
  padding: 6px;
  margin-bottom: 15px;
  font-size: 13px;
}

ul.nav.nav-pills.pull-right {
  top: -35px;
}

div.ms-select-header {
  text-align: center;
  font-weight: $bold;
}

.btn-toolbar {
  margin-bottom: 8px;
}

.thumbnail .caption, .description {
  background: #666;
  color: #FFF;
}
.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.thumbnail {
  min-height: 220px;
  text-align: center;
  position: relative;
  overflow: hidden;
  img {
    max-height: 70px;
  }
}

.description {
  position: absolute;
  top: 0%;
  display:none;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 2%;
  text-align: center;
  z-index: 2;
}
.thumbnail:hover .description {
  //top: 0%;
}

.pagination {
  margin: 15px 0 6px 0;
}

/* Breadcrumb styles*/
ol.breadcrumb{
  background-color:transparent;
  border-radius:0;
  padding: 0 15px 0 15px;
  width:100%;
}
.breadcrumb li{
  vertical-align: middle;
}
.breadcrumb li.active,
.breadcrumb > li + li:before{
  color:$grey-text;
}


.back-link{
  margin-top:20px;
}

//Pulled out of the welcome css file - (Josh 4.20.15)
.ms-container{
  background: transparent url('switch.png') no-repeat 50% 50%;
  width: 100%;
}

#editor, .editor {
  height: 250px;
  background-color: white;
  border-collapse: separate;
  border: 1px solid rgb(204, 204, 204);
  padding: 4px;
  margin-bottom: 15px;
  box-sizing: content-box;
  box-shadow: rgba(0, 0, 0, 0.0745098) 0 1px 1px 0 inset;
  border-top-right-radius: 3px; border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; border-top-left-radius: 3px;
  overflow: scroll;
  outline: none;
  resize: vertical;
  &.large {
    max-height: 405px;
    height: 405px;
  }
  &.comments{
    height: 100px;
  }
  &.new-post,&.edit-post{
    min-height: 400px;
  }
}

#voiceBtn {
  width: 20px;
  color: transparent;
  background-color: transparent;
  transform: scale(2.0, 2.0);
  -webkit-transform: scale(2.0, 2.0);
  -moz-transform: scale(2.0, 2.0);
  border: transparent;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.panel.panel-success.welcome-email {
  height: 520px;
}

div[data-role="editor-toolbar"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// acceptance_rules
.acceptance_rule ul, #review ul, ul#acceptance_rules {
  list-style-type: none;
}

.acceptance_rule .info {
  border: 1px solid #ccc;
}

#new-note {
  display: none;
}

#post .pull-right, .comments .pull-right {
  color: #ccc;
}

.smaller {
  font-size: 14px;
}

ul#acceptance_rules > li {
 cursor: pointer;
}

.ms-list {
  max-height: 100px;
}

img.shop_app_pic {
  height: 60px;
  width: 60px;
}



.right-margin {
  margin-right: 34px;
}

.home-column {
  display: inline-block;
  width: 30%;
}

.dropdown-menu a {
  cursor: pointer;
}


* {
  margin: 0;
}
/*
Globals
*/

#main{
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}

.alert_area{
  border-radius: $border-radius-base;
  list-style:none;
  background-color:#2a3a43;
  color: white;
  margin: 5px 0 10px;
  font-size: 16px;
  width: 100%;

  i.fa, i.fas, i.fab, i.far{
    display: block;
    border-radius: $border-radius-base 0 0 $border-radius-base;
    float:left;
    width: 70px;
    height: 70px;
    background: $red;

    &.warning{
      background: $orange-you-glad;
    }
  }
  .alert_text{
    padding:10px 0 10px 10px;
    display: block;
    float:left;
    li + li{
      font-weight: 100;
    }
  }
  .alert_action{
    display: block;
    float:right;
    line-height: 70px;
  }
  .btn{
    margin-right: 18px;
  }
}







#admin-search {
  width: 300px;
}


.bg-caution{
  padding: 5px;
  border: solid 1px darken($caution-yellow, 10%);
  background: $caution-yellow;
}

.well{
  box-shadow: none;
}

/** transitions/animations **/
.fade-transition {
  transition: all 1s ease-out;
  opacity: 0;
}

.fade-transition.show {
  opacity: 1;
}

/*
Navigation
*/

/* main secondary navigation */

ul.nav.nav-pills{
  display:inline-block;
  margin:0px;
  padding:0px;
  li{
    margin-bottom:-5px;
    min-width:95px;
  }
  li a{
    border-radius:0px;
    color:#FFFFFF;
    padding: 4px 10px;
    position:relative;
  }

}
/* sub secondary navigation */
.tab-content{
  ul.nav.nav-pills{
    display:inline-block;
    z-index:2;
    li a{
      color:$grey-text;
    }
    li.active a{
      color:#FFFFFF;
      background-color:$grey-text;
    }
  }
}
#shop_sub_navigation{
  ul.nav.nav-pills{
    li.active a{
      color:$grey-text;
      background-color:$form-grey;
    }
  }
}


/* Caret */
.caret{
  color:$caret-color;
}

/*
Labels
*/
.label-info{
  background-color:$light-green;
}

/*
Panel Headings
*/

.alert-dismissable .close{
  right:0;
}
.panel-warning > .panel-heading{
  background-color:#fff;
  color:$warning-yellow;
  border:2px solid $warning-yellow;
  background: url('panel-heading-warning.png') no-repeat;
  background-size:contain;
  border-radius:0;
  padding-left:60px
}
.panel-success > .panel-heading{
  background-color:#fff;
  color:$light-green;
  border:2px solid $light-green;
  background: url('panel-heading-success.png') no-repeat;
  background-size:contain;
  border-radius:0;
  padding-left:60px
}
.panel-danger > .panel-heading{
  background-color:#fff;
  color:$danger-red;
  border:2px solid $danger-red;
  background: url('panel-heading-danger.png') no-repeat;
  background-size:contain;
  border-radius:0;
  padding-left:60px
}

.panel{
  box-shadow: none;
  border-color: $light-grey;
}

.panel-heading{
  font-weight: $bold;
  border-color: $light-grey;
  font-size: 18px;
  background-color: $light-grey;
  padding: 1px 15px;
  .panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}


/*
Wysiwyg buttons
*/

/* Fix for tooltips causing buttons to shift on hover */
.btn-group > .tooltip + .btn,
.btn-group > .popover + .btn{
  margin-left:-1px;
}

.btn-toolbar .btn{
  box-shadow:none;
  background:#ECECEC;
  @include opacity(.7);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.89);
  &.btn-default{
    border-color:#ccc;
  }
  &:hover{
    @include opacity(1);
  }
  &.move{
    margin-left:-1px;
  }
}

.add-hyper-link{
  input{
    margin:10px;
  }
  button{
    margin: 0 auto;
    width: 60px;
    display: block;
  }
}
.add-image-link{
  input{
    margin:10px;
  }
  button{
    margin: 0 auto;
    width: 60px;
    display: block;
  }
}

/*
Profile Nav
*/
#profile_nav{
  font-size:.9em;
  background-color:$light-grey;
  a{
    color:#000;
  }
  .nav li a{
    padding:10px 0px;
    font-size:16px;
    border:none;
    border-radius:0;
    border-right:1px solid #ddd;
    border-top:1px solid #ddd;

  }
  .nav li:last-child a{
    border-right:none;
  }
  .nav li:nth-child(4) a{
    border-right:none;
  }
  .nav-tabs > li {
    margin-bottom:0px;
    width:25%;
    text-align:center;

  }
  .nav-tabs > li > a {
    margin:0px;

  }
  .nav-tabs li a:hover{
    background-color: darken($light-grey, 10%);
    // background-color:$light-green;
    // color:#fff;
  }
  .nav-tabs li.has-missing a:hover {
    background-color: darken($light-purple, 5%);
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color:$light-green;
    color:#fff;
  }

}
#contact {
margin-top: 20px;
}

// Sortable
.sortable {
  li {
    cursor: move;
  }
}

.select-user-search {
  img {
    height: 20px;
    width: 20px;
    margin-right: 3px;
  }
}

.full-width-page-title--wrapper{
  padding-top: 15px;
  padding-bottom: 15px;
  background: $--gradient-blue-purple;
  .btn-primary:hover:not([disabled]){
    background: lighten($btn-primary-bg, 7%);
  }
  .btn-default{
    border-color: $btn-default-bg;
  }
}

.full-width-page-title--heading{
  color: white;
  font-weight: $light;
  font-size: 24px;
  line-height: 34px;
  margin: 0;
  small{
    color: $light-grey;
  }
}

.admin_layout{
  .full-width-page-title--wrapper{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .full-width-page-title--heading{
    font-size: 22px;
    line-height: 26px;
  }
}

.sofab-tabs--btn-group {
  .sofab-tabs--btn {
    background: $denim;
    border: 1px solid $navbar-blue;
    border-radius: 4px;
    box-shadow: none;
    color: #ffffff;
    text-shadow: $global-text-shadow;
    &.active {
      background: $brand-primary;
      border-color: $navbar-blue;
      position: relative;
      &:before{
        display: inline-block;
        width: 0;
        height: 0;
        border-top:   $caret-width-base solid;
        border-right: $caret-width-base solid transparent;
        border-left:  $caret-width-base solid transparent;
        position:absolute;
        bottom: -5px;
        color: $brand-primary;
        left: 50%;
        margin-left: -4.6px;
        content: "";
      }
    }
    &:hover, &:focus, &:active{
      background: $brand-primary;
      border-color: $navbar-blue;
      color: white;
    }
    @media (max-width: $screen-xs-min){
      padding: 5px;
      font-size: 13px;
    }
  }
  @media (max-width: $screen-xs-max){
    margin-top: 10px;
  }
}

.sofab-tabs--count {
  background:#ffffff;
  margin-left:5px;
  padding:0 3px;
  border-radius:2px;
  color: $navbar-blue;
  font-size:0.8em;
  text-shadow: none;
}

// Social icons
// .card-platform-icon{
//   font-style: 'normal';
// }
.social-icon--facebook,
.social-icon--facebook_page,
.social-icon--facebook_advertiser{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f39e";
  }
  &.facebook-color,
  &.facebook_page-color,
  &.facebook_advertiser-color{
    color: $facebook-color;
  }
}

.social-icon--blog{
  font-family: "Font Awesome 5 Free";
  &:before{
    content: "\f09e";
  }
  &.blog-color{
    color: rgba(black, 0.5);
  }
}

.social-icon--twitter{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f099";
  }
  &.twitter-color{
    color: $twitter-color;
  }
}

.social-icon--linkedin{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f08c";
  }
  &.linkedin-color{
    color: $linkedin-color;
  }
}

.social-icon--youtube{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f167";
  }
  &.youtube-color{
    color: $youtube-color;
  }
}

.social-icon--pinterest{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f231";
  }
  &.pinterest-color{
    color: $pinterest-color;
  }
}

.social-icon--instagram, .social-icon--facebook_instagram_business{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f16d";
  }
  &.instagram-color{
    color: $instagram-color;
  }
}

.social-icon--vine{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f1ca";
  }
  &.vine-color{
    color: $vine-color;
  }
}

.social-icon--tumblr{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f174";
  }
  &.tumblr-color{
    color: $tumblr-color;
  }
}

.social-icon--google_oauth2{
  font-family: "Font Awesome 5 Brands";
  &:before{
    content: "\f0d4";
  }
  &.google_oauth2-color{
    color: $google-plus-color;
  }
}

.social-icon-list--icon{
  font-size: 36px;
  margin: 0 5px 0 0;
  color: $silver-2;
}

// Icons
.fab.fa-color,
.fas.fa-color {
  &.fa-rss { color: #FFC90E; }
  &.fa-facebook { color: #3D5B96; }
  &.fa-twitter { color: #3399CC; }
  &.fa-pinterest { color: #CC1E2D; }
  &.fa-google-plus { color: #DD3F34; }
}

.icon--add{
  color:$brand-primary;
  &:hover, &:active, &:focus{
    color: darken($brand-primary, 5%);
  }
}

.icon--remove, .icon--delete{
  color:$red;
  &:hover, &:active, &:focus{
    color: darken($red, 5%);
  }
}

// question icon tooltips
.question_tooltip .fa{
  color: #bfbfbf;
}

.tooltip-inner{
  padding: 8px 12px 10px;
  background-color: #383838;
  font-family: $font-family-sans-serif;
  font-size: 12px;
  line-height: 1.45;
  text-align: left;
  a{
    color: rgba(75, 160, 234, 0.86);
    &:hover{
      color: rgba(75, 160, 234, 1);
      text-decoration: underline;
    }
  }
}

$facebook-color: #4267B2;
$youtube-color: #ff0000;
$login--social-icon-size: 20px;

#login-form,
.new-member-actions,
.sign-up-social-info{
  .external-login,
  .external-auth{
    @media(min-width: $screen-sm-min){
      display: flex;
      justify-content: space-evenly;
    }

    a.btn.btn-social{
      background: #ffffff;
      color: #333333;
      display: flex;
      justify-content: center;
      margin-left: 0;
      margin-bottom: 15px;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.35);
      &:hover{
        background: darken(#ffffff, 4%);
      }
      &:active{
        background: darken(#ffffff, 10%);
      }

      .fa, .fas, .fab, .far{
        padding-right: 6px;
        line-height: $login--social-icon-size;
        &:before{
          font-size: $login--social-icon-size;
        }
      }

      .fa-facebook:before{
        color: $facebook-color;
        font-size: 18px;
      }

      .fa-youtube:before{
        color: $youtube-color;
      }

      .fa-pinterest-p:before{
        color: $pinterest-color;
      }
      .fa-twitter:before{
        color: $twitter-color;
      }
    }
  }
}

// fix redactor close button
#redactor-modal {
  .ui-button-icon-only {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.position-relative {
  position: relative
}

.opacity-65 {
  opacity: 0.65 !important;
}
