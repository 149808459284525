.basic-info-container {
  .profile-photo-card {
    .profile-photo {
      border-radius: 50%;
      border: 1px solid #eee;
      min-width: 125px;
      min-height: 125px;
      padding: 25% 0 0 0;
      width: 25%;
      margin: 0 auto 20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .btn {
      width: 100%
    }
  }
  .basic-info-form-card {
    .bio-limit {
      float: right;
      margin: 10px;
      &.maxed {
        color: $--sc-red;
      }
    }

    textarea + .help-text {
      float: left;
      margin-top: 10px;
    }
  }
}
