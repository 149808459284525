.profile-public-info-container {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
  .basic-info {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 10px;
    .profile-photo {
      border-radius: 50%;
      border: 1px solid #eee;
      min-width: 125px;
      min-height: 125px;
      padding: 10%;
      margin: auto 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .about {
      padding: 20px 0 20px 20px;
      word-break: break-word;
      color: $--tc-default;
      h1 {
        margin-bottom: 15px;
      }
    }
  }
  .dates {
    display: flex;
    flex-flow: column;
    > span {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      font-size: $--fs-regular;
      color: $--tc-light;
      padding: 10px 0;
      i {
        font-size: $--fs-x-large;
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: $--bp-small){
  .profile-public-info-container {
    .basic-info {
      flex-flow: row wrap;
      .profile-photo {
        margin: auto;
      }
      .about {
        padding: 15px 0 0 0;
        h1{
          text-align: center;
        }
        width: 100%;
      }
    }
  }
}
