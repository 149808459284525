.no-messages-text{
  margin: 10px;
  padding-bottom: 8px;
}

.conversation-loading-spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.conversation-card-plus{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
  @media (min-width: $screen-sm-max) {
    position: relative;
    background: $--sc-white;
    border: 1px solid #DDDDDD;
    border-radius: $--br-default;
    margin: 0 0 $--ls-regular;
  }
  @media (max-width: $screen-md-min) {
    position: relative;
    background: $--sc-white;
    border: 1px solid #DDDDDD;
    border-radius: 0px;
    margin: 0;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: -20px !important;
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: $--fc-sub-gray;
    > * {
      cursor: not-allowed;
      background-color: $--fc-sub-gray;
    }
  }
  .conversation-header{
    padding: 20px 20px;
    background: white;
    border-bottom: 1px solid #DDDDDD;
    border-radius: $--br-default $--br-default 0 0;
    display: flex;
    justify-content: center;
    min-height: 3.5rem;
    .messages-title {
      color: $--sc-gray;
      font-size: 20px;
      margin-left: auto;
    }
    span.pull-right {
      margin-left: auto;
      padding-right: 10px;
      .fa-new-icon {
        color: $--sc-gray;
        font-size: 1.75em;
      }
    }
  }
}

.conversation-container {
  padding: 0;
  @media (min-width: $screen-sm-max) {
    .conversation-list{
      border-right: 1px solid #DDDDDD;
      padding-right: 0;
      .conversation-header {
        padding: 20px 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
        justify-content: center;
        .messages-title {
          margin-left: auto;
          font-size: 20px;
          color: $--sc-gray;
        }
        span.pull-right {
          margin-left: auto;
          padding-right: 10px;
          .fa-new-icon {
            color: $--sc-gray;
            font-size: 1.75em;
          }
        }
      }
    }
    .conversation-window {
      border-left: 1px solid #DDDDDD;
      padding-left: 0;
      .conversation-header {
        border-top-left-radius: 0;
      }
    }
    .conversation-desktop-header{
      display: none;
    }
    .conversation-window-header {
      display: flex;
      flex-direction: column;
      min-height: 3.5rem;
      position: relative;
      background: white;
      top: 0;
      width: 100%;
      padding: 10px 10px 8px 10px;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
      border-radius: 0 20px 0 0;
      &.new{
        align-items: stretch;
        padding: 0 0 0 5px;
        min-height: 3.4rem;
      }
      input::placeholder{
        color: #999;
        opacity: 1;
      }

      .conversation-names {
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .conversation-container {
      overflow-y: auto;
      max-height: 675px;
      min-height: 600px;
    }
    .message-entry-card{
      display: flex;
      position: relative;
      bottom: 0;
      width: 100%;
      padding: 10px;
      align-items: center;
      border-radius: 0 20px 0 0;
    }
  }
  @media (max-width: $screen-md-min) {
    .conversation-list{
      border: 0;
      padding: 0;
      .card-header {
        border-radius: 0;
      }
    }
    .conversation-window {
      position: fixed;
      bottom: -2000px;
      z-index: 10;
      width: 100%;
      height: 100%;
      left: 0;
      padding: 0;
      background: $--fc-background;
      transition: 400ms all ease-in;
      &.active{
        bottom: 0;
        top: 0;
      }
      .conversation-window-header {
        display: flex;
        flex-direction: column;
        min-height: 3rem;
        position: absolute;
        z-index: 10;
        box-shadow: 0px 1px 3px 1px $--sc-sub-gray;
        background-color: $--fc-background;
        top: 0;
        width: 100%;
        padding: 10px;
        align-items: center;
        input::placeholder{
          color: #999;
          opacity: 1;
        }
        &.new{
          align-items: stretch;
          flex-direction: row;
        }
        .conversation-names {
          max-width: 80%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .message-entry-card{
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px;
      }
    }
    .conversation-container{
      padding: 0;
      margin-top: 6rem;
      display: flex;
      flex-direction: column;
      min-height: 80%;
      max-height: 80%;
      overflow-y: auto;
    }
    .conversation-desktop-header{
      margin: 0 20px 5px 0;
      font-size: 24px;
    }
  }
}

.conversation-card {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 10px;
  border-bottom: 1px solid #DDDDDD;
  padding: 5px;
  cursor: pointer;
  margin-right: 15px;
  &:hover {
    background: $--fc-background;
  }
}

.conversation-cards{
  padding: 12px 5px !important;
  position: relative;
  .conversation-card:last-child:not(:only-child){
    border-bottom: none;
  }
  @media (min-width: $screen-sm-max) {
    overflow-y: auto;
    max-height: 675px;
  }
}

.conversation-avatar {
  position: relative;
  border: 4px solid #fff;
  padding-right: 5px;
  margin-left: 0px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  min-width: 50px;
  background-position: center center;
  background-size: cover;
  .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    vertical-align: middle;
    border: 4px solid #fff;
    background: $--sc-sub-gray;
  }
}

.conversation-new-message{
  display: flex;
  flex-direction: column;
  max-width: 30%;
  align-items: center;
  height: max-content;
  justify-content: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  .message-plane {
    text-decoration: none;
    padding-bottom: $--ls-large;
    color: $--sc-gray;
    &:hover{
      color: $--sc-teal;
    }
  }
  .new-message-text{
    color: $--sc-sub-gray;
    padding-bottom: $--ls-regular;
  }
}

.message-participants-info{
  float: right;
  margin-left: auto;
  font-size: 16px;
  .show-recipients-button{
    color: $--sc-blue;
  }
}

.conversation-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.updated-at-text {
  font-size: 0.7rem;
  color: $--sc-sub-gray;
  position: absolute;
  right: 0;
  top: 5px;
}

.conversation-summary-text {
  font-size: 0.7rem;
  color: $--sc-sub-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sender-name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 4px;
}
.sender-count{
  margin: 4px 4px 4px 8px;
}

.sender-names{
  display: flex;
  flex-direction: row;
}

.conversation-show-more{
  margin: 0px;
  text-align: center;
  padding-top: 4px;
}

.participants{
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 20px;
  width: 100%;
  justify-content: space-evenly;
  .conversation-message-info{
    display: flex;
    flex-direction: column;
    height: auto;
    .updated-at-text {
      font-size: 0.7rem;
      color: $--sc-sub-gray;
      display: contents;
    }
  }
  .conversation-participants{
    width: 85%;
    position: relative;
    margin: 0;
    padding: $--ls-small $--ls-small;
    background: $--sc-white;
    border: 1px solid #DDDDDD;
    border-radius: $--br-default;
    font-size: $--ls-regular;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    .conversation-participant-card{
      display: flex;
      flex-direction: row;
      min-height: 1rem;
      position: relative;
      background: white;
      top: 0;
      width: auto;
      justify-content: center;
      padding: 10px;
      align-items: center;
      border: 1px solid #DDDDDD;
      border-radius: 50px;
    }
  }
  .recipients-close-button{
    color: $--sc-red;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}

.conversation-message-bubble {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  &.left {
    align-items: start;
  }
  &.right {
    align-items: end;
  }
}

.conversation-bubble{
  background: white;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #DDDDDD;
}

.conversation-left-message{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-align: left;
  max-width: 85%;
  padding: 8px;
  margin-right: auto;
}

.conversation-right-message{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  position: relative;
  max-width: 85%;
  padding: 8px;
  float: right;
  margin-left: auto;
}

.conversation-date{
  font-size: 0.9rem;
  color: $--sc-gray;
  position: relative;
  text-align: center;
  top: 5px;
  bottom: 5px;
  margin: auto;
}

.conversation-message-card{
  position: relative;
  margin:0 0 $--ls-regular;
  padding: $--ls-small $--ls-small;
  background: $--sc-white;
  border: 1px solid #DDDDDD;
  border-radius: $--br-default;
  font-size: $--ls-regular;
}
.conversation-message-embed{
  max-width: 100%;
  max-height: 500px;
}

