.round-tasks.add-from-general-modal {
  .selection-container {
    display: flex;
    align-items: center;

    .left {
      padding: 4px 8px;
      font-size: 12pt;
    }

    .right {
      flex: 1;
      
      .search-btn {
        font-size: 11pt;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid lightgrey;
        border-radius: 4px;
        padding: 4px 8px;
      }
    }
  }
}
