.children-demographics-card {
  .child {
    padding-bottom: 0;
    .divider {
      padding-top: 20px;
      border-bottom: 2px solid $--fc-gray;
    }
    &:last-child {
      padding-bottom: 20px;
      .divider {
        padding: 0;
        border-bottom: none;
      }
    }
  }
}