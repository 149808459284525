.round-card {
  .round-card-inner {
    display: flex;
    flex-flow: column;
    > * {
      margin: 5px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      font-size: $--fs-x-large;
      font-weight: $--fw-bold;
      color: $--sc-gray;
    }

    .due-date {
      font-size: $--fs-regular;
      color: $--sc-sub-gray;
    }

    .platform-payment {
      .platform {
        margin-right: 10px;
        font-size: $--fs-large;
        color: $--sc-gray;
      }
      .payment {
        font-size: $--fs-large;
        font-weight: $--fw-bold;
        color: $--sc-green;
      }
    }

    .round-description {
      font-size: $--fs-regular;
      color: $--sc-sub-gray;
    }
    .round-bid-payment-calculator{
      label{
        font-weight: $--fw-regular;
      }
      .round-bid-input{
        margin-bottom: 10px;
      }
      hr{
        height: 2px;
        background-color: $--sc-gray;
        margin: 10px 0;
      }
      .round-payment,
      .total-round-payment{
        margin: 0 0 4px 10px;
      }
      .round-payment{
        font-size: $--fs-regular;
        .round-payment-label{
          margin-left: 30px;
          color: $--sc-sub-gray;
        }
      }
      .total-round-payment{
        color: $--sc-green;
        font-size: $--fs-x-large;
        font-weight: $--fw-bold;
        .total-round-payment-label{
          margin-left: 30px;
          font-size: $--fs-regular;
          color: $--sc-gray;
        }
      }
    }
  }
}