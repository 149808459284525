.line {
  &:before, &:after {
    background: #fff;
    position: absolute;
    content: "";
  }
  &:before {
    border-radius: 2px;
    bottom: -30px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 3px;
  }
  &:after {
    border: 2px solid #ccc;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 15px;
    top: 10px;
    width: 15px;
    background: $light-green;
  }
  &.left {
    text-align: right;
    margin-right: 40px;
    &:before { right: -1px; }
    &:after  { right: -7px; }
    .note-inner, .audit-inner {
      &:before {
        border-left: 10px solid #ddd;
        right: -20px;
      }
      &:after {
        border-left: 10px solid #fff;
        right: -18px;
      }
    }
    .avatar {
      float: right;
      margin-left: 20px;
    }
  }
  &.right {
    margin-left: 40px;
    float: right;
    &:before { left: -1px; }
    &:after  { left: -7px; }
    .note-inner, .audit-inner {
      &:before {
        border-right: 10px solid #ddd;
        left: -20px;
      }
      &:after {
        border-right: 10px solid #fff;
        left: -18px;
      }
    }
  }
  .note-inner, .audit-inner {
    &:before, &:after {
      border-style: solid;
      border-width: 10px;
      color: rgba(0, 0, 0, 0);
      content: "";
      display: block;
      height: 0;
      position: absolute;
      top: 6px;
      width: 0;
    }
  }
  .avatar {
    float: left;
    margin-right: 20px;
  }
}

#new_note {
  color: $dark-green;
  font-size: 1.5em;
}

.note-item, .audit-item {
  margin-bottom: 25px;
  position: relative;
  padding: 0 30px;
  .note-inner, .audit-inner {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    position: relative;
  }
  @extend .line;
  textarea, textarea:focus {
    border: none;
    resize: none;
    text-align: right;
    box-shadow: none;
  }
}

.clearfix {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.note-item .note-head,
.audit-item .audit-head {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  padding-bottom: 8px;
  .avatar {
    img {
      min-height: 40px;
      max-height: 40px;
    }
  }
  .note-info, .audit-info {
    overflow: hidden;
    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }
  .note-meta, .audit-meta {
    > div {
      color: #333;
      font-weight: bold;
      text-align: right;
    }
  }
}

.note-toggle{
   -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -user-select: none;
}

.admin-content-right .note-toggle{
  margin-top: 40px;
  padding-top: 30px;
  border-top: solid 1px rgba(black, .15);
}