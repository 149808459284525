.horizontal-scroll-container {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  > * {
    width: 85%;
    flex: 0 0 auto;
    margin: 0 15px 0 0;
    &:only-child {
      width: 100%;
      margin: 0;
    }
  }
  
  &:not(.no-expand) {
    @media screen and (min-width: $--bp-medium){
      flex-wrap: wrap;
      overflow-x: hidden;
      > * {
        width: 100%;
        flex: 0 0 auto;
        margin: 0 0 15px 0;
      }
    }
  }
}
