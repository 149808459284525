$group-thumb-size: 60px;

.edit_link {
  font-size: 12px;
  display: inline;
}

.posts {
  padding: 10px 0;
  min-height: 75px;
  background: #fff;
  border-bottom: solid 1px $gallery;
}

.comments{
  margin-bottom: 10px;
  background: #FDFDFD;
  border: solid 1px #F8F8F8;
  border-bottom: solid 2px #F3F3F3;
  border-left: solid 1px #F9F9F9;
  padding: 10px;
  position: relative;
  & > a{
    float:left;
  }
  .comment-text {
    padding-left: 45px;
  }
  .reply{
    font-size: 12px;
    color: darken($link-color, 7%);
  }
}

.comment-header{
  float: left;
}

.comment-date-time{
  font-size: 11px;
  color: #B1B1B1;
}

.comment-img{
  display: block;
  height:40px;
  width: 40px;
  float:left;
  margin-right: 5px;
}

.btn.btn-primary.comment-edit-btn{
  position: absolute;
  top: 5px;
  right: 55px;
}

.btn.btn-primary.comment-delete-btn{
  position: absolute;
  top: 5px;
  right: 5px;
}
.post-content{
  padding: 15px;
  background: #f9f9f9;
  border: solid 1px #EEE;
  img{
    max-width:100%;
  }
}

.post-img{
  height: 80px;
  width: 80px;
}

.btn.btn-primary.post-btn{
  right: 10px;
  margin-bottom: 10px;
}

.posts img {
  vertical-align: middle;
}

.post-created{
  font-size: 11px;
  line-height: 12px;
  color: #BFBFBF;
  margin-top: 5px;
  border-radius: 2px;
  padding: 10px;
  background: #F8F2FA;
  max-width: 82px;
}

img.small {
  width: 50px;
  height: 50px;
}

#posts-title-area {
  height: 30px;
  background: $light-green;
  padding: 5px 0;
  color:#fff;
}

.posts .meta-info {
  color: #aaa;
  font-size: 12px;
}

#member-group-search {
  width: 300px;
  margin-bottom: 15px;
}

// admin group area
.admin_groups_body{
  .dataTable img{
    margin-right:10px;
  }
}
#new_user_area li {
  list-style-type:none;
  margin-bottom: 5px;
}
form#new_post{
  .text-center{
    margin-top: 10px;
    margin-bottom: 25px;
  }
}
.checkbox-group{
  input[type="checkbox"]{
    height:auto;
    width:auto;
  }
}

.posts_body{
  .form-actions{
    text-align:right;
    margin-bottom:20px;
    margin-top:20px;
  }
}

.comments_body{
  #new_comment{
    .form-group{
      margin-top:10px;
    }
  }
}

.comment-nav{
  margin-bottom:20px;
}

.comment-nav-btn{
  margin-top:10px;
}

.group-wrapper{
  position: relative;
  height: 200px;
  padding: 10px;
  margin-bottom:20px;
  box-shadow: 0 0 0 1px rgba(225, 231, 234, 0.45);
  border-radius: $border-radius-base;
  background: #fff;
  .top{
    line-height: $group-thumb-size;
  }
  .bottom{
    position: absolute;
    bottom:10px;
    left:0;
    width:100%;
    padding-top:7px;
    border-top: solid 1px $--fc-background;
    div{
      float:right;
      margin-right:10px;
      font-size: 12px;
      color: $dusty-grey;
      .fa, .fas, .fab, .far{
        margin-right:2px;
        color: #CFCFCF;
      }
      &.created{
        float:left;
        margin-right:20px;
        padding-left:10px;
      }
    }
  }
}

.group_thumb{
  border: solid 1px #DBDBDB;
  height:$group-thumb-size;
  width:$group-thumb-size;
  overflow:hidden;
  float: left;
  background-size: contain;
  background-repeat:no-repeat;
  background-position: center center;
}

.group_name{
  text-transform: uppercase;
  line-height:$group-thumb-size;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size:18px;
  font-weight:$light;
  padding-left:10px;
  padding-right:5px;
}

.desc{
  max-height: 75px;
  overflow: hidden;
  margin-top: 7px;
  font-size: 13px;
}
