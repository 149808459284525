.ica_area {
    overflow: scroll;
    height: 15em;
    width: 100%;
}

.ica {
  p {
    overflow-y: scroll;
  }
}