.influencer-dashboard-container {
  .content-summary {
    font-size: $--fs-regular;

    h3 {
      margin-bottom: 10px;
    }

    a {
      color: $--tc-default;
    }

    .flex-container{
      display: flex;
      justify-content: space-between;
     }

    .content-summary-card {
      @media(min-width: $--bp-medium){
        width: 49%;

        &:first-child {
          margin-right: 1.5%;
        }
      }
    }

    .total-posts {
      color: $--sc-blue;
      font-size: $--fs-xx-large;
      font-weight: $--fw-bold;
      line-height: 0.8;
    }

    .content-badge {
      margin-top: 12px;
      margin-bottom: 8px;
    }

    .metric-value {
      font-size: $--fs-x-large;
      font-weight: $--fw-bold;
      line-height: 1;
    }
  }
}
