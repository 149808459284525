.profile-show-container {
  display: flex;
  flex-flow: column;
  .button-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: unset;
  }
  .profile-cards-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 20px 0;
    > div {
      width: 100%;
    }
  }
  
  @media screen and (min-width: $--bp-medium){
    .button-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
    .profile-cards-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: unset;
      > div {
        min-width: 300px;
        margin: 10px;
        width: auto;
        flex: 1;
      }
    }
  }
}