.social-account-row.network {
  width: 100%;
  height: 46px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 2.5px 0;
  position: relative;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  .username, .platform {
    width: auto;
    margin-right: 10px;
    font-size: $--fs-regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
  .platform {
    color: $--sc-sub-gray;
    text-transform: capitalize;
  }

  &.disabled > .fa, .fas, .fab, .far {
    &.social-icon--facebook_advertiser{
      background-color: $--sc-sub-gray;
    }
  }

  .fa, .fas, .fab, .far {
    width: 30px;
    height: 30px;
    min-width: 30px;
    color: $--sc-white;
    margin-right: 10px;
    font-size: $--fs-x-large;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &.social-icon--facebook,
    &.social-icon--facebook_advertiser{
      background-color: $facebook-color;
    }
    &.social-icon--twitter{
      background-color: $twitter-color;
    }
    &.social-icon--youtube{
      background-color: $youtube-color;
    }
    &.social-icon--pinterest{
      background-color: $pinterest-color;
    }
    &.social-icon--instagram{
      background-color: $instagram-color;
      background: $instagram-color-gradient;
    }
    &.social-icon--tiktok{
      border-radius: 50px;
      border: 2px solid $tiktok-color;
      background: url('social-links/tiktok-logo.png');
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .centered-button {
    min-width: 110px;
    text-align: center;

    .disconnect-link{
      color: $--sc-red;
      &:hover {
        color: $--sc-sub-gray;
        cursor: pointer;
      }
    }

    &.reconnect {
      .username {
        color: $--sc-sub-gray;
      }
      .fa, .fas, .fab, .far {
        background: $--sc-sub-gray;
        background-color: $--sc-sub-gray;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
