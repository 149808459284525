.user-community-selection{
  margin-bottom: 14px;
  margin-top: 10px;
  margin-left: -25px;
  margin-right: -25px;

  small {
    margin-left: 10px;
  }
}
